import { clearData } from '../helpers';
import { getBusinessID, getToken } from '../tokens';

// export const url = 'http://localhost:43911/';
export const url = "https://operations-b.invoiceria.com/";

export const handleResponse = async (response) => {
  if (response.status == 200) {
    return await response.json();
  } else if (response.status == 404) {
    return { error: 'Not found' };
  } else if (response.status == 400) {
    return { error: 'error' };
  } else if (response.status == 401) {
    clearData();
    window.location.href = '/logout';
  }
};

export const unAuthBody = (data) => {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data ? JSON.stringify(data) : null,
  };
};

export const authBody = (data) => {
  const token = getToken();
  const business_id = getBusinessID();
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      Business: `${business_id}`,
    },
    body: JSON.stringify(data),
  };
};

export const apiFetch = (target, body) =>
  fetch(url+target, authBody(body ? body : {})).then((response) => handleResponse(response));
