import { handleResponse, url } from ".";
import { getBusinessID, getToken } from "../tokens";

export const sendInvoiceEmailApi = async (data, file) => {
  const token = getToken();
  const business_id = getBusinessID();

  return fetch(url + `email/send-invoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
      Business: `${business_id}`
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const sendUserAnInviteAPI = async (data) => {
  const token = getToken();
  const business_id = getBusinessID();
  return fetch(url + `email/send-invite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
      Business: `${business_id}`
    },
    body: JSON.stringify({ email: data }),
  }).then((response) => handleResponse(response));
};
