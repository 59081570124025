import { apiFetch, url } from '.';
const b = 'business/';

export const createBusinessAccount = (data) =>
  apiFetch(`${b}create-business`, data);
export const sendBusinessInviteAPI = (data) =>
  fetch(url + `${b}send-business-invite`, data);
export const changeBusinessUserStatus = (data) =>
  fetch(url + `${b}change-user-status`, data);
export const getAllUserBusinesses = () => apiFetch(`${b}get-all-user-business`);
export const getBusinessesData = () => apiFetch(`${b}get-business-by-id`);
export const getBusinessesUsers = () => apiFetch(`${b}get-business-users`);
