export const messages = {
  "lv-LV": {
    delete_credential: "Izdzēst saglabātos rekvizītus:",
    saved_for_reuse: "Saglabātie rekvizīti",
    save_credentials_for_reuse: "Saglabāt rekvizītus atkārtotai izmantošanai",
    save_invoice: "Saglabāt rēķinu",
    edit_invoice: "Rediģēt rēķinu",
    forbidden: "Aizliegts",
    allowed_to_edit_only_draft:
      "Ir atļauts modificēt vien rēķinus, kas ir melnraksta stadijā",
    return_to_invoice: "Atpakaļ uz visiem rēķiniem",
    upload_image_for_ai: "Ielādēt bildi",
    or: "Vai",
    new_emails_received:
      "Jūs esat saņēmis {count, plural, one {jaunu e-pastu} other {{count} jaunus e-pastus!}}",
    import_data: "Importēt datus",
    import_data_from_invoice: "Importēt datus no rēķina",
    select_invoice: "Izvēlaties rēķinu",
    process_invoice: "Apstrādāt un importēt datus",
    v0: "",
    tags: "Birkas",
    recent_creds: "Pēdējie izmantotie rekvizīti",
    enter_ai_credentials: "Aizpildīt laukus ar AI palīdzību",
    enter_credentials: "Ievadi rekvizītus",
    process_credentials: "Apstrādāt rekvizītus",
    modify_tags: "Modificēt birkas",
    add_tags_to_invoice: "Pievienot birkas",
    add_tags_to_invoice_description:
      "Izmanto birkas, lai izceltu rēķinus. Birkas redzēsi vien tu vai tavs uzņēmums",
    allow_to_find_by_reg_nr: "Ļaut atrast pēc reg.nr",
    uuid_to_be_found_by: "ID Pēc kā tevi var atrast",
    language: "Vietnes valoda",
    lv_lng: "Latviešu valoda 🇱🇻 (Latvian)",
    en_lng: "Angļu valoda 🇺🇸 (English)",
    something_went_wrong_recheck:
      "Kaut kas nogāja greizi! Pārbaudi visu informāciju un provē vēlreiz!",
    no_credentials_found:
      "Jums vēl nav pievienoti vai publicēti rekvizīti. Dodies uz iestatījumiem, lai tos sagatavotu",
    original_attachments: "Oriģinālie pielikumi",
    invoiceria_invoices: "Invoiceria interpretētie rēķini",
    logo: "Logo",
    select_existing_logo: "Izvēlies eksistējošu logo",
    no_logo_set: "Logo nav iestatīts...",
    set_logo: "Iestatīt logo",
    upload_new_logo: "Augšupielādēt jaunu logo",
    emails_share_get_started:
      "Tu vēl neesi saņēmis nevienu ziņu! Dalies ar savu e-pastu un sāc saņemt rēķinus pa taisno sistēmā!",
    sent_from: "No",
    invoice_emails_page: "Rēķinu e-pasti",
    email_copied: "E-pasts nokopēts!",
    email_already_taken: "Šāds e-pasts nav pieejams",
    first_part_too_short: "Pirmajai daļai jābūt garākai!",
    second_part_too_short: "Otrajai daļai jābūt garākai!",
    email_parts_cant_be_empty: "Jāaizpilda abi lauku!",
    create_invoice_email_mailbox: "Izveidot e-pastu",
    email_invoice_system_disclaimer:
      "E-pastam ir jāseko divu daļu stilam. Tas var būt, piemēram, vārds-uzvārds vai jebkāda cita veida kombinācijai",
    email_pl_name: "vārds",
    email_pl_surname: "uzvārds",
    set_up_system_email: "Izveidot e-pastu",
    email_page_hero_cta: "Izveidot e-pastu",
    email_page_hero_description:
      "Izveido e-pasta adresi, ko vari norādīt pakalpojumu sniedzēju sistēmās vai izsniegt piegādātājiem, lai automātiski saņemtu visus rēķinus vienuviet!",
    email_page_hero_title:
      "<highlight>No e-pasta uz sistēmu</highlight>, vienkāršo rēķinu saņemšanu!",
    email_invoices: "Rēķinu e-pasts",
    service_quantity: "gab.",
    view_original_invoice: "Apskatīt oriģinālo rēķinu",
    ai_read_invoice: "Rēķinu ir interpretējis mākslīgais intelekts",
    this_is_ai_read_invoice:
      "Šo rēķinu ir interpretējis mākslīgais intelekts, attiecīgi tā dati var nebūt pilnībā uzticami. Drošībai pārliecinaties par datu pareizību, kamēr mēs turpinām uzlabot mūsu rēķinu lasītāju.",
    see_original_here: "Apskati oriģinālo rēķinu šeit",
    search_for_invoice: "Meklēt rēķinu",
    invoice_template_description_info:
      "Rēķinu šabloni ļaus tev sagatavot daļu no rēķina, kas atvieglos un paātrinās atkārtojošo rēķinu izrakstīšanu",
    changes_only_temp:
      "Šeit veiktās izmaiņas attieksies vien uz konkrēto izrakstīto rēķinu",
    pin_to_start: "Pievienot sākumekrānam",
    edit_modal_template_mail_subject: "Labot e-pasta tēmu",
    edit_modal_email_content: "Labot e-pasta ziņu",
    edit_modal_public_note: "Labot publisko piezīmi",
    edit_modal_services: "Labot pakalpojumus",
    create_invoice_and_send: "Izveidot un nosūtīt rēķinu",
    invoice_deadline_setting: "Rēķina apmaksas termiņš",
    invoice_name_type: "Rēķina nosaukuma tips",
    example_invoice_name: "Rēķina nosaukuma piemērs",
    current_invoice_name: "Šobrīd izrakstīta rēķina nosaukuma piemērs",
    template_name: "Šablona nosaukums",
    template_description: "Šablona apraksts",
    due_date_settings: "Apmaksas termiņa nosacījumi",
    due_date_day_after: "Diena pēc izrakstīšanas",
    due_date_two_day_after: "Divas dienas pēc izrakstīšanas",
    due_date_three_day_after: "Trīs dienas pēc izrakstīšanas",
    due_date_four_day_after: "Četras dienas pēc izrakstīšanas",
    due_date_five_day_after: "Piecas dienas pēc izrakstīšanas",
    due_date_six_day_after: "Sešas dienas pēc izrakstīšanas",

    due_date_week_after: "Nedēļu pēc izrakstīšanas",
    due_date_two_week_after: "Divas nedēļas pēc izrakstīšanas",
    due_date_three_week_after: "Trīs nedēļas pēc izrakstīšanas",

    due_date_month_after: "Mēnesi pēc izrakstīšanas",
    due_date_two_month_after: "Divus mēnešus pēc izrakstīšanas",

    untitled_template: "Šablons bez nosaukuma",
    edit_template_invoice: "Apstrādāt šablonu",
    create_template: "Izveidot šablonu",
    template_invoices: "Rēķinu šabloni",
    service_show_full_price: "Cena ar nodokli",
    service_full_price: "Cena ar nodokli:",
    service_bulder: "Veidot pakalpojumu / produktu",
    service_name: "Pakalpojuma / produkta nosaukums",
    service_tax: "Nodoklis",
    service_use_tax: "Izmantot nodokli",
    service_price: "Cena",
    service_description: "Pakalpojuma / produkta apraksts",
    service_browse: "",
    service_save: "Saglabāt",
    fiz_per: "Fiziska persona",
    tax_rate_enabled: "Izmantot nodokļa likmi (PVN)",
    business_account_badge: "Uzņēmums",
    biz_account_created: "Uzņēmuma konts izveidots!",
    switch_to_business_account: "Pārslēgties uz uzņēmuma kontu",
    switch_to_biz_account_info:
      "Uz uzņēmuma kontu un atpakaļ var pārslēgties arī nospiežot uz profile bildes sānu izvēlnē",
    archive_invoice: "Arhivēt rēķinu",
    archive: "Annulēts un Arhivēts",
    are_you_sure_you_want_to_archive:
      "Vai esat drošš, ka vēlaties pārvietot rēķinu uz arhīva sadaļu?",
    void_invoice_confirm: "Vai esi drošs, ka vēlies annulēt šo rēķinu?",
    no: "Nē",
    yes: "Jā",
    create_biz_acc_info:
      "Pēc uzņēmuma konta izveides - jums būs iespējams uz to pārslēgties nospieot uz sava lietotāja ikonas izvēlnē",
    email_already_in_use: "Šāds e-pasts jau tiek izmantots",
    allow_to_find_by_email: "Ļaut atrast pēc e-pasta",
    invalid_email: "Nederīgs e-pasts!",
    password_dont_match: "Parolēm jāsakrīt!",
    password_must_be_8: "Parolei jābūt vismaz 8 simbolus garai",
    fill_more_fields_reg: "Lūdzu aizpildiet visus obligātos laukus",
    vat_on_by_default: "PVN ir",
    vat_off_by_default: "PVN nav",
    currency: "Valūta",
    default_values: "Noklusējuma vērtības",
    copy_to_clipboard: "Nokopēt",
    blocked: "Bloķēts",
    unblock_user: "Atbloķēt lietotāju",
    modify_biz_user: "Modificēt uzņēmuma lietotāju",
    remove_user: "Noņemt lietotāju",
    block_user: "Nobloķēt lietotāju",
    send_invite: "Sūtīt uzaicinājumu",
    add_user_to_biz: "Pievienot lietotāju",
    un_publish_credentials: "Depublicēt rekvizītus",
    business: "Uzņēmums",
    business_credentials: "Uzņēmuma rekvizīti",
    business_edit_user: "Apstrādat",
    upload_picture: "Augšupielādēt bildi",
    business_logo_img_upload: "Ielādēt uzņēmuma bildi/logo",
    business_main_acc: "Galvenais",
    business_participants: "Uzņēmuma dalībnieki",
    business_settings: "Uzņēmuma iestatījumi",
    set_business_name: "Iestatīt uzņēmuma nosaukumu",
    create_business_account: "Izveidot uzņēmuma kontu?",
    create_business_account_info:
      "Uzņēmuma kontā tu varēsi pievienot vairākus lietotājus, gan apmaksas ievadītājus, gan grāmatvežus, gan datu analistus un spēsi ierobežot viņu tiesības konkrētām darbībām",
    create_business_account_cta: "Izveidot uzņēmuma kontu",
    invoice_status: "Rēķina stāvoklis",
    payment_status: "Apmaksas stāvoklis",
    unpaid: "Neapmaksāts",
    partially_paid: "Daļēji apmaksāts",
    sort_by: "Kārtot pēc",
    select_all_invoices: "Izvēlēties visus atlasītos rēķinus",
    void: "Annulēts",
    download_selected:
      "Lejuplādē {count, plural, one {rēķinu} other {{count} rēķinus}}",
    download_invoices_screen: "Lejuplādē rēķinus",
    download_all_as_zip: "Lejuplādēt visus rēķinus kā zip",
    issue_invoice_to_this_user: "Izveidot rēķinu šim lietotājam",
    create_from_invoice: "Izveidot rēķina kopiju",
    invoice_name_missing: "Izvēlaties rēķina nosaukumu",
    individual_tax_for_each: "Individuāls nodoklis katram pakalpojumam",
    invoice_calendar_received: "Saņemts rēķins",
    invoice_calendar_sent: "Nosūtīts rēķins",
    open_in_new_tab: "Atvērt jaunā cilnē",
    more_info: "Vairāk",
    created_on: "Izveidots",
    calendar_month_look: "Mēneis",
    calendar_day_look: "Diena",
    calendar_week_look: "Nedēļa",
    invoice_nr_text: "Teksts",
    invoice_nr_insert: "Ievietot",
    invoice_nr_explain_m: "Mēnesis (3 vai 11)",
    invoice_nr_explain_0m: "Mēnesis ( 03 vai 11 )",
    invoice_nr_explain_d: "Diena (3 vai 11)",
    invoice_nr_explain_0d: "Diena (03 vai 11)",
    invoice_nr_explain_y: "Gads (24)",
    invoice_nr_explain_Y: "Pilns gads (2024)",
    invoice_nr_explain_nr: "Numurs",
    invoice_nr_explain__: "_",
    "invoice_nr_explain_-": "-",
    "invoice_nr_explain_/": "/",

    invoice_nr_explain_simple_m: "Mēnesis ",
    invoice_nr_explain_simple_0m: "Mēnesis ",
    invoice_nr_explain_simple_d: "Diena ",
    invoice_nr_explain_simple_0d: "Diena ",
    invoice_nr_explain_simple_y: "Gads ",
    invoice_nr_explain_simple_Y: "Pilns gads ",
    invoice_nr_explain_simple_nr: "Numurs ",
    invoice_nr_explain_simple__: "_ ",
    "invoice_nr_explain_simple_-": "- ",
    "invoice_nr_explain_simple_/": "/ ",

    invoice_nr_placeholder_text_explain:
      "Spied uz pogām, lai izveidotu dinamisku rēķina numuru, kurš pieņems rēķina izrakstīšanas dienā esošās vērtības",
    add_contact_didnt_find: "Neatradi lietotāju?",
    invite_sent: "Uzaicinājums nosūtīts!",
    add_contact_valid_email:
      "Ievadi derīgu e-pastu un nosūti uzaicinājumu pievienoties platformai!",
    add_contact_didnt_find_invite: "Nosūti {email} uzaicinājumu pievienoties!",
    contact_alert_top: "",
    contact_alert_info:
      "Meklē citus reģistrētus lietotājus pēc e-pasta, vārda vai uuid un pievieno viņus saviem kontaktiem, lai vieglāk varētu izrakstīt rēķinus!",
    pw_dont_match: "Paroles nesader!",
    new_password: "Jaunā parole",
    link_maybe_expired: "Saite varētu būt novecojusi",
    return_to_login: "Atgriezties pie ielogošanās",
    pwreset_invalid_token:
      "Nederīgs atiestatīšanas kods vai parole. Lūdzu, mēģiniet vēlreiz.",
    pwreset_something_wrong: "Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlāk.",
    pwreset_error: "Kļūda!",
    sent_from_eazybill: "Sūtīts no invoiceria.com",
    invoice_attached: "Pielikumā rēķins",
    fill_receiver: "Aizpildi saņēmēja lauku!",
    email_subject: "E-pasta tēma",
    email_add_receivers: "Pievienot saņēmējus",
    issue_invoice: "Izdot rēķinu",
    all_invoices: "Visi rēķini",
    received_invoices: "Saņemtie rēķini",
    invoice_calendar: "Rēķinu kalendārs",
    contacts: "Kontakti",
    support_main: "Atbalsts",
    profile_settings: "Profils & Iestatījumi",
    total_vat: "Kopā apmaksai",
    issued: "Izdots",
    reset_password_text: "Aizmirsu paroli",
    enter_email: "Ievadi savu e-pastu",
    reset_password_done: "Darīts!",
    reset_password_button: "Atiestatīt paroli",
    repeat_password: "Atkārtot paroli",
    welcome_to: "Esi sveicināts",
    incorrect_login: "Nepareizi dati!",
    welcome_back: "Sveicināts atpakaļ",
    password: "Parole",
    dont_have_an_account: "Nav konta?",
    already_have_account: "Jau esi reģistrējies?",
    do_register: "Reģistrējies",
    register: "Reģistrēties",
    login: "Ielogoties",
    do_login: "Ielogojies!",
    forgot_password: "Aizmirsu paroli",
    support_sent: "Ziņojums nosūtīts!",
    subject: "Tēma",
    your_problem: "Jūsu ziņa",
    message: "Ziņa",
    report_problem: "Ziņot par problēmu",
    support: "Problēmas / Atbalsts",
    go_to: "Doties",
    add_contacts: "Pievienot jaunu kontaktu",
    create_new_invoice: "Izveidot jaunu rēķinu",
    upload_profile_picture: "Augšupielādēt profila bildi",
    set_your_credentials_to_start:
      "Iestati savus rekvizītus, lai atvieglotu rēķinu izrakstīšanu gan sev, gan citiem!",
    profile_picture: "Profila bilde",
    delete_friend_request: "Vai esat drošs, ka vēlaties izdzēst: ",
    no_public_credentials: "Nav publisku rekvizītu",
    me: "Es",
    main_page_hero_title:
      "<highlight>Jauns veids</highlight>, kā apmaksāt rēķinus un saņemt samaksu!",
    main_page_hero_description:
      "Nekad nepalaidiet garām rēķinu un sekojiet līdzi finansēm, kas jums jāmaksā un kas jums jāsaņem! Padariet savu dzīvi vieglāku",
    main_page_hero_cta: "Sākt",
    main_page_feature_title: "Importē savus rēķinus!",
    main_page_feature_status: "Drīzumā...",
    main_page_feature_description:
      "Lai atvieglotu dzīvi, mēs strādājam pie lielapjoma importēšanas funkcijas, kas ļauj importēt esošos rēķinus, lai jūs varētu pārvaldīt tos visus vienā vietā!",
    new_invoices_received:
      "Jums ir {count, plural, one {1 jauns rēķins} other {{count} jauni rēķini}}",
    new_friend_requests_received:
      "Jūs esat saņēmuši {count, plural, one {jaunu draudzības pieprasījumu} other {{count} jaunus draudzības pieprasījumus}}",
    view: "Apskatīt",
    private_note: "Privāta piezīme",
    public_note_invoice: "Piezīme",
    set_new_credentials: "Iestatīt jaunus rekvizītus",
    invoice_is_as_draft:
      "Rēķins šobrīd ir melnraksta stadijā, attiecīgi tas ir redzams vien tev!",
    user_wont_see_disclaimer:
      "Nodod rēķinu lietotājam, lai šis rēķins parādītos reģistrētā lietotāja apmaksājamos rēķinos",
    set_issued: "Nodot rēķinu lietotājam",
    action_currently_not_possible: "Darbība šobrīd nav pieejama",
    phone: "Telefons",
    modify_issuer: "Modificēt izdevēju",
    set_credentials: "Iestatīt rekvizītus",
    edit_credentials: "Labot rekvizītus",
    publish_credentials: "Publicēt rekvizītus",
    confirm_publish_credentials:
      "Vai esat drošš, ka vēlaties publicēt rekvizītus?",
    credentials_not_published: "Rekvizīti nav publicēti! ",
    credentials_not_published_info:
      "Jūs varat tos labot vien kamēr tie nav publicēti. Pēc tam būs iespēja veidot jaunu rekvizītu ierakstu, lai visi līdz tam izrakstītie rēķini saglabātu vecos rekvizītus",
    delete_account_confirmation:
      "Vai esat drošs, ka vēlaties izdzēst savu kontu?",
    my_credentials: "Mani rekvizīti",
    credentials_not_set: "Rekvizīti nav iestatīti",
    settings: "Iestatījumi",
    subscription: "Abonaments",
    coming_eventually: "Drīzumā",
    my_data: "Mani dati",
    delete_account: "Izdzēst kontu",
    action_irreversible: "Darbība nav atgriežama",
    create_invoice: "Izveidot rēķinu",
    vat_hundredths: "Nodokļa % simtdaļās",
    add_service: "Pievienot pakalpojumu",
    notes: "Privātas piezīmes",
    vat: "PVN",
    public_notes: "Piezīmes, ko pievienot rēķinā",
    issued_by: "Izdeva",
    issued_for: "Izdots priekš",
    tax: "Nodoklis",
    total_due: "Kopā apmaksai",
    to_pay: "Apmaksai",
    subtotal: "Kopā",
    subtotal_no_disc: "Bez atlaides",
    due_date: "Apmaksāt līdz",
    add_new_invoice: "Pievienot jaunu rēķinu",
    tax_rate: "Nodokļa likme",
    add_issued_by: "Pievienot izdevēju",
    add_issued_for: "Pievienot saņēmēju",
    modify_for: "Modificēt saņēmēju",
    modify_by: "Modificēt izdevēju",
    jur_per: "Juridiska persona",
    priv: "Juridiska persona",
    name: "Vārds",
    total: "Kopā",
    surname: "Uzvārds",
    bussiness_name: "Uzņēmuma nosaukums",
    business_name: "Uzņēmuma nosaukums",
    per_id: "Personas kods",
    reg_id: "Reģistrācijas numurs",
    jur_addr: "Juridiskā addrese",
    phy_addr: "Fiziskā addrese",
    vat_code: "PVN Kods",
    bank: "Banka",
    bank_acc: "Bankas konts",
    save: "Saglabāt",
    enter_service_name: "Pakalpojuma nosaukums",
    cost: "Cena",
    quantity: "Daudzums",
    remove: "Dzēst",
    discount: "Atlaide",
    fill_more_fields: "Aizpildiet vēl laukus",
    invoice_nr: "Rēķina nr.",
    invoice_id: "Rēķina atslēga",
    search: "Meklēt",
    records_per_page: "Ieraksti lapā",
    invoice_overview: "Rēķinu pārskats",
    status: "Status",
    draft: "Melnraksts",
    sent: "Izsūtīts",
    paid: "Apmaksāts",
    services: "Pakalpojumi",
    service: "Pakalpojums",
    paid_on: "Apmaksāts",
    send_modal: "Sūtīt rēķinu",
    edit_modal: "Pielabot statusu",
    send_to: "Saņēmējs",
    email_content: "E-pasta saturs",
    send: "Sūtīt",
    extend: "Pagarināt apmaksas termiņu",
    set_paid_date: "Reģistrēt apmaksas datumu",
    set_paid: "Marķēt kā apmaksātu",
    set_voided: "Annulēt rēķinu",
    paid_amount: "Apmaksātā summa",
    mark_as_partially_paid: "Marķēt kā daļēji apmaksātu",
    pick_a_date: "Izvēlaties datumu",
    voided: "Rēķins annulēts",
    already_paid_amount: "Jau apmaksāts",
    extended_due: "Apmaksa pagarināta līdz",
    new_invoices: "Jaunie rēķini",
    sent_invoices: "Izsūtītie rēķini",
    invoices_not_found: "Nav atrasts neviens rēķins",
    invoice_name: "Rēķina nosaukums",
    edit: "Rediģēt",
    search_for_contact: "Meklēt kontaktu",
    email: "E-pasts",
    select_invoice_name_type: "Izvēlaties rēķina nosaukuma tipu",
    inv_custom: "Personalizētais",
    inv_free: "Brīvais variants",
    inv_pl_date: "INV un Datums (piem: INV20241223)",
    inv_pl_date_pl_nr: "INV, datums un numurs (piem: INV2024122303)",
    inv_start_nr: "Sākuma numurs",
    inv_name_example: "Rēķina nosaukma piemērs",
    inv_name: "Rēķina nosaukums",
    issued_on: "Izdošanas datums",
    inv_format: "Rēķina formāts",
    friend_request_sent: "Draudzības pieprasījums nosūtīts",
    send_friend_request: "Sūtīt draudzības pieprasījumu",
    in_friends: "Draugi",
    contact_name: "Kontakta nosaukums",
    requests: "Draudzības pieprasījumi",
    accept_friend_request: "Akceptēt draudzības pieprasījumu no",
    friend_request_received: "Saņemts draudzības pieprasījums",
  },
  "en-GB": {
    or: "Or",
    new_emails_received:
      "You have received {count, plural, one {new email} other {{count} new emails}}",
    import_data: "Import data",
    import_data_from_invoice: "Import data from an invoice",
    select_invoice: "Select an invoice",
    process_invoice: "Process and import data",
    v0: "",
    tags: "Tags",
    recent_creds: "Recently used credentials",
    enter_ai_credentials: "Fill fields with AI assistance",
    enter_credentials: "Enter credentials",
    process_credentials: "Process credentials",
    modify_tags: "Modify tags",
    add_tags_to_invoice: "Add tags",
    add_tags_to_invoice_description:
      "Use tags to highlight invoices. Only you or your company will see the tags",
    allow_to_find_by_reg_nr: "Allow to be found by reg. nr",
    uuid_to_be_found_by: "ID to be found by",
    language: "Site language",
    lv_lng: "Latvian 🇱🇻 (Latviešu)",
    en_lng: "English 🇺🇸 (English)",
    something_went_wrong_recheck:
      "Something went wrong! Check all information and try again!",
    no_credentials_found:
      "You haven’t added or published any credentials yet. Go to settings to prepare them",
    original_attachments: "Original attachments",
    invoiceria_invoices: "Invoiceria interpreted invoices",
    logo: "Logo",
    select_existing_logo: "Select existing logo",
    no_logo_set: "No logo set...",
    set_logo: "Set logo",
    upload_new_logo: "Upload new logo",
    emails_share_get_started:
      "You haven’t received any emails yet! Share your email to start receiving invoices directly in the system!",
    sent_from: "From",
    invoice_emails_page: "Invoice emails",
    email_copied: "Email copied!",
    email_already_taken: "This email is not available",
    first_part_too_short: "The first part must be longer!",
    second_part_too_short: "The second part must be longer!",
    email_parts_cant_be_empty: "Both fields must be filled!",
    create_invoice_email_mailbox: "Create email",
    email_invoice_system_disclaimer:
      "The email must follow the two-part format. It could be, for example, first name-last name or any other type of combination",
    email_pl_name: "First name",
    email_pl_surname: "Last name",
    set_up_system_email: "Create email",
    email_page_hero_cta: "Create email",
    email_page_hero_description:
      "Create an email address that you can provide to service providers or suppliers to automatically receive all invoices in one place!",
    email_page_hero_title:
      "<highlight>From email to system</highlight>, simplify receiving invoices!",
    email_invoices: "Invoice email",
    service_quantity: "pcs.",
    view_original_invoice: "View original invoice",
    ai_read_invoice: "The invoice was interpreted by AI",
    this_is_ai_read_invoice:
      "This invoice was interpreted by AI, so the data may not be completely reliable. To ensure safety, please verify the accuracy of the data while we continue improving our invoice reader.",
    see_original_here: "View the original invoice here",
    search_for_invoice: "Search for invoice",
    invoice_template_description_info:
      "Invoice templates will allow you to prepare parts of the invoice, making it easier and faster to issue recurring invoices",
    changes_only_temp:
      "The changes made here will only apply to this specific invoice",
    pin_to_start: "Pin to start screen",
    edit_modal_template_mail_subject: "Edit email subject",
    edit_modal_email_content: "Edit email message",
    edit_modal_public_note: "Edit public note",
    edit_modal_services: "Edit services",
    create_invoice_and_send: "Create and send invoice",
    invoice_deadline_setting: "Invoice payment deadline",
    invoice_name_type: "Invoice name type",
    example_invoice_name: "Example invoice name",
    current_invoice_name: "Currently issued invoice name example",
    template_name: "Template name",
    template_description: "Template description",
    due_date_settings: "Due date settings",
    due_date_day_after: "Day after issuing",
    due_date_two_day_after: "Two days after issuing",
    due_date_three_day_after: "Three days after issuing",
    due_date_four_day_after: "Four days after issuing",
    due_date_five_day_after: "Five days after issuing",
    due_date_six_day_after: "Six days after issuing",
    due_date_week_after: "Week after issuing",
    due_date_two_week_after: "Two weeks after issuing",
    due_date_three_week_after: "Three weeks after issuing",
    due_date_month_after: "Month after issuing",
    due_date_two_month_after: "Two months after issuing",
    untitled_template: "Untitled template",
    edit_template_invoice: "Edit template",
    create_template: "Create template",
    template_invoices: "Invoice templates",
    service_show_full_price: "Price with tax",
    service_full_price: "Price with tax:",
    service_bulder: "Build service/product",
    service_name: "Service/product name",
    service_tax: "Tax",
    service_use_tax: "Use tax",
    service_price: "Price",
    service_description: "Service/product description",
    service_browse: "",
    service_save: "Save",
    fiz_per: "Individual",
    tax_rate_enabled: "Use tax rate (VAT)",
    business_account_badge: "Company",
    biz_account_created: "Business account created!",
    switch_to_business_account: "Switch to business account",
    switch_to_biz_account_info:
      "You can also switch to and from the business account by clicking on the profile picture in the sidebar menu",
    archive_invoice: "Archive invoice",
    archive: "Voided and Archived",
    are_you_sure_you_want_to_archive:
      "Are you sure you want to move this invoice to the archive?",
    void_invoice_confirm: "Are you sure you want to void this invoice?",
    no: "No",
    yes: "Yes",
    create_biz_acc_info:
      "After creating a business account - you’ll be able to switch to it by clicking on your user icon in the menu",
    email_already_in_use: "This email is already in use",
    allow_to_find_by_email: "Allow to be found by email",
    invalid_email: "Invalid email!",
    password_dont_match: "Passwords must match!",
    password_must_be_8: "Password must be at least 8 characters long",
    fill_more_fields_reg: "Please fill in all required fields",
    vat_on_by_default: "VAT is",
    vat_off_by_default: "VAT is not",
    currency: "Currency",
    default_values: "Default values",
    copy_to_clipboard: "Copy",
    blocked: "Blocked",
    unblock_user: "Unblock user",
    modify_biz_user: "Modify business user",
    remove_user: "Remove user",
    block_user: "Block user",
    send_invite: "Send invitation",
    add_user_to_biz: "Add user",
    un_publish_credentials: "Unpublish credentials",
    business: "Business",
    business_credentials: "Business credentials",
    business_edit_user: "Edit",
    upload_picture: "Upload picture",
    business_logo_img_upload: "Upload company picture/logo",
    business_main_acc: "Main",
    business_participants: "Business participants",
    business_settings: "Business settings",
    set_business_name: "Set business name",
    create_business_account: "Create a business account?",
    create_business_account_info:
      "In the business account, you will be able to add multiple users, including payers, accountants, and data analysts, and restrict their permissions to specific actions",
    create_business_account_cta: "Create business account",
    invoice_status: "Invoice status",
    payment_status: "Payment status",
    unpaid: "Unpaid",
    partially_paid: "Partially paid",
    sort_by: "Sort by",
    select_all_invoices: "Select all invoices",
    void: "Voided",
    download_selected:
      "Download {count, plural, one {invoice} other {{count} invoices}}",
    download_invoices_screen: "Download invoices",
    download_all_as_zip: "Download all invoices as zip",
    issue_invoice_to_this_user: "Issue invoice to this user",
    create_from_invoice: "Create from invoice",
    invoice_name_missing: "Select an invoice name",
    individual_tax_for_each: "Individual tax for each service",
    invoice_calendar_received: "Invoice received",
    invoice_calendar_sent: "Invoice sent",
    open_in_new_tab: "Open in new tab",
    more_info: "More info",
    created_on: "Created on",
    calendar_month_look: "Month",
    calendar_day_look: "Day",
    calendar_week_look: "Week",
    invoice_nr_text: "Text",
    invoice_nr_insert: "Insert",
    invoice_nr_explain_m: "Month (3 or 11)",
    invoice_nr_explain_0m: "Month (03 or 11)",
    invoice_nr_explain_d: "Day (3 or 11)",
    invoice_nr_explain_0d: "Day (03 or 11)",
    invoice_nr_explain_y: "Year (24)",
    invoice_nr_explain_Y: "Full year (2024)",
    invoice_nr_explain_nr: "Number",
    invoice_nr_explain__: "_",
    "invoice_nr_explain_-": "-",
    "invoice_nr_explain_/": "/",
    invoice_nr_explain_simple_m: "Month ",
    invoice_nr_explain_simple_0m: "Month ",
    invoice_nr_explain_simple_d: "Day ",
    invoice_nr_explain_simple_0d: "Day ",
    invoice_nr_explain_simple_y: "Year ",
    invoice_nr_explain_simple_Y: "Full year ",
    invoice_nr_explain_simple_nr: "Number ",
    invoice_nr_explain_simple__: "_ ",
    "invoice_nr_explain_simple_-": "- ",
    "invoice_nr_explain_simple_/": "/ ",
    invoice_nr_placeholder_text_explain:
      "Click on the buttons to create a dynamic invoice number that will use the values from the invoice issue date",
    add_contact_didnt_find: "Couldn't find the user?",
    invite_sent: "Invitation sent!",
    add_contact_valid_email:
      "Enter a valid email and send an invitation to join the platform!",
    add_contact_didnt_find_invite: "Send an invitation to {email}!",
    contact_alert_top: "",
    contact_alert_info:
      "Search for other registered users by email, name, or uuid and add them to your contacts to make invoicing easier!",
    pw_dont_match: "Passwords do not match!",
    new_password: "New password",
    link_maybe_expired: "The link may have expired",
    return_to_login: "Return to login",
    pwreset_invalid_token: "Invalid reset code or password. Please try again.",
    pwreset_something_wrong: "Something went wrong. Please try again later.",
    pwreset_error: "Error!",
    sent_from_eazybill: "Sent from invoiceria.com",
    invoice_attached: "Invoice attached",
    fill_receiver: "Fill in the recipient field!",
    email_subject: "Email subject",
    email_add_receivers: "Add recipients",
    issue_invoice: "Issue invoice",
    all_invoices: "All invoices",
    received_invoices: "Received invoices",
    invoice_calendar: "Invoice calendar",
    contacts: "Contacts",
    support_main: "Support",
    profile_settings: "Profile & Settings",
    total_vat: "Total due",
    issued: "Issued",
    reset_password_text: "Forgot password",
    enter_email: "Enter your email",
    reset_password_done: "Done!",
    reset_password_button: "Reset password",
    repeat_password: "Repeat password",
    welcome_to: "Welcome to",
    incorrect_login: "Incorrect data!",
    welcome_back: "Welcome back",
    password: "Password",
    dont_have_an_account: "Don't have an account?",
    already_have_account: "Already registered?",
    do_register: "Register",
    register: "Register",
    login: "Login",
    do_login: "Log in!",
    forgot_password: "Forgot password",
    support_sent: "Message sent!",
    subject: "Subject",
    your_problem: "Your message",
    message: "Message",
    report_problem: "Report a problem",
    support: "Problems / Support",
    go_to: "Go",
    add_contacts: "Add new contact",
    create_new_invoice: "Create new invoice",
    upload_profile_picture: "Upload profile picture",
    set_your_credentials_to_start:
      "Set your credentials to facilitate invoice creation for yourself and others!",
    profile_picture: "Profile picture",
    delete_friend_request: "Are you sure you want to delete:",
    no_public_credentials: "No public credentials",
    me: "Me",
    main_page_hero_title:
      "<highlight>A new way</highlight> to pay and get paid for invoices!",
    main_page_hero_description:
      "Never miss an invoice and stay on top of the finances you owe and are owed! Make your life easier",
    main_page_hero_cta: "Start",
    main_page_feature_title: "Import your invoices!",
    main_page_feature_status: "Coming soon...",
    main_page_feature_description:
      "To make life easier, we are working on a bulk import feature that will allow you to import existing invoices so you can manage them all in one place!",
    new_invoices_received:
      "You have {count, plural, one {1 new invoice} other {{count} new invoices}}",
    new_friend_requests_received:
      "You have received {count, plural, one {new friend request} other {{count} new friend requests}}",
    view: "View",
    private_note: "Private note",
    public_note_invoice: "Note",
    set_new_credentials: "Set new credentials",
    invoice_is_as_draft:
      "The invoice is currently in draft status, so it's only visible to you!",
    user_wont_see_disclaimer:
      "Submit the invoice to the user for it to appear in their payable invoices",
    set_issued: "Submit invoice to user",
    action_currently_not_possible: "Action currently unavailable",
    phone: "Phone",
    modify_issuer: "Modify issuer",
    set_credentials: "Set credentials",
    edit_credentials: "Edit credentials",
    publish_credentials: "Publish credentials",
    confirm_publish_credentials:
      "Are you sure you want to publish the credentials?",
    credentials_not_published: "Credentials not published!",
    credentials_not_published_info:
      "You can edit them until they are published. After that, you will need to create a new credential entry, so all previously issued invoices will retain the old credentials",
    delete_account_confirmation:
      "Are you sure you want to delete your account?",
    my_credentials: "My credentials",
    credentials_not_set: "Credentials not set",
    settings: "Settings",
    subscription: "Subscription",
    coming_eventually: "Coming soon",
    my_data: "My data",
    delete_account: "Delete account",
    action_irreversible: "Action is irreversible",
    create_invoice: "Create invoice",
    vat_hundredths: "VAT % in hundredths",
    add_service: "Add service",
    notes: "Private notes",
    vat: "VAT",
    public_notes: "Notes to include on the invoice",
    issued_by: "Issued by",
    issued_for: "Issued for",
    tax: "Tax",
    total_due: "Total due",
    to_pay: "To pay",
    subtotal: "Subtotal",
    subtotal_no_disc: "Without discount",
    due_date: "Due date",
    add_new_invoice: "Add new invoice",
    tax_rate: "Tax rate",
    add_issued_by: "Add issuer",
    add_issued_for: "Add recipient",
    modify_for: "Modify recipient",
    modify_by: "Modify issuer",
    jur_per: "Legal entity",
    priv: "Private person",
    name: "Name",
    total: "Total",
    surname: "Surname",
    bussiness_name: "Business name",
    business_name: "Business name",
    per_id: "Personal ID",
    reg_id: "Registration number",
    jur_addr: "Legal address",
    phy_addr: "Physical address",
    vat_code: "VAT Code",
    bank: "Bank",
    bank_acc: "Bank account",
    save: "Save",
    enter_service_name: "Service name",
    cost: "Price",
    quantity: "Quantity",
    remove: "Remove",
    discount: "Discount",
    fill_more_fields: "Fill more fields",
    invoice_nr: "Invoice no.",
    invoice_id: "Invoice key",
    search: "Search",
    records_per_page: "Records per page",
    invoice_overview: "Invoice overview",
    status: "Status",
    draft: "Draft",
    sent: "Sent",
    paid: "Paid",
    services: "Services",
    service: "Service",
    paid_on: "Paid on",
    send_modal: "Send invoice",
    edit_modal: "Edit status",
    send_to: "Recipient",
    email_content: "Email content",
    send: "Send",
    extend: "Extend due date",
    set_paid_date: "Register payment date",
    set_paid: "Mark as paid",
    set_voided: "Void invoice",
    paid_amount: "Paid amount",
    mark_as_partially_paid: "Mark as partially paid",
    pick_a_date: "Pick a date",
    voided: "Invoice voided",
    already_paid_amount: "Already paid",
    extended_due: "Payment extended until",
    new_invoices: "New invoices",
    sent_invoices: "Sent invoices",
    invoices_not_found: "No invoices found",
    invoice_name: "Invoice name",
    edit: "Edit",
    search_for_contact: "Search for contact",
    email: "Email",
    select_invoice_name_type: "Select invoice name type",
    inv_custom: "Custom",
    inv_free: "Free format",
    inv_pl_date: "INV and Date (e.g., INV20241223)",
    inv_pl_date_pl_nr: "INV, Date, and Number (e.g., INV2024122303)",
    inv_start_nr: "Start number",
    inv_name_example: "Invoice name example",
    inv_name: "Invoice name",
    issued_on: "Issued on",
    inv_format: "Invoice format",
    friend_request_sent: "Friend request sent",
    send_friend_request: "Send friend request",
    in_friends: "Friends",
    contact_name: "Contact name",
    requests: "Friend requests",
    accept_friend_request: "Accept friend request from",
    friend_request_received: "Friend request received",
    upload_image_for_ai: "Upload Image",
    forbidden: "Forbidden",
    allowed_to_edit_only_draft:
      "It's allowed only to edit invoices that are in draft status",
    return_to_invoice: "Return to all invoices",
    edit_invoice: "Edit invoice",
    save_invoice: "Save invoice",
    save_credentials_for_reuse: "Save credentials for re-use",
    saved_for_reuse: "Saved credentials",
    delete_credential: "Delete saved credentials:",
  },
};
