import {
  createPersonalCredentials,
  getPersonalCredentials,
  publishPersonalCredentials,
  updatePersonalCredentials,
} from "../../functions/api/credentialsApi";
import {
  Avatar,
  Button,
  Card,
  FileButton,
  Grid,
  Loader,
  Select,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import {
  getSelfUserData,
  getUserSettings,
  saveUserSettingsApi,
  uploadProfilePicAPI,
} from "../../functions/api/userApi";
import { useIntl } from "react-intl";
import { EditInvoiceSubjectModal } from "../../Components/Modals/EditInvoiceSubjectModal";
import {
  getData,
  getLanguage,
  setBusinessID,
  setData,
  setLanguage,
  setSelectedBusiness,
} from "../../functions/tokens";
import { getBusinessesData } from "../../functions/api/businessApi";

export const handleCredentialSave = async (credentials, modalData) => {
  if (credentials && credentials[0]?.published == 0) {
    updatePersonalCredentials({
      upd: modalData,
      cid: credentials[0].cid,
    }).then((x) => window.location.reload());
  } else {
    createPersonalCredentials(modalData).then((x) => window.location.reload());
  }
};

export const SettingsUploadPictureFrame = ({
  self,
  setSelf,
  setImgLoading,
  imgLoading,
  isItBusiness,
}) => {
  const intl = useIntl();

  function uploadProfilePic(file) {
    uploadProfilePicAPI(file).then((x) => {
      if (x.pic) {
        setImgLoading(true);
        const data = getData().data;
        const updatedData = { ...data, user_img: x.pic };
        if (!isItBusiness) {
          setData({ data: updatedData });
        }

        (isItBusiness ? getBusinessesData() : getSelfUserData()).then((x) => {
          setSelf(x);
          if (isItBusiness) {
            setSelectedBusiness(x);
          }
          setImgLoading(false);
        });
      }
    });
  }
  return (
    <div style={{ marginBottom: 10 }}>
      <Card withBorder>
        <Title order={5} style={{ marginBottom: 10 }}>
          {intl.formatMessage({
            id: isItBusiness ? "business_logo_img_upload" : "profile_picture",
          })}
        </Title>
        <Grid>
          <Grid.Col span={{ base: 12, md: 3 }}>
            {imgLoading ? (
              <Loader
                size="xl"
                style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}
              />
            ) : (
              <Avatar
                src={
                  self.user_img
                    ? self.user_img
                    : "https://images.unsplash.com/photo-1640960543409-dbe56ccc30e2?q=20&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                size={100}
                radius="xl"
              />
            )}
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6 }}
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <FileButton
              onChange={uploadProfilePic}
              accept="image/png,image/jpeg"
              disabled={imgLoading}
            >
              {(props) => (
                <Button {...props}>
                  {intl.formatMessage({
                    id: isItBusiness
                      ? "upload_picture"
                      : "upload_profile_picture",
                  })}
                </Button>
              )}
            </FileButton>
          </Grid.Col>
        </Grid>
      </Card>
    </div>
  );
};

export const DefaultValueCard = () => {
  return;
  const [initialSettings, setInitialSettings] = useState([]);
  const [settings, setSettings] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    getUserSettings().then((x) => {
      setSettings(x);
      setInitialSettings(x);
    });
  }, []);

  const saveSettings = () => {
    saveUserSettingsApi(settings);
  };

  const getSettingValue = (settingName) => {
    const setting = settings.find((s) => s.setting_name === settingName);
    return setting ? setting.setting_value : "false"; // Default value if setting is not found
  };

  const updateSetting = (settingName, settingValue) => {
    setSettings((prevState) => {
      const existingSettingIndex = prevState.findIndex(
        (s) => s.setting_name === settingName
      );

      if (existingSettingIndex >= 0) {
        // Update existing setting
        const updatedSettings = JSON.parse(JSON.stringify(prevState));
        updatedSettings[existingSettingIndex].setting_value = settingValue;
        return updatedSettings;
      } else {
        // Add new setting
        return [
          ...JSON.parse(JSON.stringify(prevState)),
          {
            setting_name: settingName,
            setting_value: settingValue,
          },
        ];
      }
    });
  };
  return (
    <Card withBorder style={{ marginTop: 10 }}>
      <Title order={4}>{intl.formatMessage({ id: "default_values" })}</Title>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 4 }}>
          <Select
            clearable={false}
            unselectable={false}
            disabled
            defaultValue="EUR"
            label={intl.formatMessage({ id: "currency" })}
            data={["EUR"]}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 4 }}>
          <Select
            value={getSettingValue("allow_email")}
            onChange={(i) => updateSetting("allow_email", i)}
            label={intl.formatMessage({ id: "allow_to_find_by_email" })}
            clearable={false}
            unselectable={false}
            data={[
              {
                label: intl.formatMessage({ id: "yes" }),
                value: "true",
              },
              {
                label: intl.formatMessage({ id: "no" }),
                value: "false",
              },
            ]}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 4 }}>
          <Select
            clearable={false}
            unselectable={false}
            value={getSettingValue("allow_reg_nr")}
            onChange={(i) => updateSetting("allow_reg_nr", i)}
            label={intl.formatMessage({ id: "allow_to_find_by_reg_nr" })}
            data={[
              {
                label: intl.formatMessage({ id: "no" }),
                value: "false",
              },
              {
                label: intl.formatMessage({ id: "yes" }),
                value: "true",
              },
            ]}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 4 }}>
          <Select
            clearable={false}
            unselectable={false}
            value={getSettingValue("vat")}
            onChange={(i) => updateSetting("vat", i)}
            label={intl.formatMessage({ id: "vat" })}
            defaultValue="true"
            data={[
              {
                label: intl.formatMessage({ id: "vat_on_by_default" }),
                value: "true",
              },
              {
                label: intl.formatMessage({ id: "vat_off_by_default" }),
                value: "false",
              },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          {JSON.stringify(initialSettings) !== JSON.stringify(settings) && (
            <Button onClick={saveSettings}>
              {intl.formatMessage({ id: "save" })}
            </Button>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export const SettingsCredentialManagement = () => {
  const [credentials, setCredentials] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    getPersonalCredentials().then((x) => {
      setCredentials(x);
    });
  }, []);

  const handleOpen = ({ data }) => {
    const preset = data ? JSON.parse(data) : null;
    const modalInitSettings = {
      type: "issuer",
      searchOff: true,
      hideEmail: true,
    };
    const toSet = { ...modalInitSettings, ...{ data: preset } };
    setModalData(toSet);
    setModalOpen(true);
  };

  const publishCredentials = () => {
    if (
      window.confirm(
        intl.formatMessage({ id: "confirm_publish_credentials" }) +
          intl.formatMessage({ id: "credentials_not_published_info" })
      )
    ) {
      publishPersonalCredentials({ cid: credentials[0].cid }).then((x) =>
        window.location.reload()
      );
    }
  };
  return (
    <>
      <EditInvoiceSubjectModal
        modalData={modalData}
        modalOpen={modalOpen}
        handleDataSave={() => handleCredentialSave(credentials, modalData)}
        setModalData={setModalData}
        setModalOpen={setModalOpen}
      />
      <Card withBorder>
        <Title order={5}>{intl.formatMessage({ id: "my_credentials" })}</Title>
        {credentials && credentials.length > 0 ? (
          <div>
            <hr />
            {credentials[0]?.published == 0 && (
              <div style={{ marginBottom: 10 }}>
                <div style={{ color: "red" }}>
                  <Title order={5}>
                    {intl.formatMessage({ id: "credentials_not_published" })}
                  </Title>
                  <small>
                    {intl.formatMessage({
                      id: "credentials_not_published_info",
                    })}
                  </small>
                </div>
                <Button onClick={publishCredentials}>
                  {intl.formatMessage({ id: "publish_credentials" })}
                </Button>
              </div>
            )}
            {credentials[0] &&
              Object.entries(JSON.parse(credentials[0].data)).map((x) => (
                <Grid key={x[0]}>
                  <Grid.Col span={{ base: 12, md: 2, xs: 4 }}>
                    <strong>{intl.formatMessage({ id: x[0] })}</strong>:{" "}
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 10, xs: 8 }}>
                    <span>{x[1]}</span>
                  </Grid.Col>
                </Grid>
              ))}
            <div style={{ marginTop: 15 }}>
              {credentials[0]?.published == 1 ? (
                <Button onClick={() => handleOpen({})}>
                  {intl.formatMessage({ id: "set_new_credentials" })}
                </Button>
              ) : (
                <Button onClick={() => handleOpen(credentials[0])}>
                  {intl.formatMessage({ id: "edit_credentials" })}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Title order={6}>
              {intl.formatMessage({ id: "credentials_not_set" })}
            </Title>
            <Button onClick={() => handleOpen({})}>
              {intl.formatMessage({ id: "set_credentials" })}
            </Button>
          </div>
        )}
      </Card>
    </>
  );
};

export const handleTheLanguageSwitch = (data) => {
  setLanguage(data);
  window.location.reload();
};

export const LanguageSwitchMng = () => {
  const intl = useIntl();

  return (
    <Card withBorder style={{ marginBottom: 10 }}>
      <Title order={5}>{intl.formatMessage({ id: "language" })}</Title>
      <Select
        value={getLanguage()}
        onChange={(_value, option) => handleTheLanguageSwitch(option.value)}
        data={[
          {
            value: "lv-LV",
            label: `${intl.formatMessage({ id: "lv_lng" })}`,
          },
          {
            value: "en-GB",
            label: `${intl.formatMessage({ id: "en_lng" })}`,
          },
        ]}
      />
    </Card>
  );
};
