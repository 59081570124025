import { Button, Combobox, useCombobox } from '@mantine/core';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Triangle, TriangleInverted } from 'tabler-icons-react';

export default function DropDownButton({
  items,
  selectedItem,
  setSelectedItem,
  label,
  sortAction,
}) {
  const [open, setOpen] = useState();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const intl = useIntl();

  const options = items.map((item) => (
    <Combobox.Option value={item} key={item}>
      {intl.formatMessage({ id: item })}
    </Combobox.Option>
  ));

  const toggleFn = () => {
    setOpen(!open);
    combobox.toggleDropdown();
  };

  const setFn = (val) => {
    setSelectedItem(val);
    combobox.closeDropdown();
  };

  return (
    <>
      <Combobox
        store={combobox}
        width={250}
        position="bottom-start"
        withArrow
        withinPortal={false}
        onOptionSubmit={(val) => {
          setFn(val);
        }}
      >
        <Combobox.Target>
          <div>
            <Button size="xs" onClick={() => setFn(selectedItem)} radius="xs">
              {label}
            </Button>
            <Button
              style={{ backgroundColor: '#025fb0', marginLeft: -2 }}
              radius="xs"
              size="xs"
              onClick={toggleFn}
              p={10}
            >
              {open ? <Triangle size={10} /> : <TriangleInverted size={10} />}
            </Button>
          </div>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </>
  );
}
