import {
  Paper,
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  Anchor,
} from '@mantine/core';
import './RegisterScreen.css';
import { useEffect, useState } from 'react';
import { setData, setGroup, setToken, setUserId } from '../../functions/tokens';
import {
  isEmailInUse,
  registerUser,
} from '../../functions/api/authApi';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../Components/Logo/Logo';
import { isValidEmail } from '../../functions/helpers';

export function RegisterScreen() {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [repeat, setRepeat] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const checkIfEmailIsValid = () => {
    if (isValidEmail(email)) {
      isEmailInUse({ email: email }).then((x) => {
        if (x.data == 1) {
          setError(intl.formatMessage({ id: 'email_already_in_use' }));
        } else {
          setError('');
        }
      });
    } else {
      setError(intl.formatMessage({ id: 'invalid_email' }));
    }
  };

  useEffect(() => {
    checkIfEmailIsValid();
  }, [email]);

  useEffect(() => {
    if (password.trim() != '' && password.length < 8) {
      setError(intl.formatMessage({ id: 'password_must_be_8' }));
    } else {
      setError('');
    }
    if (password.length >= 8) {
      if (password != repeat) {
        setError(intl.formatMessage({ id: 'password_dont_match' }));
      } else {
        setError('');
      }
    }
  }, [password, repeat]);

  const handleSubmit = async (e) => {
    setError('');
    e.preventDefault();

    if (
      name.trim() != '' &&
      email.trim() != '' &&
      password.trim() != '' &&
      repeat.trim() != ''
    ) {
      checkIfEmailIsValid();
      if (error == '') {
        try {
          const response = await registerUser({
            email,
            password,
            phone,
            name,
            surname,
          });

          const data = response;
          setToken(data.token);
          setGroup(data.group);
          setUserId(data.id);
          setData(data);
          navigate('/settings');
        } catch (err) {
          setError(err.message);
          //   formRef.current.classList.add("shake");
        }
      }
    } else {
      setError(intl.formatMessage({ id: 'fill_more_fields_reg' }));
    }
  };

  return (
    <div className="reg-wrapper">
      <Paper className="form" radius={0} p={30}>
        <Title order={2} className={`title`} ta="center" mt="md" mb={20}>
          {intl.formatMessage({ id: 'welcome_to' })} <Logo />
        </Title>
        <form>
          <TextInput
            label={intl.formatMessage({ id: 'name' })}
            placeholder="John"
            size="md"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            label={intl.formatMessage({ id: 'surname' })}
            placeholder="Doe"
            size="md"
            required
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          <TextInput
            label={intl.formatMessage({ id: 'email' })}
            placeholder="hello@gmail.com"
            size="md"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            label={intl.formatMessage({ id: 'phone' })}
            placeholder="+00 0000000"
            size="md"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <PasswordInput
            label={intl.formatMessage({ id: 'password' })}
            placeholder={intl.formatMessage({ id: 'password' })}
            mt="md"
            size="md"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            label={intl.formatMessage({ id: 'repeat_password' })}
            placeholder={intl.formatMessage({ id: 'password' })}
            mt="md"
            size="md"
            value={repeat}
            required
            onChange={(e) => setRepeat(e.target.value)}
          />
        </form>
        {error && (
          <div
            style={{
              textAlign: 'center',
              color: 'red',
              marginTop: 20,
              marginBottom: -20,
            }}
          >
            {error}
          </div>
        )}
        <Button fullWidth mt="xl" size="md" onClick={handleSubmit}>
          {intl.formatMessage({ id: 'register' })}
        </Button>

        <Text ta="center" mt="md">
          {intl.formatMessage({ id: 'already_have_account' })}{' '}
          <Anchor
            href="#"
            fw={700}
            onClick={(event) => (window.location.href = '/login')}
          >
            {intl.formatMessage({ id: 'do_login' })}
          </Anchor>
        </Text>
      </Paper>
    </div>
  );
}
