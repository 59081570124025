import {
  Alert,
  Button,
  Card,
  HoverCard,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getAllTemplateInvoices } from "../../functions/api/templateApi";
import { IconInfoCircle } from "@tabler/icons-react";
import DataTable from "../../Components/DataTable";
import { ListDetails } from "tabler-icons-react";

export const TemplateListScreen = () => {
  const intl = useIntl();
  const theme = useMantineTheme();

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getAllTemplateInvoices().then((x) => {
      setTemplates(x);
    });
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title order={3}>
          {intl.formatMessage({ id: "template_invoices" })}
        </Title>
        <Button onClick={() => (window.location.href = "/create-invoice/new")}>
          {intl.formatMessage({ id: "create_template" })}
        </Button>
      </div>
      <Alert style={{ marginTop: 10 }} color="green" icon={<IconInfoCircle />}>
        {intl.formatMessage({ id: "invoice_template_description_info" })}
      </Alert>
      <hr />
      <div>
        {templates &&
          templates.map((template) => {
            const services = template.services_json
              ? JSON.parse(template.services_json)
              : [];

            const individual_vat = template.vat_individual;

            const columns = [
              {
                key: "name",
                title: "service",
                searchable: true,
                sort: true,
                render: (data) => data.name,
              },
              {
                key: "cost",
                title: "cost",
                searchable: false,
                sort: true,
                render: (data) => `${data.cost} €`,
              },
              {
                key: "quantity",
                title: "quantity",
                searchable: true,
                sort: true,
                render: (data) => data.quantity,
              },
              {
                key: "total",
                title: "total",
                searchable: true,
                sort: true,
                render: (data) => `${data.cost * data.quantity} €`,
              },
              individual_vat && {
                key: "vat",
                title: "vat",
                searchable: false,
                sort: true,
                render: (data) => (data.vat ? `${data.vat} %` : ""),
              },
            ].filter(Boolean);
            return (
              <Card withBorder style={{ marginTop: 5 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginTop: -10, marginBottom: -5 }}>
                    <div></div>
                    <div>
                      <Text fw={600} style={{ fontSize: 16 }}>
                        {template.template_name
                          ? template.template_name
                          : intl.formatMessage({ id: "untitled_template" })}
                      </Text>
                    </div>
                    <div style={{ color: "gray" }}>
                      <small>{template.template_description}</small>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    ></div>
                    <div>
                      {services.length > 0 && (
                        <HoverCard width={380} shadow="md">
                          <HoverCard.Target>
                            <Button size="xs" style={{paddingLeft: 5, paddingRight: 8}} >
                              <ListDetails
                                size={14}
                                style={{ marginRight: 10 }}
                              />
                              {intl.formatMessage({ id: "services" })}
                            </Button>
                          </HoverCard.Target>
                          <HoverCard.Dropdown>
                            <div
                              style={{
                                margin: -10,
                                marginBottom: -5,
                                marginTop: -25,
                              }}
                            >
                              <DataTable data={services} columns={columns} />
                            </div>
                          </HoverCard.Dropdown>
                        </HoverCard>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 12,
                        }}
                      >
                        {template.total_vat > 0 && (
                          <div>
                            <strong>
                              {intl.formatMessage({ id: "total_vat" })}:{" "}
                            </strong>
                            {template.total_vat}€
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: -5 }}>
                    <Button
                      style={{
                        marginBottom: 10,
                        paddingLeft: 4,
                        paddingRight: 4,
                      }}
                      size="xs"
                      onClick={() =>
                        (window.location.href = `/template-invoices/${template.t_uuid}`)
                      }
                    >
                      {intl.formatMessage({ id: "edit_template_invoice" })}
                    </Button>
                    {
                      <Button
                        style={{
                          marginBottom: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                        }}
                        color="green"
                        size="xs"
                        onClick={() =>
                          (window.location.href = `/templating/${template.t_uuid}`)
                        }
                      >
                        {intl.formatMessage({ id: "create_invoice" })}
                      </Button>
                    }
                  </div>
                </div>
              </Card>
            );
          })}
      </div>
    </div>
  );
};
