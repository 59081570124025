import { Switch, useMantineTheme } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useIntl } from 'react-intl';

export const ItemSwitch = ({ checked, setChecked, labelKey }) => {
  const intl = useIntl();
  const theme = useMantineTheme();


  return (
    <div style={{ display: 'flex' }}>
      <Switch
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        size="md"
        style={{ marginBottom: 10, marginRight: 10 }}
        // label={}
        thumbIcon={
          checked ? (
            <IconCheck
              style={{ width: 12, height: 12 }}
              color={theme.colors.teal[6]}
              stroke={3}
            />
          ) : (
            <IconX
              style={{ width: 12, height: 12 }}
              color={theme.colors.red[6]}
              stroke={3}
            />
          )
        }
      />
      {labelKey && intl.formatMessage({ id: labelKey })}
    </div>
  );
};
