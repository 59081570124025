import { Button, Card, Grid } from "@mantine/core";
import { useIntl } from "react-intl";
import UserCard from "../../Components/UserCard";
import { issuedFields } from "../../Components/Modals/EditInvoiceSubjectModal";

import { useEffect, useMemo, useState } from "react";
import { getRecentCreds } from "../../functions/api/credentialsApi";
import { ChevronDown, ChevronUp } from "tabler-icons-react";

export const calculateIndividualSubTotalNoVat = (services, vat_individual) => {
  return services.reduce((total, service) => {
    const quantity = Number(service.quantity) || 1;

    if (vat_individual) {
      const vatMultiplier = 1 + Number(service.vat ? service.vat : 0) / 100;
      const costWithoutVat = Number(service.cost) / vatMultiplier;
      return total + costWithoutVat * quantity;
    } else {
      return total + Number(service.cost) * quantity;
    }
  }, 0);
};

// Use it with useMemo
export const useIndividualSubTotalNoVat = (services, vat_individual) => {
  return useMemo(
    () => calculateIndividualSubTotalNoVat(services, vat_individual),
    [services, vat_individual]
  );
};

// Function to calculate subtotal
export const calculateSubtotal = (services, template) => {
  return services.reduce((total, service) => {
    const quantity = Number(service.quantity) || 1;

    if (template.vat_individual) {
      const vatMultiplier = 1 + Number(service.vat) / 100;
      return total + Number(service.cost) * quantity;
    } else {
      return total + Number(service.cost) * quantity;
    }
  }, 0);
};

// Use it with useMemo
export const useSubtotal = (services, template) => {
  return useMemo(
    () => calculateSubtotal(services, template),
    [services, template]
  );
};

// Function to calculate amountDue
export const calculateAmountDue = (subtotal, template) => {
  return template.vat_individual
    ? subtotal
    : subtotal * (1 + Number(template.vat_perc) / 100);
};

// Use it with useMemo
export const useAmountDue = (subtotal, template) => {
  return useMemo(
    () => calculateAmountDue(subtotal, template),
    [subtotal, template]
  );
};

export const dataMapping = (data) => {
  const mappedData = {};

  if (data.data == null) {
    return null;
  }
  issuedFields.forEach((field) => {
    if (data?.data[field.name]) {
      mappedData[field.name] = data?.data[field.name];
    }
  });
  return mappedData;
};

export const RenderInvoiceSubjectFields = ({ type, obj, setUpModal }) => {
  const intl = useIntl();
  const [users, setUsers] = useState([]);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    getRecentCreds().then((x) => {
      setUsers(x);
    });
  }, []);
  return (
    <Grid.Col span={{ base: 12, md: 6 }}>
      <div>{Object.entries(obj).friend ? "friend" : ""}</div>
      <div style={{ cursor: "pointer" }}>
        {Object.keys(obj).length > 0 ? (
          <Card
            withBorder
            onClick={() => setUpModal({ open: true, type: type, data: obj })}
          >
            <div style={{ fontSize: 18 }}>
              <strong style={{ fontWeight: 800 }}>
                {intl.formatMessage({ id: `issued_${type}` })}
              </strong>
            </div>
            {obj &&
              obj["friend"] &&
              (obj["friend"]?.userid || obj["friend"]?.biz_id) && (
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <div style={{ margin: -18 }}>
                    <UserCard
                      config={{ onClick: false }}
                      data={obj["friend"]}
                    />
                  </div>
                </div>
              )}
            {obj.priv == true && obj.reg_nr ? (
              <div>
                <strong style={{ fontWeight: 600 }}>
                  {intl.formatMessage({ id: "reg_nr" })}:{" "}
                </strong>
                {obj.reg_nr}
              </div>
            ) : (
              obj.email && (
                <div>
                  <strong style={{ fontWeight: 600 }}>
                    {intl.formatMessage({ id: "email" })}:{" "}
                  </strong>
                  {obj.email}
                </div>
              )
            )}
            {obj.name && obj.priv != true && (
              <div>
                <strong style={{ fontWeight: 600 }}>
                  {intl.formatMessage({ id: "name" })}:{" "}
                </strong>
                {obj.name}
              </div>
            )}
            {obj.surname && obj.priv != true && (
              <div>
                <strong style={{ fontWeight: 600 }}>
                  {intl.formatMessage({ id: "surname" })}:{" "}
                </strong>
                {obj.surname}
              </div>
            )}
            {obj.bussiness_name && obj.priv == true && (
              <div>
                <strong style={{ fontWeight: 600 }}>
                  {intl.formatMessage({ id: "bussiness_name" })}:{" "}
                </strong>
                {obj.bussiness_name}
              </div>
            )}
            {obj.per_id && obj.priv != true && (
              <div>
                <strong style={{ fontWeight: 600 }}>
                  {intl.formatMessage({ id: "per_id" })}:{" "}
                </strong>
                {obj.per_id}
              </div>
            )}
          </Card>
        ) : (
          <Card withBorder style={{ padding: 8 }}>
            <Button
              fullWidth
              onClick={() => setUpModal({ open: true, type: type, data: obj })}
            >
              {intl.formatMessage({ id: `add_issued_${type}` })}
            </Button>
            {users && users.length > 0 && (
              <div>
                <span
                  style={{
                    fontSize: 14,
                    color: "dodgerblue",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => setHide(!hide)}
                >
                  {intl.formatMessage({ id: "recent_creds" })}{" "}
                  {hide ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
                </span>
                {!hide &&
                  users.map((user) => {
                    const userCreds = JSON.parse(user.credentials);
                    const userData = {
                      ...user,
                      ...userCreds,
                      friend: user.userid ? user : null,
                    };
                    return (
                      <UserCard
                        data={user}
                        config={{
                          padding: 6,
                          showApprovalStatus: false,
                          showCredentials: true,
                          showButtons: false,
                          showPointer: true,
                          showCredentialsData: true,
                          hideAvatar: true,
                          onClick: () => {
                            setUpModal({
                              open: true,
                              type: type,
                              data: userData,
                            });
                          },
                        }}
                      />
                    );
                  })}
              </div>
            )}
          </Card>
        )}
      </div>
    </Grid.Col>
  );
};
