import { Select, Title, LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { getInvoiceTags, getInvoices } from "../../functions/api/invoiceApi";
import { FormattedMessage } from "react-intl";
import InvoiceList from "../../Components/InvoiceList";

function InvoiceOverviewScreen({ type }) {

  const [invoices, setInvoices] = useState([]);
  const [perPage, setPerPage] = useState("40");
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [tags, setTags] = useState([]);

  const [filters, setFilters] = useState({
    paymentStatus: [],
    invoiceStatus: [],
    searchTerm: "",
    tags: [],
  });

  useEffect(() => {
    getInvoiceTags().then((x) =>
      setTags(x.map((tag) => ({ label: tag.tag_name, value: tag.tag_id })))
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getInvoices({ type: type, filters }).then((items) => {
      setInvoices(items);
      setIsLoading(false);
    });
  }, [filters, type]);

  return (
    <div>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title order={2}>
            {!type && <FormattedMessage id="invoice_overview" />}
            {type === "new" && <FormattedMessage id="new_invoices" />}
            {type === "sent" && <FormattedMessage id="sent_invoices" />}
          </Title>

          <Select
            style={{ marginTop: -20, width: 70 }}
            value={perPage}
            size="xs"
            onChange={(x) => setPerPage(x)}
            data={[
              { value: "40", label: "40" },
              { value: "80", label: "80" },
              { value: "100", label: "100" },
              { value: "200", label: "200" },
              { value: "500", label: "500" },
            ]}
            label={<FormattedMessage id="records_per_page" />}
          />
        </div>
        <div style={{ marginTop: 20, marginBottom: 15 }}>
          <div
            style={{
              position: "relative",
              height: isLoading ? 600 : "auto",
            }}
          >
            <LoadingOverlay
              visible={isLoading}
              overlayProps={{ radius: "sm", blur: 2 }}
              loaderProps={{ color: "pink", type: "bars" }}
            />
            <InvoiceList
              tags={tags}
              invoices={invoices}
              type={type}
              filters={filters}
              setFilters={setFilters}
              setSelected={setSelected}
              selected={selected}
              itemsPerPage={parseInt(perPage, 10)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceOverviewScreen;
