import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Grid,
  MultiSelect,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  CoinEuro,
  FileAlert,
  Search,
  Tag,
  Triangle,
  TriangleInverted,
} from "tabler-icons-react";
import { PaginationItem } from "../Pagination";
import { IconDownload, IconRobot, IconStars } from "@tabler/icons-react";
import { saveInvoiceList } from "../../functions/api/invoiceApi";
import DropDownButton from "../DropDownButton/DropDownButton";
import { DatePickerInput } from "@mantine/dates";

const IssuerPart = ({ data, user, list = false }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      fontSize: 12,
      marginBottom: 5,
    }}
  >
    {console.log(data)}
    {list ? (
      <div
        style={{
          fontSize: 11,
          marginBottom: -5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {data.priv
          ? data.name || data.surname
          : data.business_name && (
              <>
                <strong style={{ marginRight: 7 }}>
                  {data.priv ? (
                    <>
                      {data.name} {data.surname}
                    </>
                  ) : (
                    data.business_name
                  )}{" "}
                </strong>{" "}
              </>
            )}
        {data.priv
          ? data.reg_id || data.vat_code
          : data.per_id && (
              <>
                (
                {data.priv
                  ? data.reg_id
                    ? data.reg_id
                    : data.vat_code
                  : data.per_id}
                )
              </>
            )}
      </div>
    ) : (
      <>
        {user && (
          <div
            style={{ marginBottom: 4, display: "flex", flexDirection: "row" }}
          >
            <Avatar variant="filled" radius="sm" size="xs" src="" />
            <div style={{ marginLeft: 4 }}>
              <small>User</small>
            </div>
          </div>
        )}
        <small style={{ marginTop: -2 }}>
          {data.name} {data.surname} {data.business_name}
        </small>
        <small style={{ marginTop: -2 }}>{data.email}</small>
        <small style={{ marginTop: -2 }}>
          {data.per_id} {data.reg_id ? data.reg_id : data.vat_code}
        </small>
      </>
    )}
  </div>
);

const columnValue = (item, columnKey) => {
  switch (columnKey) {
    case "due_date":
      return new Date(item.due_date);
    case "invoice_name":
      return item.invoice_name;
    case "total_vat":
      return item.total_vat;
    case "status":
      return item.status;
    case "issued_for":
      return JSON.parse(item.issuedfor_json).name;
    case "issued_by":
      return JSON.parse(item.issuedby_json).name;
    default:
      return "";
  }
};

export const InvoiceList = ({
  invoices,
  config,
  itemsPerPage = 40,
  type,
  setFilters,
  filters,
  selected,
  setSelected,
  tags,
}) => {
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("due_date");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const totalPages = useMemo(
    () => Math.ceil(invoices.length / itemsPerPage),
    [invoices.length, itemsPerPage]
  );

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
    if (currentPage == 0) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const handleSort = (columnKey) => {
    setSortColumn(columnKey);
    setSortDirection((prevDirection) =>
      sortColumn === columnKey && prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const filteredData = useMemo(
    () =>
      invoices.filter((invoice) =>
        Object.values(invoice).some((value) =>
          value?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )
      ),
    [invoices, searchTerm]
  );

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const columnA = columnValue(a, sortColumn);
      const columnB = columnValue(b, sortColumn);
      if (columnA === columnB) return 0;
      if (sortDirection === "asc") return columnA < columnB ? -1 : 1;
      return columnA > columnB ? -1 : 1;
    });
  }, [filteredData, sortColumn, sortDirection]);

  const handleCheckboxChange = (invoice_id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(invoice_id)
        ? prevSelected.filter((id) => id !== invoice_id)
        : [...prevSelected, invoice_id]
    );
  };

  const handleSaveList = async () => {
    const urlResp = await saveInvoiceList(selected);
    setSelected([]);
    window.open("/download/" + urlResp.url, "_blank").focus();
  };

  const toggleAllInvoices = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(sortedData.map((a) => a.invoice_id));
    }
  };

  return (
    <div>
      <div style={{ position: "fixed", top: 10, right: 16, zIndex: 5000 }}>
        {selected.length > 0 && (
          <>
            <Button onClick={handleSaveList} hiddenFrom="sm">
              <IconDownload size={14} />
            </Button>
            <Button
              onClick={handleSaveList}
              visibleFrom="sm"
              rightSection={<IconDownload size={14} />}
            >
              {intl.formatMessage(
                { id: "download_selected" },
                { count: selected?.length }
              )}
            </Button>
          </>
        )}
      </div>
      <Grid style={{ marginBottom: 10 }}>
        <Grid.Col span={{ base: 12, md: 4 }} style={{ marginTop: -15 }}>
          <MultiSelect
            label={intl.formatMessage({ id: "payment_status" })}
            data={[
              { label: intl.formatMessage({ id: "paid" }), value: "paid" },
              { label: intl.formatMessage({ id: "unpaid" }), value: "unpaid" },
              {
                label: intl.formatMessage({ id: "partially_paid" }),
                value: "partially_paid",
              },
            ]}
            searchable
            clearable
            value={filters.paymentStatus}
            onChange={(value) => handleFilterChange("paymentStatus", value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }} style={{ marginTop: -15 }}>
          <MultiSelect
            label={intl.formatMessage({ id: "invoice_status" })}
            data={[
              { label: intl.formatMessage({ id: "draft" }), value: "draft" },
              { label: intl.formatMessage({ id: "issued" }), value: "issued" },
              { label: intl.formatMessage({ id: "void" }), value: "void" },
              { label: intl.formatMessage({ id: "sent" }), value: "sent" },
              { label: intl.formatMessage({ id: "paid" }), value: "paid" },
            ]}
            searchable
            clearable
            value={filters.invoiceStatus}
            onChange={(value) => handleFilterChange("invoiceStatus", value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }} style={{ marginTop: -15 }}>
          <MultiSelect
            label={intl.formatMessage({ id: "tags" })}
            data={tags}
            searchable
            clearable
            value={filters.tags}
            hidePickedOptions
            onChange={(value) => handleFilterChange("tags", value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }} style={{ marginTop: -15 }}>
          <DatePickerInput
            valueFormat="DD/MM/YYYY"
            type="range"
            allowSingleDateInRange
            label={intl.formatMessage({ id: "created_on" })}
            placeholder={intl.formatMessage({ id: "created_on" })}
            value={filters.createdOn}
            onChange={(value) => handleFilterChange("createdOn", value)}
            clearable
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }} style={{ marginTop: -15 }}>
          <DatePickerInput
            valueFormat="DD/MM/YYYY"
            type="range"
            allowSingleDateInRange
            label={intl.formatMessage({ id: "due_date" })}
            placeholder={intl.formatMessage({ id: "due_date" })}
            value={filters.dueDate}
            onChange={(value) => handleFilterChange("dueDate", value)}
            clearable
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }} style={{ marginTop: -15 }}>
          <DatePickerInput
            valueFormat="DD/MM/YYYY"
            type="range"
            allowSingleDateInRange
            label={intl.formatMessage({ id: "paid_on" })}
            placeholder={intl.formatMessage({ id: "paid_on" })}
            value={filters.paidOn}
            onChange={(value) => handleFilterChange("paidOn", value)}
            clearable
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
          <TextInput
            value={filters.searchTerm}
            onChange={(e) => handleFilterChange("searchTerm", e.target.value)}
            leftSection={<Search style={{ width: 20, height: 20 }} />}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
          <div
            style={{
              paddingTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DropDownButton
              items={[
                "total_vat",
                "due_date",
                "status",
                "issued_by",
                "issued_for",
                "invoice_name",
              ]}
              selectedItem={sortColumn}
              setSelectedItem={handleSort}
              label={
                <>
                  {sortDirection != "" ? (
                    sortDirection === "asc" ? (
                      <Triangle style={{ marginRight: 5 }} size={12} />
                    ) : (
                      <TriangleInverted style={{ marginRight: 5 }} size={12} />
                    )
                  ) : null}
                  {intl.formatMessage({ id: sortColumn })}
                </>
              }
            />
            <Tooltip label={intl.formatMessage({ id: "select_all_invoices" })}>
              <Checkbox
                onClick={toggleAllInvoices}
                title={intl.formatMessage({ id: "select_all_invoices" })}
              />
            </Tooltip>
          </div>
        </Grid.Col>
      </Grid>
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{
            display: "flex",
            marginBottom: 10,
            marginTop: 10,
            justifyContent: "center",
          }}
        />
      )}
      {sortedData
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        .map((item) => {
          const payDay = new Date(item.due_date).toLocaleDateString();
          const badgeColor =
            item.status === "draft"
              ? "black"
              : item.status === "paid"
              ? "green"
              : item.status === "void"
              ? "red"
              : "dodgerblue";
          const issued_by = JSON.parse(item.issuedby_json);
          const issued_for = JSON.parse(item.issuedfor_json);

          return (
            <div
              key={item.encrypted}
              style={{
                border: "1px solid lightgrey",
                padding: 10,
                paddingBottom: 10,
                backgroundColor:
                  type == "new" && item.seen == 0
                    ? "#ffc0002b"
                    : item.status === "paid"
                    ? "#00ff0050"
                    : item.status === "void"
                    ? "#ff000030"
                    : "#fff",
                borderBottom: "unset",
              }}
            >
              <a
                href={`/invoice/${item.encrypted}`}
                style={{ textDecoration: "none", color: "unset" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    {/* <small
                      style={{ fontSize: 10, color: "grey", fontWeight: 200 }}
                    >
                      {item.encrypted}
                    </small> */}
                    <IssuerPart data={issued_by} list={true} />
                    <div
                      style={{
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span title={intl.formatMessage({ id: "invoice_name" })}>
                        {item.invoice_name}{" "}
                      </span>
                      {item.original_ref && (
                        <Badge
                          color="orange"
                          variant="outline"
                          title={intl.formatMessage({ id: "ai_read_invoice" })}
                          style={{ marginLeft: 20 }}
                          size="xs"
                        >
                          <IconRobot size={16} style={{ marginBottom: -4 }} />
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      title={`${intl.formatMessage({
                        id: "due_date",
                      })} ${payDay}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 14,
                          justifyContent: "flex-end",
                        }}
                      >
                        <FileAlert size={14} style={{ marginRight: 5 }} />
                        {payDay}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      {/* <Badge color="orange" size='xs' style={{marginRight: 10}}>AI</Badge> */}
                      <Badge
                        color={badgeColor}
                        style={{ float: "right" }}
                        size="xs"
                      >
                        {intl.formatMessage({ id: item.status })}
                      </Badge>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <small style={{ fontWeight: 500 }}>
                      {intl.formatMessage({ id: "issued_by" })}
                    </small>
                    <IssuerPart data={issued_by} />
                  </div>
                  {type != "new" && (
                    <div style={{ textAlign: "right" }}>
                      <small style={{ fontWeight: 500 }}>
                        {intl.formatMessage({ id: "issued_for" })}
                      </small>
                      <IssuerPart data={issued_for} />
                    </div>
                  )}
                </div>
                {item.tags &&
                  item.tags.map((tg) => (
                    <Badge
                      color="grape"
                      variant="outline"
                      size="xs"
                      style={{
                        marginRight: 3,
                        paddingLeft: 2,
                      }}
                    >
                      <Tag
                        size={12}
                        color="purple"
                        style={{ marginRight: 3, marginBottom: -3 }}
                      />
                      {tg.tag_name}
                    </Badge>
                  ))}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <small
                    title={intl.formatMessage({ id: "total_due" })}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CoinEuro
                      size={14}
                      color="green"
                      style={{ marginRight: 3 }}
                    />
                    {(
                      item.total_vat *
                        (item.discount ? 1 - item.discount / 100 : 1) -
                      (item.paid_amount ? item.paid_amount : 0)
                    ).toFixed(2)}
                    {item.paid_amount &&
                      ` / (${(
                        item.total_vat *
                        (item.discount ? 1 - item.discount / 100 : 1)
                      ).toFixed(2)})`}{" "}
                    €
                    {item.discount && (
                      <small
                        style={{ marginLeft: 10 }}
                        title={`${intl.formatMessage({ id: "discount" })} %`}
                      >
                        <Badge size="sm" variant="outline" color="lime">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            - {item.discount}%
                          </div>
                        </Badge>
                      </small>
                    )}
                  </small>
                </div>
              </a>
              <div
                style={{
                  marginTop: -38,
                  padding: 15,
                  zIndex: 300,
                  marginRight: -10,
                  float: "right",
                  display: "flex",
                }}
              >
                <Checkbox
                  checked={selected.includes(item.invoice_id)}
                  onChange={() => handleCheckboxChange(item.invoice_id)}
                />
              </div>
            </div>
          );
        })}
      {sortedData.length == 0 && (
        <Title order={4}>
          {intl.formatMessage({ id: "invoices_not_found" })}
        </Title>
      )}
      <div style={{ borderTop: "1px solid lightgrey" }}></div>
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ display: "flex", marginTop: 20, justifyContent: "center" }}
        />
      )}
    </div>
  );
};
