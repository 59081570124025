import {
  Accordion,
  Alert,
  Button,
  Card,
  Center,
  Grid,
  LoadingOverlay,
  Modal,
  Table,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  createTemplatedInvoiceApi,
  getTemplateByUUID,
} from "../../functions/api/templateApi";
import { useEffect, useState } from "react";
import { getFriendCredentialsById } from "../../functions/api/userApi";
import DataTable from "../../Components/DataTable";
import { getColumnsConfig, getLabelForValue } from "./fn";
import {
  LogoContainer,
  TotalsHolder,
} from "../CreateInvoiceScreen/CreateComponents";
import {
  RenderInvoiceSubjectFields,
  dataMapping,
  useAmountDue,
  useIndividualSubTotalNoVat,
  useSubtotal,
} from "../CreateInvoiceScreen/fn";
import UserCard from "../../Components/UserCard";
import { EditInvoiceSubjectModal } from "../../Components/Modals/EditInvoiceSubjectModal";
import { replacePlaceholders } from "../../Components/Modals/EditInvoiceNrModal";
import TextEditor from "../../Components/TextEditor";
import { ServiceList } from "../../Components/Lists";
import { IconAlertCircle } from "@tabler/icons-react";

// TODO - save for this OR for all future
export const TemplateCreateInvoice = () => {
  const intl = useIntl();
  const param = useParams();

  const [template, setTemplate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [issuedBy, setIssuedBy] = useState([]);
  const [byUser, setByUser] = useState([]);
  const [services, setServices] = useState([]);
  const [emailContent, setEmailContent] = useState("");
  const [issuedFor, setIssuedFor] = useState({});
  const [modalData, setModalData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [temp, setTemp] = useState(null);
  const [logo, setLogo] = useState(null);

  const openEditModal = (type) => {
    setEditModal(true);
    setEditData({ type });
    if (type == "email_content") {
      setTemp(emailContent);
    }
    if (type == "public_note") {
      setTemp(template.public_note);
    }
    if (type == "services") {
      setTemp(JSON.parse(template.services_json));
    }
    if (type == "template_mail_subject") {
      setTemp(template.template_mail_subject);
    }
  };

  const closeEditModal = () => {
    setTemp(null);
    setEditModal(false);
    setEditData([]);
  };

  const saveAndClose = (type) => {
    if (type == "email_content") {
      setEmailContent(temp);
    }
    if (type == "public_note") {
      setTemplate({ ...template, public_note: temp });
    }
    if (type == "services") {
      setServices(temp);
      setTemplate((prevState) => ({
        ...prevState,
        services_json: JSON.stringify(temp),
      }));
    }
    if (type == "template_mail_subject") {
      setTemplate({ ...template, template_mail_subject: temp });
    }

    closeEditModal();
  };

  const fetchInvoice = () => {
    setLoading(true);
    getTemplateByUUID({ tuuid: param.id }).then((x) => {
      if (x.length > 0) {
        setTemplate(x[0]);
        setIssuedBy(JSON.parse(x[0].issuedby_json));
        setServices(JSON.parse(x[0].services_json));
        if (x[0].by_user_id && x[0].by_user_id > 0) {
          getFriendCredentialsById({ id: x[0].by_user_id }).then((y) => {
            setByUser(y[0]);
          });
        }
        setTemplate((prevState) => ({
          ...prevState,
          issuedby_json: JSON.parse(x[0].issuedby_json),
        }));

        if (x[0].mail_text) {
          setEmailContent(x[0].mail_text);
        }
        setLogo(x[0].logo_url);
        setLoading(false);
      } else {
        window.location.href = "/template-invoices";
      }
    });
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  const handleDataSave = () => {
    const updatedData = modalData?.data;
    if (modalData?.type === "for") {
      setIssuedFor(updatedData);
      setTemplate({ ...template, issuedfor_json: updatedData });
    } else {
      setIssuedBy(updatedData);
      setTemplate({ ...template, issuedby_json: updatedData });
    }
    setModalData(null);
    setModalOpen(false);
  };

  const individualSubTotalNoVat = useIndividualSubTotalNoVat(services);
  const subtotal = useSubtotal(services, template);
  const amountDue = useAmountDue(subtotal, template);

  const setUpModal = (data) => {
    setModalOpen(true);
    const mappedData = dataMapping(data);
    setModalData({ type: data.type, data: mappedData });
  };

  const createTemplatedInvoice = (send) => {
    createTemplatedInvoiceApi({
      template,
      emailContent,
      send,
      invoice_name: replacePlaceholders(
        template.invoice_name,
        template.invoice_index
      ),
    }).then((x) => (window.location.href = `/invoice/${x.eId}`));
  };

  const ByUserAccControl = () => {
    const creds = issuedBy;

    return (
      <Accordion.Control>
        {intl.formatMessage({ id: "issued_by" })}:{" "}
        {!creds.priv
          ? `${creds.name} ${creds.surname}`
          : `${creds.bussiness_name}`}
      </Accordion.Control>
    );
  };

  return (
    <div
      style={{
        marginTop: 20,
        position: "relative",
        height: loading ? 900 : "auto",
      }}
    >
      <LoadingOverlay
        visible={loading}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{ color: "pink", type: "bars" }}
      />

      <Modal
        opened={editModal}
        onClose={closeEditModal}
        title={
          editData.type &&
          intl.formatMessage({ id: `edit_modal_${editData.type}` })
        }
      >
        {editData.type == "email_content" && (
          <>
            <TextEditor value={temp} onChange={(x) => setTemp(x)} />
          </>
        )}

        {editData.type == "public_note" && (
          <>
            <Textarea value={temp} onChange={(x) => setTemp(x.target.value)} />
          </>
        )}

        {editData.type == "template_mail_subject" && (
          <TextInput value={temp} onChange={(x) => setTemp(x.target.value)} />
        )}

        {editData.type == "services" && (
          <ServiceList
            services={temp}
            setServices={(x) => setTemp(x)}
            individualVat={template.individualVat}
          />
        )}
        <Button
          style={{ marginTop: 10 }}
          onClick={() => saveAndClose(editData.type)}
        >
          {intl.formatMessage({ id: "save" })}
        </Button>
      </Modal>
      <EditInvoiceSubjectModal
        modalData={modalData}
        modalOpen={modalOpen}
        handleDataSave={handleDataSave}
        setModalData={setModalData}
        setModalOpen={setModalOpen}
      />
      <Title order={4}>{template.template_name}</Title>
      <small>{template.template_description}</small>
      <hr />
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div>
              <strong>
                {intl.formatMessage({ id: "invoice_deadline_setting" })}:
              </strong>
            </div>
            {getLabelForValue(intl, template.template_deadline)}
            <div>
              <strong>{intl.formatMessage({ id: "invoice_name" })}:</strong>
            </div>
            {replacePlaceholders(template.invoice_type, template.invoice_index)}
          </Grid.Col>
          <RenderInvoiceSubjectFields
            type="for"
            obj={issuedFor}
            setUpModal={setUpModal}
          />
        </Grid>
      </div>
      <Alert
        color="red"
        style={{ marginBottom: 10 }}
        icon={<IconAlertCircle />}
      >
        {intl.formatMessage({ id: "changes_only_temp" })}
      </Alert>
      <Accordion chevronPosition="left" defaultValue="" variant="contained">
        <Accordion.Item value="issued_by">
          <Center>
            <ByUserAccControl />
          </Center>
          <Accordion.Panel>
            {byUser && byUser.userid && (
              <UserCard
                data={byUser}
                config={{
                  padding: 8,
                  showApprovalStatus: false,
                  showCredentials: false,
                  showButtons: false,
                  showPointer: false,
                  showIssueButton: true,
                }}
              />
            )}
            <Table withTableBorder verticalSpacing={1}>
              <Table.Tbody>
                {Object.entries(issuedBy).map(([key, value]) => (
                  <Table.Tr>
                    <Table.Td>
                      <strong>{intl.formatMessage({ id: key })}</strong>
                    </Table.Td>
                    <Table.Td>{value}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="logo">
          <Center>
            <Accordion.Control>
              {intl.formatMessage({ id: "logo" })}
            </Accordion.Control>
          </Center>
          <Accordion.Panel>
            <LogoContainer logo={logo} setLogo={setLogo} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="services">
          <Center>
            <Accordion.Control>
              {intl.formatMessage({ id: "services" })}
            </Accordion.Control>
            <Button
              size="xs"
              style={{ float: "right", marginRight: 10, width: 100 }}
              onClick={() => openEditModal("services")}
            >
              {intl.formatMessage({ id: "edit" })}
            </Button>
          </Center>
          <Accordion.Panel>
            {services && (
              <DataTable data={services} columns={getColumnsConfig(template)} />
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="total_due">
          <Center>
            <Accordion.Control>
              {intl.formatMessage({ id: "total_due" })}:{" "}
              {template.discount && template.discount != 0
                ? (Number(amountDue) * (1 - template.discount / 100)).toFixed(2)
                : Number(amountDue).toFixed(2)}
              €
            </Accordion.Control>
            {/* <Button
              size="xs"
              style={{ float: "right", marginRight: 10, width: 100 }}
            >
              {intl.formatMessage({ id: "edit" })}
            </Button> */}
          </Center>
          <Accordion.Panel>
            <TotalsHolder
              individualVat={template.vat_individual}
              individualSubTotalNoVat={individualSubTotalNoVat}
              subtotal={subtotal}
              taxRate={template.vat_perc}
              discount={Number(template.discount)}
              amountDue={amountDue}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="email_subject">
          <Center>
            <Accordion.Control>
              {intl.formatMessage({ id: "email_subject" })}
            </Accordion.Control>
            <Button
              size="xs"
              style={{ float: "right", marginRight: 10, width: 100 }}
              onClick={() => openEditModal("template_mail_subject")}
            >
              {intl.formatMessage({ id: "edit" })}
            </Button>
          </Center>
          <Accordion.Panel>
            <Card withBorder>{template.template_mail_subject}</Card>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="email_content">
          <Center>
            <Accordion.Control>
              {intl.formatMessage({ id: "email_content" })}
            </Accordion.Control>
            <Button
              size="xs"
              style={{ float: "right", marginRight: 10, width: 100 }}
              onClick={() => openEditModal("email_content")}
            >
              {intl.formatMessage({ id: "edit" })}
            </Button>
          </Center>
          <Accordion.Panel>
            <Card withBorder>
              <div dangerouslySetInnerHTML={{ __html: emailContent }}></div>
            </Card>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="public_notes">
          <Center>
            <Accordion.Control>
              {intl.formatMessage({ id: "public_notes" })}
            </Accordion.Control>
            <Button
              size="xs"
              style={{ float: "right", marginRight: 10, width: 100 }}
              onClick={() => openEditModal("public_note")}
            >
              {intl.formatMessage({ id: "edit" })}
            </Button>
          </Center>
          <Accordion.Panel>
            <Card withBorder>{template.public_note}</Card>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Grid style={{ marginTop: 20 }}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Button
            fullWidth
            color="green"
            onClick={() => createTemplatedInvoice(false)}
          >
            {intl.formatMessage({ id: "create_invoice" })}
          </Button>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Button fullWidth onClick={() => createTemplatedInvoice(true)}>
            {intl.formatMessage({ id: "create_invoice_and_send" })}
          </Button>
        </Grid.Col>
      </Grid>
    </div>
  );
};
