import {
  Button,
  Card,
  Checkbox,
  FileButton,
  Grid,
  Loader,
  Modal,
  Select,
  Switch,
  TextInput,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { searchFriendCredentials } from "../../functions/api/userApi";
import UserCard from "../UserCard";
import { Stars } from "tabler-icons-react";
import { useDisclosure } from "@mantine/hooks";
import {
  credsFromPictureAI,
  processCredentialsWithAI,
  saveCredsForReuse,
} from "../../functions/api/credentialsApi";

export const issuedFields = [
  { name: "friend", priv: false, bus: false },
  {
    label: "jur_per",
    name: "priv",
    type: "switch",
    priv: true,
    bus: true,
    mob: 12,
    desk: 12,
  },
  { name: "email", type: "text", priv: true, bus: true, mob: 12, desk: 6 },
  { name: "name", type: "text", priv: true, bus: false, mob: 12, desk: 6 },
  { name: "surname", type: "text", priv: true, bus: false, mob: 12, desk: 6 },
  {
    name: "bussiness_name",
    type: "text",
    priv: false,
    bus: true,
    mob: 12,
    desk: 6,
  },
  { name: "per_id", type: "text", priv: true, bus: false, mob: 12, desk: 6 },
  { name: "reg_id", type: "text", priv: false, bus: true, mob: 12, desk: 6 },
  { name: "vat_code", type: "text", priv: true, bus: true, mob: 12, desk: 6 },
  { name: "jur_addr", type: "text", priv: true, bus: true, mob: 12, desk: 12 },
  { name: "phy_addr", type: "text", priv: true, bus: true, mob: 12, desk: 12 },
  { name: "bank", type: "text", priv: true, bus: true, mob: 12, desk: 6 },
  { name: "bank_acc", type: "text", priv: true, bus: true, mob: 12, desk: 12 },
];

export const EditInvoiceSubjectModal = ({
  modalData,
  modalOpen,
  handleDataSave,
  setModalData,
  setModalOpen,
}) => {
  const intl = useIntl();
  const theme = useMantineTheme();

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [friend, setFriend] = useState(null);
  const [checked, setChecked] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);

  const [process, setProcess] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const [save, setSave] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (modalData && modalData?.data) {
      if (modalData?.data?.friend) {
        setFriend(modalData?.data.friend);
        setSearchValue(modalData?.data.friend.label);
      }
      if (modalData.data.save) {
        setSave(true);
      }
    }
  }, [modalOpen]);

  const handleSaveProxy = () => {
    if (!friend && save) {
      saveCredsForReuse({ creds: modalData }).then();
    }
    setSearchValue("");
    setSearchResults([]);
    setFriend(null);
    setSave(false);
    handleDataSave();
  };

  useEffect(() => {
    if (!searchValue.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    if (!friend && searchValue != "") {
      searchFriendCredentials({ search: searchValue })
        .then((data) => {
          setSearchResults(data);
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (friend && modalData?.data?.friend) {
      const isChecked = JSON.parse(modalData.data?.friend.credentials);
      setModalData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          priv: isChecked?.priv,
        },
      }));
    }
  }, [friend]);

  const processEnteredCredentials = async () => {
    setLoading(true);
    const data = await processCredentialsWithAI({ creds: process });
    finishCredSetUp(data);
  };

  const finishCredSetUp = (data) => {
    handleFriendDeselect();
    const updatedData = {
      ...modalData.data,
      friend: null,
      name: data.name,
      surname: data.surname,
      email: data.email,
      per_id: data.per_id,
      bussiness_name: data.business_name,
      reg_id: data.reg_id,
      jur_addr: data.jur_addr,
      phy_addr: data.phy_addr,
      vat_code: data.vat_code,
      bank: data.bank,
      bank_acc: data.bank_acc,
      priv: data.priv,
    };

    setModalData((prevState) => ({
      ...prevState,
      data: sortModalData(updatedData),
    }));

    toggle();
    setLoading(false);
  };

  const renderInputs = (fields, disabled) => {
    return (
      <Grid>
        {fields.map((field, index) => {
          if (field.type == "switch") {
            return (
              <Grid.Col
                span={{ base: field.mob, md: field.desk }}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Switch
                  key={index}
                  disabled={disabled}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  label={intl.formatMessage({
                    id: field.label ? field.label : field.name,
                  })}
                  checked={checked ? checked : modalData.data?.[field.name]}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: field.name,
                        value: e.currentTarget.checked,
                      },
                    })
                  }
                  thumbIcon={
                    modalData.data?.[field.name] ? (
                      <IconCheck
                        style={{ width: 12, height: 12 }}
                        color={theme.colors.teal[6]}
                        stroke={3}
                      />
                    ) : (
                      <IconX
                        style={{ width: 12, height: 12 }}
                        color={theme.colors.red[6]}
                        stroke={3}
                      />
                    )
                  }
                />
                <Button onClick={toggle}>
                  AI
                  <Stars
                    style={{ rotate: "50deg", marginBottom: 3, marginLeft: 3 }}
                    fill="yellow"
                    color="orange"
                    size={14}
                    strokeWidth={1}
                  />
                </Button>
              </Grid.Col>
            );
          }
          if (field.type == "text") {
            if (
              (field.bus == true && modalData.data?.priv == true) ||
              (field?.priv == true && modalData.data?.priv != true)
            )
              return (
                (field.name != "email" ||
                  (field.name == "email" && modalData?.hideEmail != true)) && (
                  <Grid.Col span={{ base: field.mob, md: field.desk }}>
                    <TextInput
                      disabled={disabled}
                      key={index}
                      placeholder={intl.formatMessage({ id: field.name })}
                      label={intl.formatMessage({ id: field.name })}
                      name={field.name}
                      value={modalData.data?.[field.name]}
                      onChange={handleChange}
                    />
                  </Grid.Col>
                )
              );
          }
          if (field.type == "select") {
            return (
              <Select
                disabled={disabled}
                key={index}
                placeholder={intl.formatMessage({ id: field.name })}
                label={intl.formatMessage({ id: field.name })}
                name={field.name}
                value={modalData.data?.[field.name]}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: field.name,
                      value: e,
                    },
                  })
                }
                data={field.data}
              />
            );
          }
        })}
      </Grid>
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setModalData((prevState) => ({
      ...prevState,
      data: { ...prevState.data, [name]: value },
    }));
  };

  const closeModal = () => {
    setModalOpen(false);
    setSearchValue("");
    setSearchResults([]);
    setModalData(null);
    setFriend(null);
  };

  const sortModalData = (data) => {
    const sortedData = {};

    issuedFields.forEach((field) => {
      if (data.hasOwnProperty(field.name)) {
        sortedData[field.name] = data[field.name];
      }
    });

    return sortedData;
  };

  const handleFriendSelect = (option) => {
    const creds = option.credentials ? JSON.parse(option.credentials) : [];

    const updatedData = {
      ...modalData.data,
      friend: option.status != "saved" ? option : null,
      name: creds.name,
      surname: creds.surname,
      email: option.email ? option.email : creds.email,
      per_id: creds.per_id,
      bussiness_name: creds.bussiness_name,
      reg_id: creds.reg_id,
      jur_addr: creds.jur_addr,
      phy_addr: creds.phy_addr,
      vat_code: creds.vat_code,
      bank: creds.bank,
      bank_acc: creds.bank_acc,
      priv: creds.priv,
    };

    setModalData((prevState) => ({
      ...prevState,
      data: sortModalData(updatedData),
    }));

    if (option.status != "saved") {
      setFriend(option);
      setSearchValue(option.label);
    }

    if (option.status == "saved") {
      setSearchValue("");
    }

    setSearchResults([]);
    setLoading(false);
  };

  const handleFriendDeselect = () => {
    // should I remove everything about him?
    setFriend(null);
    setSearchValue("");
    setSearchResults([]);
    setLoading(false);
    setModalData((prevState) => ({
      ...prevState,
      data: {},
    }));
  };

  const renderUserSearch = (option) => {
    return (
      <UserCard
        data={option}
        config={{
          padding: 8,
          showApprovalStatus: false,
          showCredentials: true,
          showButtons: false,
          showPointer: true,
          showCredentialsData: true,
          onClick: () => handleFriendSelect(option),
        }}
      />
    );
  };

  const processPicture = async (file) => {
    setLoading(true);
    const data = await credsFromPictureAI(file);
    finishCredSetUp(data);
  };

  const handleSaveReuse = (checked) => {
    setSave(checked);
    handleChange({
      target: {
        name: "save",
        value: checked,
      },
    });
  };

  return (
    <Modal
      size="lg"
      fullScreen={isMobile}
      title={
        modalData &&
        modalData?.type &&
        intl.formatMessage({ id: `modify_${modalData.type}` })
      }
      onClose={closeModal}
      opened={modalOpen}
    >
      <Modal
        centered
        opened={opened}
        onClose={toggle}
        title={intl.formatMessage({ id: "enter_ai_credentials" })}
      >
        <Textarea
          rows={7}
          onChange={(e) => setProcess(e.target.value)}
          value={process}
          style={{ marginBottom: 20 }}
          placeholder={intl.formatMessage({ id: "enter_credentials" })}
        ></Textarea>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Button
              fullWidth
              onClick={processEnteredCredentials}
              loading={loading}
            >
              {intl.formatMessage({ id: "process_credentials" })}
            </Button>
            <div style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "or" })}
            </div>
            <FileButton onChange={processPicture} accept="image/png,image/jpeg">
              {(props) => (
                <Button fullWidth {...props}>
                  {intl.formatMessage({ id: "upload_image_for_ai" })}
                </Button>
              )}
            </FileButton>
          </>
        )}
      </Modal>

      {modalData?.type && (
        <div style={{ marginBottom: isMobile ? 100 : 20 }}>
          {!modalData?.searchOff && (
            <div style={{ position: "relative", marginBottom: 10 }}>
              <TextInput
                label={intl.formatMessage({ id: "search_for_contact" })}
                placeholder={intl.formatMessage({ id: "search_for_contact" })}
                value={searchValue}
                readOnly={friend ? true : false}
                onChange={(e) => setSearchValue(e.target.value)}
                rightSection={
                  loading ? (
                    <Loader size="xs" />
                  ) : (
                    searchValue && (
                      <IconX
                        style={{ cursor: "pointer" }}
                        onClick={handleFriendDeselect}
                      />
                    )
                  )
                }
              />
              <div style={{ position: "absolute", width: "100%", zIndex: 5 }}>
                {searchResults?.length > 0 && (
                  <Card p="0" shadow="md">
                    {searchResults.map((user, index) => renderUserSearch(user))}
                  </Card>
                )}
              </div>
            </div>
          )}
          {renderInputs(
            issuedFields,
            friend ? (friend.credentials ? true : false) : false
          )}
          {!friend && (
            <div style={{ marginTop: 10 }}>
              <Checkbox
                checked={save}
                onChange={(event) =>
                  handleSaveReuse(event.currentTarget.checked)
                }
                label={intl.formatMessage({ id: "save_credentials_for_reuse" })}
              />
            </div>
          )}
          <Button fullWidth style={{ marginTop: 25 }} onClick={handleSaveProxy}>
            {intl.formatMessage({ id: "save" })}
          </Button>
        </div>
      )}
    </Modal>
  );
};
