import { handleResponse, unAuthBody, url } from '.';

const a = 'auth/';

export const handleLogin = async (email, password, rememberMe) => {
  const response = await fetch(
    url + `${a}login`,
    unAuthBody({ email, password, rememberMe })
  );
  return response;
};

export const checkResetToken = async (data) =>
  fetch(url + `${a}reset-password-token`, unAuthBody(data)).then((response) =>
    handleResponse(response)
  );

export const getPasswordReset = (data) =>
  fetch(url + `${a}rest-password-token`, unAuthBody(data)).then((response) =>
    handleResponse(response)
  );

export const setPasswordResetToken = (data) =>
  fetch(url + `${a}forgot-password`, unAuthBody({ email: data })).then(
    (response) => handleResponse(response)
  );

export const setNewPassword = (data) =>
  fetch(url + `${a}change-pass`, unAuthBody(data)).then((response) =>
    handleResponse(response)
  );

export const isEmailInUse = (data) =>
  fetch(url + `${a}check-if-email-used`, unAuthBody(data)).then((response) =>
    handleResponse(response)
  );

export const registerUser = (data) =>
  fetch(url + `${a}register-user`, unAuthBody(data)).then((response) =>
    handleResponse(response)
  );
