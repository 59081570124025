import { apiFetch, handleResponse, url } from ".";
import { getBusinessID, getToken } from "../tokens";

const c = "credentials/";
export const getPersonalCredentials = () =>
  apiFetch(`${c}get-personal-credentials`);
export const createPersonalCredentials = (data) =>
  apiFetch(`${c}create-personal-credentials`, data);
export const updatePersonalCredentials = (data) =>
  apiFetch(`${c}update-personal-credentials`, data);
export const publishPersonalCredentials = (data) =>
  apiFetch(`${c}publish-personal-credentials`, data);
export const unpublishPersonalCredentials = (data) =>
  apiFetch(`${c}un-publish-personal-credentials`, data);
export const processCredentialsWithAI = (data) =>
  apiFetch(`${c}process-creds`, data);
export const getRecentCreds = (data) =>
  apiFetch(`${c}get-previous-creds`, data);
export const saveCredsForReuse = (data) =>
  apiFetch(`${c}save-credentials-for-reuse`, data);
export const getSavedCreds = (data) =>
  apiFetch(`${c}get-saved-credentials`, data);

export const modifySavedCreds = (data) =>
  apiFetch(`${c}modify-saved-credentials`, data);

export const dataFromInvoiceAI = async (file) => {
  const token = getToken();
  const formData = new FormData();
  const business_id = getBusinessID();
  formData.append("file", file);

  return fetch(url + "upgo-route/import-invoice-and-edit", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
      Business: `${business_id}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};

export const credsFromPictureAI = async (file) => {
  const token = getToken();
  const formData = new FormData();
  const business_id = getBusinessID();
  formData.append("file", file);

  return fetch(url + "upgo-route/process-creds-from-pic", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
      Business: `${business_id}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};
