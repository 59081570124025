import {
  Avatar,
  Badge,
  Group,
  Text,
  Select,
  Button,
  Modal,
  TextInput,
  Switch,
  useMantineTheme,
  Card,
  Loader,
  Title,
  Alert,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { issuedFields } from "../../Components/Modals/EditInvoiceSubjectModal";
import { IconCheck, IconInfoCircle, IconX } from "@tabler/icons-react";
import { useIntl } from "react-intl";
import {
  acceptFriendRequest,
  deleteFriendRequest,
  getFriends,
  searchUsersForFriends,
  sendFriendRequest,
} from "../../functions/api/userApi";
import UserCard from "../../Components/UserCard";
import { isValidEmail } from "../../functions/helpers";
import { sendUserAnInviteAPI } from "../../functions/api/emailApi";
import {
  getSavedCreds,
  modifySavedCreds,
} from "../../functions/api/credentialsApi";

function ContactScreen() {
  const intl = useIntl();

  const [modalData, setModalData] = useState({ data: null });
  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [requests, setRequests] = useState([]);
  const [creds, setCreds] = useState([]);

  const refreshFriendRequest = () => {
    getFriends().then((results) => {
      const r_requests = [];
      const r_friends = [];

      results.forEach((item) => {
        if (item.type === "request" && item.approved == 0) {
          r_requests.push(item);
        } else {
          r_friends.push(item);
        }
      });

      setContacts(r_friends);
      setRequests(r_requests);
    });
  };

  const sendUserAnInvite = () => {
    if (isValidEmail(searchValue)) {
      sendUserAnInviteAPI(searchValue).then(() => {
        window.alert(intl.formatMessage({ id: "invite_sent" }));
        setLoading(false);
        setSearchResults([]);
        setSearchValue("");
      });
    }
  };

  useEffect(() => {
    refreshFriendRequest();
    getSavedCreds().then((x) => setCreds(x));
  }, []);

  useEffect(() => {
    if (!searchValue.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    searchUsersForFriends({ search: searchValue })
      .then((data) => {
        setSearchResults(data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue]);

  const handleDataSave = () => {};

  const friendRequestForm = async (usr) => {
    if (
      window.confirm(
        `${intl.formatMessage({ id: "send_friend_request" })} ${usr?.label}?`
      )
    ) {
      await sendFriendRequest({ id: usr.userid });
      setSearchResults([]);
      setSearchValue("");
    }
  };

  const acceptFriendRequestFn = async (usr) => {
    if (
      window.confirm(
        `${intl.formatMessage({ id: "accept_friend_request" })} ${usr?.label}?`
      )
    ) {
      await acceptFriendRequest({ fid: usr.fid });
      refreshFriendRequest();
      setSearchResults([]);
      setSearchValue("");
    }
  };

  const deleteFriendRequestFn = async (usr) => {
    if (
      window.confirm(
        `${intl.formatMessage({ id: "delete_friend_request" })} ${usr?.label}?`
      )
    ) {
      await deleteFriendRequest({ fid: usr.fid });
      refreshFriendRequest();
      setSearchResults([]);
      setSearchValue("");
    }
  };

  const deleteCredential = async (data) => {
    if (
      window.confirm(
        `${intl.formatMessage({ id: "delete_credential" })} ${data?.label}?`
      )
    ) {
      modifySavedCreds({ method: "delete", credId: data?.value }).then(() =>
        getSavedCreds().then((x) => setCreds(x))
      );
    }
  };

  const renderUserSearch = (option) => {
    const showPointer =
      !option?.approved ||
      (option?.approved == 0 && option?.friend_data.friend_id != option.userid);
    return (
      <UserCard
        data={option}
        config={{
          padding: 8,
          showApprovalStatus: true,
          showCredentials: false,
          showButtons: false,
          showPointer: showPointer,
          showIssueButton: false,
          onClick: () => {
            if (!option?.approved) {
              friendRequestForm(option);
            } else if (
              option?.approved == 0 &&
              option?.friend_data.friend_id != option.userid
            ) {
              acceptFriendRequestFn(option);
            }
          },
        }}
      />
    );
  };

  return (
    <div>
      <Alert
        variant="light"
        color="green"
        radius="lg"
        icon={<IconInfoCircle />}
        style={{ marginBottom: 15 }}
      >
        {intl.formatMessage({ id: "contact_alert_info" })}
      </Alert>
      <div style={{ position: "relative", marginBottom: 10 }}>
        <TextInput
          label={intl.formatMessage({ id: "search_for_contact" })}
          placeholder={intl.formatMessage({ id: "search_for_contact" })}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          rightSection={
            loading ? (
              <Loader size="xs" />
            ) : (
              searchValue && (
                <IconX
                  style={{ cursor: "pointer" }}
                  onClick={() => setSearchValue("")}
                />
              )
            )
          }
        />
        <div style={{ position: "absolute", width: "100%", zIndex: 5 }}>
          {searchResults.length > 0 && (
            <Card p="0" shadow="md">
              {searchResults.map((user, index) => renderUserSearch(user))}
            </Card>
          )}
          {searchResults.length <= 4 && searchValue != "" && (
            <Card p="0" shadow="md">
              {isValidEmail(searchValue) ? (
                <Alert
                  style={{ cursor: "pointer", border: "0.5px solid lightgrey" }}
                  color="green"
                  onClick={sendUserAnInvite}
                  title={intl.formatMessage({ id: "add_contact_didnt_find" })}
                >
                  {intl.formatMessage(
                    { id: "add_contact_didnt_find_invite" },
                    { email: searchValue }
                  )}
                </Alert>
              ) : (
                <Alert
                  style={{ border: "0.5px solid lightgrey" }}
                  color="yellow"
                  title={intl.formatMessage({ id: "add_contact_didnt_find" })}
                >
                  {intl.formatMessage({ id: "add_contact_valid_email" })}
                </Alert>
              )}
            </Card>
          )}
        </div>
      </div>
      {/* <Button onClick={() => setModalOpen(true)}>Add contact</Button> */}
      <EditContactModal
        modalData={modalData}
        modalOpen={modalOpen}
        handleDataSave={handleDataSave}
        setModalData={setModalData}
        setModalOpen={setModalOpen}
      />
      <div style={{ marginTop: 10, padding: 10 }}>
        {requests.length > 0 && (
          <div>
            <Title order={4}>{intl.formatMessage({ id: "requests" })}</Title>
            {requests.map((data) => (
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <UserCard
                  data={data}
                  deleteFriendRequestFn={deleteFriendRequestFn}
                  acceptFriendRequestFn={acceptFriendRequestFn}
                  config={{
                    showApprovalStatus: false,
                    showCredentials: false,
                    showButtons: true,
                    showPointer: false,
                    showIssueButton: false,
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div style={{ marginTop: 10, padding: 10 }}>
        {contacts.length > 0 && (
          <div>
            <Title order={4}>{intl.formatMessage({ id: "in_friends" })}</Title>
            {contacts.map((data) => (
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <UserCard
                  data={data}
                  deleteFriendRequestFn={deleteFriendRequestFn}
                  acceptFriendRequestFn={acceptFriendRequestFn}
                  config={{
                    showApprovalStatus: false,
                    showCredentials: false,
                    showButtons: true,
                    showPointer: false,
                    showIssueButton: data.approved != 0,
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div style={{ marginTop: 10, padding: 10 }}>
        {contacts.length > 0 && (
          <div>
            <Title order={4}>
              {intl.formatMessage({ id: "saved_for_reuse" })}
            </Title>
            {creds.map((data) => (
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <UserCard
                  data={data}
                  deleteFriendRequestFn={() => deleteCredential(data)}
                  config={{
                    padding: 8,
                    showApprovalStatus: false,
                    showCredentials: true,
                    showButtons: false,
                    showPointer: true,
                    showButtons: true,
                    showPointer: false,
                    showCredentialsData: true,
                  }}
                />
                {/* <UserCard
                  data={data}
                  deleteFriendRequestFn={deleteFriendRequestFn}
                  acceptFriendRequestFn={acceptFriendRequestFn}
                  config={{
                    showApprovalStatus: false,
                    showCredentials: true,
                    showButtons: true,
                    showPointer: false,
                    showIssueButton: data.approved != 0,
                  }}
                /> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const EditContactModal = ({
  modalData,
  modalOpen,
  handleDataSave,
  setModalData,
  setModalOpen,
}) => {
  const intl = useIntl();
  const theme = useMantineTheme();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setModalData((prevState) => ({
      ...prevState,
      data: { ...prevState.data, [name]: value },
    }));
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalData(null);
  };

  const rFields = [
    ...[{ name: "contact_name", type: "text", priv: true, bus: true }],
    ...issuedFields,
  ];

  const renderInputs = (fields) => {
    return fields.map((field, index) => {
      if (field.type == "switch") {
        return (
          <>
            <Switch
              key={index}
              style={{ marginTop: 10, marginBottom: 10 }}
              label={intl.formatMessage({
                id: field.label ? field.label : field.name,
              })}
              checked={modalData.data?.[field.name]}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: field.name,
                    value: e.currentTarget.checked,
                  },
                })
              }
              thumbIcon={
                modalData.data?.[field.name] ? (
                  <IconCheck
                    style={{ width: 12, height: 12 }}
                    color={theme.colors.teal[6]}
                    stroke={3}
                  />
                ) : (
                  <IconX
                    style={{ width: 12, height: 12 }}
                    color={theme.colors.red[6]}
                    stroke={3}
                  />
                )
              }
            />
          </>
        );
      }
      if (field.type == "text") {
        if (
          (field.bus == true && modalData.data?.priv == true) ||
          (field.priv == true && modalData.data?.priv != true)
        )
          return (
            <TextInput
              key={index}
              placeholder={intl.formatMessage({ id: field.name })}
              label={intl.formatMessage({ id: field.name })}
              name={field.name}
              value={modalData.data?.[field.name]}
              onChange={handleChange}
            />
          );
      }
      if (field.type == "select") {
        return (
          <Select
            key={index}
            placeholder={intl.formatMessage({ id: field.name })}
            label={intl.formatMessage({ id: field.name })}
            name={field.name}
            value={modalData.data?.[field.name]}
            onChange={(e) =>
              handleChange({
                target: {
                  name: field.name,
                  value: e,
                },
              })
            }
            data={field.data}
          />
        );
      }
    });
  };
  return (
    <div>
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)}>
        {rFields && renderInputs(rFields)}
      </Modal>
    </div>
  );
};

export default ContactScreen;
