import { Title } from '@mantine/core';
import classes from './Logo.module.css';

export const Logo = () => {
  return (
    <Title order={2} className={classes.title} onClick={() => window.location = '/'} style={{cursor: "pointer"}}>
      invo<strong style={{ color: "red", fontSize: 30 }}>i</strong>ceria.com
    </Title>
  );
};
  