import {
  Card,
  Image,
  Text,
  Group,
  Badge,
} from "@mantine/core";
import classes from "./FeatureCard.module.css";
import { useIntl } from "react-intl";

export function FeatureCard({ data }) {
  const { image, title, description, status, tags } = data;
  const intl = useIntl();

  return (
    <Card withBorder radius="md" p="md" className={classes.card}>
      <Card.Section className="mantine-visible-from-md">
        <Image src={image} alt={intl.formatMessage({id: title})} height={180} />
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
        <Group justify="apart">
          <Text fz="lg" fw={500}>
           {intl.formatMessage({id: title})}
          </Text>
          <Badge size="sm" variant="light">
            {intl.formatMessage({id: status})}
          </Badge>
        </Group>
        <Text fz="sm" mt="xs">
          {intl.formatMessage({id: description})}
        </Text>
      </Card.Section>
    </Card>
  );
}
