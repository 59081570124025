import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkResetToken,
  setNewPassword,
  setPasswordResetToken,
} from "../../functions/api/authApi";
import { useParams, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { emailRegex } from "../../functions/helpers";
import { Button, Card, TextInput } from "@mantine/core";
import { Logo } from "../../Components/Logo/Logo";

const CenteredContainer = ({ children, title }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#f0f2f5",
      padding: "20px",
      backgroundImage:
        "url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?q=40&w=1040&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
  >
    <div style={{ maxWidth: "500px", width: "100%", textAlign: "center" }}>
      <div style={{ marginBottom: 20 }}>
        <Logo />
      </div>
      <Card withBorder shadow="md" style={{ borderRadius: 10 }}>
        <h2 style={{ marginTop: 5 }}>{title}</h2>
        {children}
      </Card>
    </div>
  </div>
);

export const ForgotPassScreen = () => {
  const intl = useIntl();
  const [email, setEmail] = useState();
  const [sent, setSent] = useState(false);

  const submitForgotPassword = (event) => {
    event.preventDefault();
    if (emailRegex.test(email)) {
      setPasswordResetToken(email);
      setSent(true);
    } else {
      window.alert(intl.formatMessage({ id: "invalid_email" }));
    }
  };

  return (
    <CenteredContainer title={<FormattedMessage id="reset_password_text" />}>
      {!sent ? (
        <form onSubmit={submitForgotPassword} className="space-y-2">
          <TextInput
            id="email-address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={intl.formatMessage({ id: "enter_email" })}
            autoComplete="email"
            required
          />
          <Button style={{ marginTop: 20 }} type="submit">
            <FormattedMessage id="reset_password_button" />
          </Button>
        </form>
      ) : (
        <Button
          style={{ marginTop: 20 }}
          onClick={() => (window.location.href = "/login")}
        >
          <FormattedMessage id="reset_password_done" />
        </Button>
      )}
    </CenteredContainer>
  );
};

export function PassResetScreen() {
  const intl = useIntl();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [repeatP, setRepeatP] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== repeatP) {
      window.alert(intl.formatMessage({ id: "pw_dont_match" }));
      return;
    }
    try {
      const response = await setNewPassword({ token, pass: password });
      if (response.message === "win") {
        navigate("/");
      } else {
        setError(intl.formatMessage({ id: "pwreset_invalid_token" }));
      }
    } catch (error) {
      console.error(error);
      setError(intl.formatMessage({ id: "pwreset_something_wrong" }));
    }
  };

  useEffect(() => {
    checkResetToken({ token }).then((data) => {
      setIsLoading(false);
      if (data.error) {
        setError(data.error);
      }
    });
  }, [token]);

  return (
    <>
      {!isLoading && (
        <CenteredContainer
          title={
            error ? (
              <FormattedMessage id="pwreset_error" />
            ) : (
              <FormattedMessage id="reset_password_text" />
            )
          }
        >
          {error ? (
            <div
              style={{
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "#ffffff40",
              }}
            >
              <p className="text-center">
                <FormattedMessage id="link_maybe_expired" />
              </p>
              <div className="mt-6 flex justify-center">
                <Link
                  to="/login"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  <FormattedMessage id="return_to_login" />
                </Link>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-2">
              {password && repeatP && password !== repeatP && (
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    marginBottom: 10,
                  }}
                >
                  {intl.formatMessage({ id: "pw_dont_match" })}
                </div>
              )}
              <TextInput
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={intl.formatMessage({ id: "new_password" })}
                autoComplete="current-password"
                required
                style={{ marginBottom: 10 }}
              />
              <TextInput
                id="repeat-password"
                type="password"
                value={repeatP}
                onChange={(e) => setRepeatP(e.target.value)}
                placeholder={intl.formatMessage({ id: "repeat_password" })}
                required
                style={{ marginBottom: 10 }}
              />
              <Button type="submit">
                <FormattedMessage id="reset_password_button" />
              </Button>
            </form>
          )}
        </CenteredContainer>
      )}
    </>
  );
}
