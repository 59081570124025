import React, { useEffect, useState } from "react";
import { Table, ActionIcon, Input } from "@mantine/core";
import { Eye, Search } from "tabler-icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { PaginationItem } from "../Pagination";

const DataTable = ({
  data,
  columns,
  itemsPerPage = 40,
  actionsRow = false,
  condition,
}) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnKey) => {
    if (sortColumn === columnKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const columnValue = (client, key) => {
    const column = columns.find((col) => col.key === key);
    return column && column.render(client);
  };

  const filteredData = data?.filter((client) =>
    columns.some((column) =>
      column.searchable
        ? columnValue(client, column.key)
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase())
        : false
    )
  );

  const sortedData = filteredData?.sort((a, b) => {
    const columnA = sortColumn && columnValue(a, sortColumn);
    const columnB = sortColumn && columnValue(b, sortColumn);

    if (columnA === columnB) {
      return 0;
    } else if (sortDirection === "asc") {
      return columnA < columnB ? -1 : 1;
    } else {
      return columnA > columnB ? -1 : 1;
    }
  });

  const totalPages = Math.ceil(sortedData?.length / itemsPerPage);
  const startIndex = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = sortedData?.slice(startIndex, endIndex);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  let i = 0;
  return (
    <div>
      <Input
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
        icon={<Search />}
        placeholder={intl.formatMessage({ id: "search" })}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ marginBottom: "20px", justifyContent: "center" }}
        />
      )}
      <div style={{ overflowX: "auto" }}>
        <Table
          stickyHeader
          stickyHeaderOffset={0}
          verticalSpacing="sm"
          striped
          highlightOnHover
          withColumnBorders
        >
          <Table.Thead>
            <Table.Tr>
              {columns.map(
                (column) =>
                  !column.hidden && (
                    <Table.Th
                      key={column.key}
                      onClick={() => {
                        if (column.sort == true) handleSort(column.key);
                      }}
                      style={{
                        cursor: column.sort == true ? "pointer" : "default",
                        textAlign: "left",
                        textWrap: "nowrap",
                      }}
                    >
                      {column.title && <FormattedMessage id={column.title} />}
                      {sortColumn === column.key && (
                        <span>{sortDirection === "asc" ? " 🔼" : " 🔽"}</span>
                      )}
                    </Table.Th>
                  )
              )}
              {actionsRow && <Table.Th></Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {displayedData.map((client) => (
              <Table.Tr key={client.clientid ? client.clientid : ++i}>
                {columns.map(
                  (column) =>
                    !column.hidden && (
                      <Table.Td
                        key={column.key}
                        style={{
                          fontSize: column.extra?.size == "xs" ? 10 : "unset",
                          color: column.extra?.color
                            ? column.extra?.color
                            : "default",
                          backgroundColor: column.extra?.bg
                            ? column.extra?.bg
                            : "default",
                        }}
                      >
                        {column.render(client)}
                      </Table.Td>
                    )
                )}
                {actionsRow && (
                  <Table.Td
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {/* Example action icon */}
                    <a target="_blank" href={`/partner/${client.clientid}`}>
                      <ActionIcon color="green" size="lg" variant="light">
                        <Eye />
                      </ActionIcon>
                    </a>
                  </Table.Td>
                )}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </div>
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ marginTop: "20px", justifyContent: "center" }}
        />
      )}
    </div>
  );
};

export default DataTable;
