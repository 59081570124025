import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  extendInvoice,
  getSingleInvoice,
  partialInvoice,
  voidInvoice,
  setInvoicePaid,
  issueInvoice,
  setInvoiceSeen,
  archivenvoice,
  modifyTags,
} from "../../functions/api/invoiceApi";
import {
  Alert,
  Badge,
  Button,
  Divider,
  Grid,
  Image,
  LoadingOverlay,
  Modal,
  NumberInput,
  Table,
  TextInput,
  Title,
  em,
} from "@mantine/core";
import { useIntl } from "react-intl";
import {
  IconArchive,
  IconArrowRight,
  IconCheck,
  IconLink,
  IconMail,
  IconRobot,
  IconStatusChange,
  IconX,
} from "@tabler/icons-react";
import DataTable from "../../Components/DataTable";
import { useMediaQuery } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import TextEditor from "../../Components/TextEditor";
import { getBusinessUserID, getUserId } from "../../functions/tokens";
import UserCard from "../../Components/UserCard";
import { sendInvoiceEmailApi } from "../../functions/api/emailApi";
import {
  AlertCircle,
  ClipboardCopy,
  LayoutGridAdd,
  Paperclip,
  Pencil,
  Tag,
} from "tabler-icons-react";
import DownloadPDFButton from "../../Components/DownloadPDFButton";
import { InvoiceTagInput } from "../CreateInvoiceScreen/CreateComponents";

const InvoiceScreen = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(`(max-width: ${em(1000)})`);

  const [invoice, setInvoice] = useState();
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState();
  const [data, setData] = useState();
  const [tags, setTags] = useState([]);

  const [tagModal, setTagModal] = useState(false);

  const [byUser, setByUser] = useState([]);
  const [forUser, setForUser] = useState([]);

  const [canEdit, setCanEdit] = useState(false);

  const [isseudBy, setIssuedBy] = useState();
  const [issuedFor, setIssuedFor] = useState();

  const [receiver, setReceiver] = useState("");
  const [cc, setCC] = useState("");
  const [bcc, setBCC] = useState("");
  const [subject, setSubject] = useState("");

  const [loading, setLoading] = useState(false);

  const initialContent = `<p>${intl.formatMessage({
    id: "invoice_attached",
  })}</p></br><p><a href="https://invoiceria.com">${intl.formatMessage({
    id: "sent_from_eazybill",
  })}</a></p>`;

  const [content, setContent] = useState(initialContent);

  const [error, setError] = useState("");

  const { id } = useParams();

  const openModal = (rsn) => {
    if (rsn == "send") {
      if (issuedFor?.email) {
        setReceiver(issuedFor?.email);
      }
    }
    setReason(rsn);
    setOpen(true);
  };

  const launchTheInvoiceToUser = () => {
    issueInvoice({ invoice_id: invoice.invoice_id }).then((x) =>
      retrieveInvoice()
    );
  };

  const handleChange = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const archiveConfirm = () => {
    if (
      window.confirm(
        intl.formatMessage({ id: "are_you_sure_you_want_to_archive" })
      )
    ) {
      archivenvoice({ invoice_id: invoice.invoice_id }).then(() =>
        completeAction()
      );
    }
  };

  const retrieveInvoice = () => {
    getSingleInvoice({ invoice_code: id }).then((data) => {
      setInvoice(data);
      setTags(data.tags);
      setServices(JSON.parse(data.services_json));
      setIssuedBy(JSON.parse(data.issuedby_json));
      setIssuedFor(JSON.parse(data.issuedfor_json));
      if (
        data.created_by !=
        (getBusinessUserID() ? getBusinessUserID() : getUserId())
      ) {
        setCanEdit(false);
      } else {
        setCanEdit(true);
      }
      if (data.for_user && data.for_user[0]) {
        setForUser(data.for_user[0]);
      }
      if (data.by_user && data.by_user[0]) {
        setByUser(data.by_user[0]);
      }
      if (data.status == "archive") {
        setCanEdit(false);
      }
    });
  };

  useEffect(() => {
    retrieveInvoice();
  }, []);

  useEffect(() => {
    if (invoice?.for_user_id == getUserId()) {
      setInvoiceSeen({ invoice_id: invoice.invoice_id });
    }
  }, [invoice]);

  const confirmVoidInvoice = () => {
    if (window.confirm(intl.formatMessage({ id: "void_invoice_confirm" }))) {
      voidInvoice({ invoice_id: invoice.invoice_id }).then(() =>
        completeAction()
      );
    }
  };

  const initAction = (action) => {
    const i_id = invoice.invoice_id;
    if (action == "void") {
      voidInvoice({ invoice_id: i_id }).then(() => completeAction());
    }
    if (action == "extend") {
      extendInvoice({ invoice_id: i_id, extendTo: data.extend }).then(() =>
        completeAction()
      );
    }
    if (action == "partial") {
      partialInvoice({ invoice_id: i_id, paid_amount: data.paid_amount }).then(
        () => completeAction()
      );
    }

    if (action == "set_paid") {
      setInvoicePaid({
        invoice_id: i_id,
        paid_on: data?.paid_on ? data?.paid_on : new Date(),
      }).then(() => completeAction());
    }
  };

  const completeAction = () => {
    setData([]);
    retrieveInvoice();
    setOpen(false);
  };

  if (!invoice && !invoice?.encrypted) {
    return (
      <div style={{ position: "relative", minHeight: 500 }}>
        <LoadingOverlay
          visible={true}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "pink", type: "bars" }}
        />
      </div>
    );
  }

  const columns = [
    {
      key: "name",
      title: "service",
      searchable: true,
      sort: true,
      render: (data) => data.name,
    },
    {
      key: "cost",
      title: "cost",
      searchable: false,
      sort: true,
      render: (data) => `${data.cost} €`,
    },
    {
      key: "quantity",
      title: "quantity",
      searchable: true,
      sort: true,
      render: (data) => data.quantity,
    },
    {
      key: "total",
      title: "total",
      searchable: true,
      sort: true,
      render: (data) => `${data.cost * data.quantity} €`,
    },
    invoice.vat_individual && {
      key: "vat",
      title: "vat",
      searchable: false,
      sort: true,
      render: (data) => (data.vat ? `${data.vat} %` : ""),
    },
  ].filter(Boolean);

  const sendInvoiceEmail = async () => {
    if (receiver.trim() == "") {
      return window.alert(intl.formatMessage({ id: "fill_receiver" }));
    }
    setLoading(true);

    getSingleInvoice({ invoice_code: invoice.encrypted }).then(async (dx) => {
      const response = await fetch(
        `https://generator-dta.invoiceria.com/?code=${invoice?.encrypted}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );

      if (!response.ok) {
        setLoading(false);
        throw new Error("Failed to fetch PDF");
      }

      const pdfBlob = await response.blob();

      const attepmt = await sendInvoiceEmailApi(
        {
          receiver,
          cc,
          bcc,
          subject,
          content,
          invoice_code: invoice.encrypted,
        },
        pdfBlob
      );
      setLoading(false);
      setOpen(false);
      setReason(null);
      setReceiver(null);
      setSubject(null);
      setContent(initialContent);
    });
  };

  const closeTagModal = (force) => {
    if (force) {
      setTagModal(false);
      setTags(invoice.tags);
    } else {
      modifyTags({
        tags: tags,
        oldTags: invoice.tags,
        invoice_id: invoice.invoice_id,
      }).then((x) => ({}));
      setTagModal(false);
      //Save logic
      //Refetch invoice? reload screen?
    }
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: "modify_tags" })}
        opened={tagModal}
        onClose={() => closeTagModal(true)}
      >
        <InvoiceTagInput tags={tags} setTags={setTags} />
        <Button
          fullWidth
          style={{ marginTop: 10 }}
          onClick={() => closeTagModal(false)}
        >
          {intl.formatMessage({ id: "save" })}
        </Button>
      </Modal>
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        title={
          reason && reason == "send"
            ? intl.formatMessage({ id: "send_modal" })
            : intl.formatMessage({ id: "edit_modal" })
        }
      >
        {reason == "send" ? (
          <div>
            <TextInput
              label={intl.formatMessage({ id: "send_to" })}
              placeholder="inbox@email.com"
              value={receiver}
              onChange={(x) => setReceiver(x.target.value)}
            />
            {/* <Accordion>
              <Accordion.Item
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                  borderBottom: "unset",
                }}
                key="additional"
                value="additional"
              >
                <Accordion.Control>
                  {intl.formatMessage({ id: "email_add_receivers" })}
                </Accordion.Control>
                <Accordion.Panel style={{ marginLeft: -15, marginRight: -15 }}>
                  <TextInput
                    label="CC"
                    placeholder="inbox@email.com; other@inbox.lv"
                    value={cc}
                    onChange={(x) => setCC(x.target.value)}
                  />
                  <TextInput
                    label="BCC"
                    placeholder="inbox@email.com; other@inbox.lv"
                    value={bcc}
                    onChange={(x) => setBCC(x.target.value)}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion> */}
            <TextInput
              label={intl.formatMessage({ id: "email_subject" })}
              placeholder=""
              value={subject}
              onChange={(x) => setSubject(x.target.value)}
            />
            <span
              style={{
                fontWeight: 500,
                marginTop: 7,
                marginBottom: 7,
                fontSize: 14,
              }}
            >
              {intl.formatMessage({ id: "email_content" })}
            </span>
            {/* <Textarea label={intl.formatMessage({ id: "email_content" })} /> */}
            <TextEditor value={content} onChange={(x) => setContent(x)} />
            <div style={{ marginTop: 10 }}>
              <Badge size="lg">
                <Paperclip
                  strokeWidth={1}
                  size={20}
                  style={{ marginBottom: -5, marginTop: -10 }}
                />
                <span style={{ marginLeft: 5 }}>
                  {intl.formatMessage({ id: "invoice_attached" })}
                </span>
              </Badge>
            </div>
            <Button
              fullWidth
              loading={loading}
              style={{ marginTop: 10 }}
              onClick={() => sendInvoiceEmail()}
            >
              {intl.formatMessage({ id: "send" })}
            </Button>
          </div>
        ) : (
          <div>
            <DateInput
              onChange={(data) => handleChange("extend", data)}
              name="extend"
              value={data?.extend}
              placeholder={intl.formatMessage({ id: "pick_a_date" })}
              label={intl.formatMessage({ id: "extend" })}
            />
            <Button
              style={{ marginTop: 20 }}
              fullWidth
              onClick={() => initAction("extend")}
            >
              {intl.formatMessage({ id: "extend" })}
            </Button>
            <Divider style={{ marginTop: 15, marginBottom: 10 }} />
            <DateInput
              onChange={(data) => handleChange("paid_on", data)}
              name="paid_on"
              value={data?.paid_on}
              placeholder={intl.formatMessage({ id: "pick_a_date" })}
              label={intl.formatMessage({ id: "set_paid_date" })}
            />
            <Button
              style={{ marginTop: 20 }}
              fullWidth
              color="green"
              onClick={() => initAction("set_paid")}
            >
              {intl.formatMessage({ id: "set_paid" })}
            </Button>
            <Divider style={{ marginTop: 15, marginBottom: 10 }} />
            <NumberInput
              onChange={(data) => handleChange("paid_amount", data)}
              name="paid_amount"
              value={data?.paid_amount}
              label={intl.formatMessage({ id: "paid_amount" })}
            />
            <Button
              style={{ marginTop: 20 }}
              fullWidth
              color="orange"
              onClick={() => initAction("partial")}
            >
              {intl.formatMessage({ id: "mark_as_partially_paid" })}
            </Button>
            <Divider style={{ marginTop: 15, marginBottom: 10 }} />

            <Button
              style={{ marginTop: 20 }}
              fullWidth
              color="red"
              onClick={() => initAction("void")}
            >
              {intl.formatMessage({ id: "set_voided" })}
            </Button>
          </div>
        )}
      </Modal>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={2} style={{ display: "flex", alignItems: "center" }}>
            {intl.formatMessage({ id: "invoice_nr" })} {invoice.invoice_name}{" "}
            {invoice.original_ref && (
              <Badge
                color="orange"
                variant="outline"
                title={intl.formatMessage({ id: "ai_read_invoice" })}
                style={{ marginLeft: 20 }}
                size="lg"
              >
                <IconRobot size={24} style={{ marginBottom: -8 }} />
              </Badge>
            )}
          </Title>
          {invoice.original_ref && (
            <div>
              <small>
                <a href={invoice.original_ref} target="_blank">
                  <IconLink size={15} style={{ marginBottom: -2 }} />
                  {intl.formatMessage({ id: "view_original_invoice" })}
                </a>
              </small>
            </div>
          )}
          <small style={{ color: "grey", fontSize: 10 }}>
            {invoice.encrypted}
          </small>
        </Grid.Col>
        <Grid.Col
          style={{ marginTop: 0, textAlign: isMobile ? "left" : "right" }}
          span={{ base: 12, md: 6 }}
        >
          <div>
            {invoice.extended_due ? (
              <strong>
                {intl.formatMessage({ id: "extended_due" })}:{" "}
                {new Date(invoice.extended_due).toLocaleDateString()}
              </strong>
            ) : (
              <small>
                {intl.formatMessage({ id: "due_date" })}:{" "}
                {new Date(invoice.due_date).toLocaleDateString()}
              </small>
            )}
            {/* {invoice.status == "draft" && (
              <div>
                <Button
                  size="xs"
                  onClick={() =>
                    (window.location.href = `/edit-invoice/${invoice.encrypted}`)
                  }
                >
                  {intl.formatMessage({ id: "edit_invoice" })}
                </Button>
              </div>
            )} */}
          </div>
          {invoice.paid_date && (
            <small>
              <strong>
                {intl.formatMessage({ id: "paid_on" })}:{" "}
                {new Date(invoice.paid_date).toLocaleDateString()}
              </strong>
            </small>
          )}
          {invoice.status == "void" && (
            <strong style={{ color: "red" }}>
              {intl.formatMessage({ id: "voided" })}!
            </strong>
          )}
          {invoice.status == "archive" && (
            <strong style={{ color: "red" }}>
              {intl.formatMessage({ id: "archive" })}!
            </strong>
          )}
        </Grid.Col>
      </Grid>
      {invoice.logo_url && (
        <Image
          // style={{ float: "right" }}
          h={75}
          w="auto"
          src={invoice.logo_url}
        />
      )}
      <Grid style={{ marginTop: 0 }}>
        <Grid.Col span={{ base: 12, md: 8 }}>
          {canEdit && (
            <div style={{ display: "flex" }}>
              <span
                onClick={() => setTagModal(true)}
                style={{
                  marginRight: 3,
                  marginTop: -3,
                  display: "flex",
                  border: "1px solid dodgerblue",
                  padding: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                  borderRadius: 10,
                  cursor: "pointer",
                  height: 20,
                }}
                title={intl.formatMessage({ id: "modify_tags" })}
              >
                <LayoutGridAdd size={14} />
                <small style={{ marginTop: -2, marginLeft: 2, fontSize: 12 }}>
                  {intl.formatMessage({ id: "modify_tags" })}
                </small>
              </span>
              {tags &&
                tags.map((tag, index) => (
                  <Badge key={index} size="xs" style={{ marginRight: 2 }}>
                    <Tag
                      size={10}
                      style={{ marginRight: 3, marginBottom: -2 }}
                    />
                    {tag.tag_name}
                  </Badge>
                ))}
            </div>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12 }}>
          <Grid>
            {canEdit && invoice.status == "draft" && (
              <Grid.Col span={{ base: 12, md: 4 }}>
                <Button
                  color="green"
                  fullWidth
                  leftSection={<Pencil size={14} />}
                  onClick={() =>
                    (window.location.href = `/edit-invoice/${invoice.encrypted}`)
                  }
                >
                  {intl.formatMessage({ id: "edit_invoice" })}
                </Button>
              </Grid.Col>
            )}
            {invoice.status == "draft" && canEdit && (
              <>
                <Grid.Col span={{ base: 12, md: 12 }}>
                  <small style={{ color: "dodgerblue", fontSize: 12 }}>
                    {intl.formatMessage({ id: "invoice_is_as_draft" })}
                  </small>
                  {invoice.for_user_id && (
                    <small style={{ fontSize: 12 }}>
                      <br />
                      {intl.formatMessage({ id: "user_wont_see_disclaimer" })}
                    </small>
                  )}
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                  <Button
                    fullWidth
                    variant="light"
                    color="green"
                    leftSection={<IconCheck size={14} />}
                    onClick={() => launchTheInvoiceToUser()}
                  >
                    {intl.formatMessage({ id: "set_issued" })}
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                  <Button
                    fullWidth
                    color="red"
                    variant="light"
                    leftSection={<IconX size={14} />}
                    onClick={() => confirmVoidInvoice()}
                  >
                    {intl.formatMessage({ id: "set_voided" })}
                  </Button>
                </Grid.Col>
              </>
            )}
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Button
                fullWidth
                variant="light"
                color="yellow"
                leftSection={<ClipboardCopy size={14} />}
                onClick={() =>
                  (window.location.href = `/create-invoice/${invoice.encrypted}`)
                }
              >
                {intl.formatMessage({ id: "create_from_invoice" })}
              </Button>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        {canEdit && (
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Button
              fullWidth
              variant="light"
              leftSection={<IconMail size={14} />}
              rightSection={<IconArrowRight size={14} />}
              onClick={() => openModal("send")}
            >
              {intl.formatMessage({ id: "send_modal" })}
            </Button>
          </Grid.Col>
        )}
        <Grid.Col span={{ base: 12, md: 4 }}>
          <DownloadPDFButton
            encryptedCode={invoice.encrypted}
            invoiceName={invoice.invoice_name}
            ai_ref={invoice.original_ref ? invoice.original_ref : false}
          />
        </Grid.Col>
        {canEdit &&
          !invoice.paid_date &&
          invoice.status != "void" &&
          invoice.status != "paid" && (
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Button
                fullWidth
                leftSection={<IconStatusChange size={14} />}
                variant="default"
                onClick={() => openModal("update")}
              >
                {intl.formatMessage({ id: "edit_modal" })}
              </Button>
            </Grid.Col>
          )}
        {canEdit && invoice.status == "void" && (
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Button
              fullWidth
              variant="light"
              color="red"
              leftSection={<IconArchive size={14} />}
              onClick={() => archiveConfirm()}
            >
              {intl.formatMessage({ id: "archive_invoice" })}
            </Button>
          </Grid.Col>
        )}
      </Grid>

      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={4}>{intl.formatMessage({ id: "issued_by" })}</Title>
          {byUser && byUser.userid && (
            <UserCard
              data={byUser}
              config={{
                padding: 8,
                showApprovalStatus: false,
                showCredentials: false,
                showButtons: false,
                showPointer: false,
                showIssueButton: true,
              }}
            />
          )}
          <Table withTableBorder verticalSpacing={1}>
            <Table.Tbody>
              {Object.entries(isseudBy).map(([key, value]) => (
                <Table.Tr>
                  <Table.Td>
                    <strong>{intl.formatMessage({ id: key })}</strong>
                  </Table.Td>
                  <Table.Td>{value}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={4}>{intl.formatMessage({ id: "issued_for" })}</Title>
          {forUser && forUser.userid && (
            <UserCard
              data={forUser}
              config={{
                padding: 8,
                showApprovalStatus: false,
                showCredentials: false,
                showButtons: false,
                showPointer: false,
                showIssueButton: true,
              }}
            />
          )}
          <Table withTableBorder verticalSpacing={1}>
            <Table.Tbody>
              {Object.entries(issuedFor).map(([key, value]) => (
                <Table.Tr>
                  <Table.Td>
                    <strong>{intl.formatMessage({ id: key })}</strong>
                  </Table.Td>
                  <Table.Td>{value}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Grid.Col>
      </Grid>
      <Grid>
        {invoice.note && invoice.created_by == getUserId() && (
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div style={{ padding: 10, border: "1px solid lightgrey" }}>
              <strong>{intl.formatMessage({ id: "private_note" })}</strong>
              <div>
                <small>{invoice.note}</small>
              </div>
            </div>
          </Grid.Col>
        )}
        {invoice.public_note && (
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div style={{ padding: 10, border: "1px solid lightgrey" }}>
              <strong>
                {intl.formatMessage({ id: "public_note_invoice" })}
              </strong>
              <div>
                <small>{invoice.public_note}</small>
              </div>
            </div>
          </Grid.Col>
        )}
      </Grid>
      <div
        style={{ border: "1px solid lightgrey", padding: 10, marginTop: 20 }}
      >
        <Title order={4}>{intl.formatMessage({ id: "services" })}</Title>
        <DataTable data={services} columns={columns} />
      </div>
      {invoice.original_ref && (
        <Alert style={{ margin: 10 }} icon={<AlertCircle />} color="red">
          {intl.formatMessage({ id: "this_is_ai_read_invoice" })}
          <br />
          <a target="_blank" href={invoice.original_ref}>
            {intl.formatMessage({ id: "see_original_here" })}
          </a>
        </Alert>
      )}
      <Grid>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Table withRowBorders={false} verticalSpacing={1}>
            <Table.Tbody>
              {invoice.total_novat > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: "right", width: 130 }}>
                    <strong>{intl.formatMessage({ id: "subtotal" })}</strong>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "left" }}>
                    {invoice.total_novat} €
                  </Table.Td>
                </Table.Tr>
              )}
              {invoice.vat_perc > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: "right", width: 130 }}>
                    <strong>{intl.formatMessage({ id: "tax_rate" })}</strong>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "left" }}>
                    {" "}
                    {invoice.vat_perc}%
                  </Table.Td>
                </Table.Tr>
              )}

              {invoice.vat_value > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: "right", width: 130 }}>
                    <strong>{intl.formatMessage({ id: "tax" })}</strong>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "left" }}>
                    {" "}
                    {invoice.vat_value} €
                  </Table.Td>
                </Table.Tr>
              )}

              {invoice.discount && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: "right", width: 130 }}>
                    <strong>
                      {intl.formatMessage({ id: "subtotal_no_disc" })}
                    </strong>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "left" }}>
                    {invoice.total_vat} €
                  </Table.Td>
                </Table.Tr>
              )}
              {invoice.discount && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: "right", width: 130 }}>
                    <strong>{intl.formatMessage({ id: "discount" })}</strong>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "left" }}>
                    {invoice.discount} %
                  </Table.Td>
                </Table.Tr>
              )}

              {invoice.paid_amount && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: "right", width: 130 }}>
                    <strong>
                      {intl.formatMessage({ id: "already_paid_amount" })}
                    </strong>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "left" }}>
                    {invoice.paid_amount} €
                  </Table.Td>
                </Table.Tr>
              )}

              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>{intl.formatMessage({ id: "total_due" })}</strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {(
                    invoice.total_vat *
                      (invoice.discount ? 1 - invoice.discount / 100 : 1) -
                    (invoice.paid_amount ? invoice.paid_amount : 0)
                  ).toFixed(2)}{" "}
                  €
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default InvoiceScreen;
