import { Navigate } from "react-router-dom";
import {
  getToken,
} from "../functions/tokens";
import { useEffect, useState } from "react";
import { clearData } from "../functions/helpers";

export const Logout = ({ setLoggedin }) => {
  const [logout, setLogout] = useState(false);
  useEffect(() => {
    if (getToken() != null) {
      clearData();
      setLoggedin(false);
      setLogout(true);
    }
  }, []);

  useEffect(() => {
    window.location.href = "/login";
  }, [logout]);

  return <div></div>;
};
