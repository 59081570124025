import { apiFetch, handleResponse, url } from ".";
import { getBusinessID, getToken } from "../tokens";
const u = "users/";

export const uploadProfilePicAPI = (file) => {
  const token = getToken();
  const formData = new FormData();
  const business_id = getBusinessID();
  formData.append("file", file);

  return fetch(url + "upgo-route/upload-profile-pic", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
      Business: `${business_id}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};

export const searchUsersForFriends = async (data) =>
  apiFetch(`${u}search-users`, data);

export const sendFriendRequest = async (data) =>
  apiFetch(`${u}send-friend-request`, data);

export const getFriends = async () => apiFetch(`${u}get-friends`);

export const acceptFriendRequest = async (data) =>
  apiFetch(`${u}accept-friend-request`, data);

export const getFriedRequestCount = async () =>
  apiFetch(`${u}get-request-count`);

export const deleteFriendRequest = async (data) =>
  apiFetch(`${u}delete-friend-request`, data);

export const getSelfUserData = async () => apiFetch(`${u}get-user-data`);

export const searchFriendCredentials = async (data) =>
  apiFetch(`${u}search-friends`, data);

export const getFriendCredentialsById = async (data) =>
  apiFetch(`${u}get-friend-by-id`, data);

export const getUserSettings = async () => apiFetch(`${u}get-user-settings`);

export const saveUserSettingsApi = async (data) =>
  apiFetch(`${u}save-user-settings`, data);

export const uploadInvoice = (file) => {
  const token = getToken();
  const formData = new FormData();
  formData.append("file", file);

  return fetch(url + "upgo-route/upload-invoice", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};
