import { ActionIcon, Alert, Button, Card } from "@mantine/core";
import { useIntl } from "react-intl";

export const NotificationCard = ({
  count,
  messageId,
  viewPath,
  icon,
  showView = true,
}) => {
  const intl = useIntl();

  return (
    <Card
      withBorder
      style={{
        marginBottom: 5,
        borderRadius: 8,
        padding: 3,
        // backgroundColor: "#00ff0005",
        // border: "0.5px solid #00ff00aa",
      }}
    >
      <div
        style={{
          fontSize: 12,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon ? (
            <ActionIcon
              style={{ marginBottom: -2 }}
              variant="transparent"
              color="green"
              size="sm"
            >
              {icon}
            </ActionIcon>
          ) : null}
          <div style={{ paddingTop: 5, marginLeft: 10 }}>
            {intl.formatMessage({ id: messageId }, { count })}
          </div>
        </div>
        {showView && (
          <div>
            <Button
              color="green"
              size="xs"
              variant="outline"
              onClick={() => (window.location.href = viewPath)}
            >
              {intl.formatMessage({ id: "view" })}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};
