import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import {
  Badge,
  Box,
  Button,
  Container,
  Grid,
  GridCol,
  Modal,
  SimpleGrid,
  Title,
} from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { ArrowBadgeLeft, ArrowBadgeRight } from 'tabler-icons-react';
import { useIntl } from 'react-intl';
import { getInvoices } from '../../functions/api/invoiceApi';
import { getUserId } from '../../functions/tokens';
import { InvoiceDetailsModal } from '../../Components/Modals/InvoiceDetailsModal';

let calendar;

const InvoiceCalendar = ({ mode = 'month', setMode, initDate, setInitDate }) => {
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [initialUsed, setInitialUsed] = useState(false);

  const calendarRef = useRef(null);

  const intl = useIntl();

  useEffect(() => {
    createCalendar();
    // eslint-disable-next-line
  }, [events]);

  useEffect(() => {
    getInvoices({ type: 'calendar' }).then((invoices) => {
      const userId = getUserId();
      const coloredEvents = invoices.map((invoice) => ({
        ...invoice,
        color: invoice.by_user_id !== userId ? 'red' : 'limegreen',
      }));
      setEvents(coloredEvents);
    });
  }, []);

  const closeModal = () => {
    setModal(false);
    setSelectedEvent(null);
  };

  const createCalendar = () => {
    const initial = mode
      ? mode == 'month'
        ? 'dayGridMonth'
        : mode == 'week'
        ? 'dayGridWeek'
        : 'dayGridDay'
      : 'dayGridMonth';
    calendar = new Calendar(calendarRef.current, {
      plugins: [interaction, dayGridPlugin],
      firstDay: 1,
      initialView: initial,
      initialDate: new Date(),
      selectable: true,
      editable: true,
      events: events,
      headerToolbar: '',
      height: 'auto',
      updateSize: true,
      handleWindowResize: true,
      windowResizeDelay: 100,
      // Add new event
      // select: (info) => {
      //   setModalAdd(true);
      //   setStartDate(info.start);
      //   setEndDate(info.end);
      //   setRadios('bg-info');
      // },
      // Edit calendar event action
      eventClick: ({ event }) => {
        setSelectedEvent(event?._def?.extendedProps);
        setModal(true);
      },
    });
    calendar.render();
    setInitialUsed(true);
    setCurrentDate(calendar.view.title);
    // setInitDate(calendar.view.title);
  };

  const changeView = (newView) => {
    calendar.changeView(newView);
    setMode &&
      setMode(
        newView == 'dayGridMonth'
          ? 'month'
          : newView == 'dayGridWeek'
          ? 'week'
          : 'day'
      );
    setCurrentDate(calendar.view.title);
    setInitDate(calendar.view.title);
  };

  return (
    <div>
      <InvoiceDetailsModal
        open={modal}
        setOpen={closeModal}
        modalData={selectedEvent}
      />
      <Container maxWidth={false} component={Box}>
        <div style={{ marginBottom: 20, marginTop: 10 }}>
          <Grid
            container
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="0.5rem"
          >
            {/* <Grid.Col span={12}>{currentDate}</Grid.Col> */}
            <Grid.Col span={12}>
              <Grid>
                <GridCol span={{ base: 12, md: 8 }}>
                  <Grid>
                    <GridCol span={{ base: 4, md: 4 }}>
                      <Button
                        variant="filled"
                        color="green"
                        size="small"
                        fullWidth
                        style={{ margin: 2 }}
                        onClick={() => changeView('dayGridMonth')}
                      >
                        {intl.formatMessage({ id: 'calendar_month_look' })}
                      </Button>
                    </GridCol>
                    <GridCol span={{ base: 4, md: 4 }}>
                      <Button
                        variant="filled"
                        color="green"
                        size="small"
                        fullWidth
                        style={{ margin: 2 }}
                        onClick={() => changeView('dayGridWeek')}
                      >
                        {intl.formatMessage({ id: 'calendar_week_look' })}
                      </Button>
                    </GridCol>
                    <GridCol span={{ base: 4, md: 4 }}>
                      <Button
                        variant="filled"
                        color="green"
                        size="small"
                        fullWidth
                        style={{ margin: 2 }}
                        onClick={() => changeView('dayGridDay')}
                      >
                        {intl.formatMessage({ id: 'calendar_day_look' })}
                      </Button>
                    </GridCol>
                  </Grid>
                </GridCol>

                <GridCol span={{ base: 6, md: 2 }}>
                  <Button
                    variant="outline"
                    size="small"
                    fullWidth
                    color="green"
                    style={{ margin: 2 }}
                    onClick={() => {
                      {
                        calendar.prev();
                        setCurrentDate(calendar.view.title);
                        setInitDate(calendar.view.title);
                      }
                    }}
                  >
                    <ArrowBadgeLeft />
                  </Button>
                </GridCol>
                <GridCol span={{ base: 6, md: 2 }}>
                  <Button
                    variant="outline"
                    size="small"
                    color="green"
                    fullWidth
                    style={{ margin: 2 }}
                    onClick={() => {
                      {
                        calendar.next();
                        setCurrentDate(calendar.view.title);
                        setInitDate(calendar.view.title);
                      }
                    }}
                  >
                    <ArrowBadgeRight />
                  </Button>
                </GridCol>
              </Grid>
            </Grid.Col>
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title style={{ marginBottom: 5 }} order={4}>
            {currentDate}
          </Title>
          <div>
            <Badge color="red" size="sm" style={{ marginRight: 5 }}>
              {intl.formatMessage({ id: 'invoice_calendar_received' })}
            </Badge>
            <Badge color="green" size="sm">
              {intl.formatMessage({ id: 'invoice_calendar_sent' })}
            </Badge>
          </div>
        </div>
      </Container>
      <Container>
        <div
          className="calendar"
          data-toggle="calendar"
          id="calendar"
          ref={calendarRef}
        />
      </Container>
    </div>
  );
};

export default InvoiceCalendar;
