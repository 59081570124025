  // Function to generate columns configuration
export const getColumnsConfig = (template) => {
    const columns = [
      {
        key: "name",
        title: "service",
        searchable: true,
        sort: true,
        render: (data) => data.name,
      },
      {
        key: "cost",
        title: "cost",
        searchable: false,
        sort: true,
        render: (data) => `${data.cost} €`,
      },
      {
        key: "quantity",
        title: "quantity",
        searchable: true,
        sort: true,
        render: (data) => data.quantity,
      },
      {
        key: "total",
        title: "total",
        searchable: true,
        sort: true,
        render: (data) => `${data.cost * data.quantity} €`,
      },
    ];
  
    if (template.vat_individual) {
      columns.push({
        key: "vat",
        title: "vat",
        searchable: false,
        sort: true,
        render: (data) => `${data.vat} %`,
      });
    }
  
    return columns;
  };
  
  // Function to generate the due date options array
export const getDueDateOptions = (intl) => {
    return [
      {
        label: intl.formatMessage({ id: "due_date_day_after" }),
        value: "1d",
      },
      {
        label: intl.formatMessage({ id: "due_date_two_day_after" }),
        value: "2d",
      },
      {
        label: intl.formatMessage({ id: "due_date_three_day_after" }),
        value: "3d",
      },
      {
        label: intl.formatMessage({ id: "due_date_four_day_after" }),
        value: "4d",
      },
      {
        label: intl.formatMessage({ id: "due_date_five_day_after" }),
        value: "5d",
      },
      {
        label: intl.formatMessage({ id: "due_date_six_day_after" }),
        value: "6d",
      },
      {
        label: intl.formatMessage({ id: "due_date_week_after" }),
        value: "1w",
      },
      {
        label: intl.formatMessage({ id: "due_date_two_week_after" }),
        value: "2w",
      },
      {
        label: intl.formatMessage({ id: "due_date_three_week_after" }),
        value: "3w",
      },
      {
        label: intl.formatMessage({ id: "due_date_month_after" }),
        value: "1m",
      },
      {
        label: intl.formatMessage({ id: "due_date_two_month_after" }),
        value: "2m",
      },
    ];
  };
  
  export const getLabelForValue = (intl, value) => {
    const options = getDueDateOptions(intl);
    const option = options.find((option) => option.value === value);
    return option ? option.label : null;
  };
  