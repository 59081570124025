import { useState } from 'react';
import InvoiceCalendar from './InvoiceCalendar';

export const InvoiceCalendarScreen = () => {
  const [mode, setMode] = useState('month');
  const [initDate, setInitDate] = useState(new Date());
  return (
    <InvoiceCalendar
      mode={mode}
      setMode={setMode}
      initDate={initDate}
      setInitDate={setInitDate}
    />
  );
};
