import React from "react";
import { Group, Avatar, Text, Badge, Button, Card } from "@mantine/core";
import {
  IconCheck,
  IconX,
  IconClock,
  IconTrash,
  IconInvoice,
  IconSend,
} from "@tabler/icons-react";

import { UserCheck, AlertTriangle } from "tabler-icons-react";
import { useIntl } from "react-intl";
import {
  getBusinessID,
  getBusinessUserID,
  getUserId,
} from "../../functions/tokens";
import {
  bizPlaceholderImg,
  placeholderImg,
} from "../UserButton/AccountSelector";

const UserCard = ({
  data,
  config,
  acceptFriendRequestFn,
  deleteFriendRequestFn,
}) => {
  const intl = useIntl();
  const business_id = getBusinessUserID();
  const external_biz_id = data?.userid;

  const userHimself = business_id
    ? external_biz_id == business_id
    : data?.userid == getUserId();

  const renderAvatar = (src, biz) => (
    <Avatar
      src={
        src
          ? src
          : external_biz_id || data?.business || data?.user_json?.user_img
          ? bizPlaceholderImg
          : placeholderImg
      }
      size={36}
      radius={biz ? "sm" : "xl"}
    />
  );

  const renderCredentialInfo = () => {
    if (data?.credentials) {
      const creds = JSON.parse(data?.credentials);
      return (
        <Group gap="sm">
          <small style={{ fontSize: 10, position: "relative", width: "100%" }}>
            {Object.entries(creds).map((item) =>
              item[0] == "priv" ? (
                item[1] == true && (
                  <Badge
                    variant="outline"
                    color="yellow"
                    style={{ position: "absolute", right: 5, top: 5 }}
                    size="xs"
                  >
                    {intl.formatMessage({ id: "priv" })}
                  </Badge>
                )
              ) : item[0] != "bank" &&
                item[0] != "bank_acc" &&
                item[0] != "phy_addr" &&
                item[0] != "jur_addr" &&
                item[0] == "save" ? (
                <Badge size="xs" style={{ float: "right" }}>
                  {intl.formatMessage({ id: "saved_for_reuse" })}
                </Badge>
              ) : (
                <span>
                  <strong>{intl.formatMessage({ id: item[0] })}</strong>:{" "}
                  {item[1]}
                  <br />
                </span>
              )
            )}
          </small>
        </Group>
      );
    }
  };

  const renderBadge = () => {
    if (config?.showApprovalStatus) {
      if (data.approved == 0) {
        return (
          <Badge color="green" size="xs">
            {data?.friend_data?.friend_id == data?.userid
              ? intl.formatMessage({ id: "friend_request_sent" })
              : intl.formatMessage({ id: "friend_request_received" })}
          </Badge>
        );
      } else if (data.approved == 1) {
        return (
          <Badge color="dodgerblue" size="xs">
            <UserCheck size="10" style={{ paddingTop: 3, marginRight: 2 }} />
            {intl.formatMessage({ id: "in_friends" })}
          </Badge>
        );
      }
    }
    if (config?.showCredentials && !data.credentials) {
      return (
        <Badge color="red" size="xs">
          <AlertTriangle size="10" style={{ paddingTop: 3, marginRight: 2 }} />
          {intl.formatMessage({ id: "no_public_credentials" })}
        </Badge>
      );
    }
    return null;
  };

  const renderButtons = () => {
    if (config?.showButtons) {
      if (data.type === "request" && data.approved == 0) {
        return (
          <Group gap="sm">
            <Button
              color="green"
              onClick={() => {
                acceptFriendRequestFn({
                  ...data,
                  label: `${data.user_json.user_name} ${data?.user_json.user_surname}`,
                });
              }}
            >
              <IconCheck />
            </Button>
            <Button
              color="red"
              onClick={() =>
                deleteFriendRequestFn({
                  ...data,
                  label: `${data.user_json.user_name} ${data?.user_json.user_surname}`,
                })
              }
            >
              <IconX />
            </Button>
          </Group>
        );
      } else if (data.approved == 0) {
        return (
          <Button color="dodgerblue">
            <IconClock />
          </Button>
        );
      } else {
        return (
          <Button
            color="red"
            onClick={() =>
              deleteFriendRequestFn({
                ...data,
                label: `${data.user_json?.user_name} ${data?.user_json?.user_surname}`,
              })
            }
          >
            <IconTrash />
          </Button>
        );
      }
    }
    return null;
  };

  const handleClick = () => {
    if (config.onClick) {
      config.onClick(data);
    }
  };

  return (
    <div
      style={{
        // marginTop: 5,
        // marginBottom: 5,
        backgroundColor: userHimself ? "#00ff0050" : "#fff",
        border: "0.5px solid lightgrey",
        padding: config?.padding ? config?.padding : 14,
        cursor: config?.showPointer ? "pointer" : "default",
      }}
      onClick={handleClick}
    >
      <Group gap="sm">
        {config.hideAvatar != true &&
          data.status != "saved" &&
          renderAvatar(
            data?.user_img || data?.user_json?.user_img,
            data.business
          )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <div>
            <Group gap="sm" justify="space-between">
              <Text size="sm">
                {(business_id && data?.business_name) ||
                  data?.business_data?.name ||
                  data?.name ||
                  data?.user_json?.user_name}{" "}
                {data?.surname || data?.user_json?.user_surname}
                {userHimself && `(${intl.formatMessage({ id: "me" })})`}
              </Text>
              {renderBadge()}
              {data.business == true && (
                <Badge
                  color="orange"
                  variant="light"
                  style={{ position: "absolute", right: 10 }}
                  size="xs"
                >
                  {intl.formatMessage({ id: "business_account_badge" })}
                </Badge>
              )}
            </Group>
            <Group gap="sm">
              <Text size="xs" opacity={0.3}>
                {data?.uuid || data?.user_json?.uuid || data?.b_uuid}
              </Text>
            </Group>
          </div>
        </div>
        {config.showIssueButton && (
          <a
            href={`/create-invoice/new/u/${
              data?.uuid || data?.user_json?.uuid
            }`}
            title={intl.formatMessage({ id: "issue_invoice_to_this_user" })}
          >
            <Button>
              <IconInvoice size={16} strokeWidth={2} />
              <IconSend style={{ marginLeft: -2 }} size={14} strokeWidth={2} />
            </Button>
          </a>
        )}
        <div>{renderButtons()}</div>
      </Group>
      {config?.showCredentialsData && renderCredentialInfo()}
    </div>
  );
};

export default UserCard;
