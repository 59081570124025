import { Pagination } from "@mantine/core";

export const PaginationItem = ({ currentPage, setCurrentPage, totalPages, style }) => (
  <Pagination
    color="#ff0000"
    boundaries={3}
    size="lg"
    style={style}
    siblings={3}
    value={currentPage}
    onChange={setCurrentPage}
    total={totalPages}
  />
);
