import { Card, Grid, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import {
  getHomeScreenTemplates,
  getNotificationData,
} from "../../functions/api/notificationApi";
import {
  IconFileInvoice,
  IconMessageCircleExclamation,
  IconUserExclamation,
} from "@tabler/icons-react";
import FeatureCard from "../../Components/FeatureCard";
import HeroCard from "../../Components/HeroCard";
import StartCards from "../../Components/StartCards";
import { featureData, heroData } from "../../functions/helpers";
import { NotificationCard } from "../../Components/NotificationCard";
import { TemplateCard } from "../../Components/TemplateCard";
import { MailFast } from "tabler-icons-react";

export const MainScreen = () => {
  const [templates, setTemplates] = useState([]);

  const [notif, setNotif] = useState([]);

  useEffect(() => {
    getNotificationData().then((x) => setNotif(x));
    getHomeScreenTemplates().then((x) => setTemplates(x));
  }, []);

  const renderItems = [
    {
      bg: "https://images.unsplash.com/photo-1670078112799-e470ccc87951?q=40&w=700&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "add_contacts",
      url: "contacts",
      d: true,
      spaceBottom: true,
    },
    {
      bg: "https://images.unsplash.com/photo-1666410017173-091dce1b9e7d?q=40&w=744&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "create_new_invoice",
      url: "create-invoice/new",
      d: true,
    },

    {
      bg: "https://images.unsplash.com/photo-1714464702911-9b88ec2cdb37?q=15&w=460&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "invoice_overview",
      url: "all-invoices",
    },

    {
      bg: "https://images.unsplash.com/photo-1703628115946-21c57c659604?q=15&w=563&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "received_invoices",
      url: "received-invoices",
    },

    {
      bg: "https://images.unsplash.com/photo-1706554596469-8794cae235a3?q=15&w=560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "sent_invoices",
      url: "sent-invoices",
    },

    {
      bg: "https://images.unsplash.com/photo-1708435031742-2d07f66c316f?q=15&w=432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "support",
      url: "support",
    },
  ];

  return (
    <div>
      <div>
        {notif.credentials == 0 && (
          <div style={{ margin: -20, marginBottom: 10 }}>
            <HeroCard data={heroData} />
          </div>
        )}
        {notif?.invoices > 0 && (
          <NotificationCard
            icon={<IconFileInvoice size={30} />}
            count={notif.invoices}
            messageId="new_invoices_received"
            viewPath="/received-invoices"
          />
        )}
        {notif?.friends > 0 && (
          <NotificationCard
            icon={<IconUserExclamation size={30} />}
            count={notif.friends}
            messageId="new_friend_requests_received"
            viewPath="/contacts"
          />
        )}
        {notif?.emails > 0 && (
          <NotificationCard
            icon={<MailFast size={30} />}
            count={notif.emails}
            messageId="new_emails_received"
            viewPath="/contacts"
          />
        )}
        <Grid>
          <Grid.Col span={{ base: 12 }}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <FeatureCard data={featureData} />
              </Grid.Col>
              {notif.credentials != 0 && (
                <>
                  {/* mobile */}
                  {templates.length > 0 &&
                    templates.map((template, index) => (
                      <TemplateCard
                        key={`m-t-${index}`}
                        template={template}
                        mobile={true}
                      />
                    ))}
                  {renderItems.map((itm, index) => (
                    <Grid.Col
                      span={{ base: 6 }}
                      className="mantine-hidden-from-md"
                      key={`m-x-${index}`}
                    >
                      <StartCards
                        small={true}
                        background={`url(${itm.bg})`}
                        title={itm.title}
                        url={`/${itm.url}`}
                      />
                    </Grid.Col>
                  ))}
                </>
              )}
              {notif.credentials != 0 && (
                <Grid.Col span={{ base: 12, md: 6 }}>
                  {renderItems
                    .filter((it) => it.d == true)
                    .map((itm, index) => (
                      <StartCards
                        key={`m-d-${index}`}
                        small={false}
                        background={`url(${itm.bg})`}
                        title={itm.title}
                        url={`/${itm.url}`}
                        spaceBottom={itm.spaceBottom == true}
                      />
                    ))}
                </Grid.Col>
              )}
              {templates.length > 0 &&
                templates.map((template, index) => (
                  <TemplateCard
                    key={`d-x-${index}`}
                    template={template}
                    mobile={false}
                  />
                ))}
            </Grid>
          </Grid.Col>
        </Grid>
      </div>

      <Card withBorder shadow="lg" style={{ marginTop: 20, display: "none" }}>
        <div>
          <Title order={3}>TODO:</Title>
          <ul>
            <li>Service builder - build a service,save it, reuse it</li>
            <li>
              Flag suspicious invoices, senders - like, this company changed
              credentials recently or is impersonating a contact
            </li>
            <li>
              Security: rēķiniem pieminēt, cik reizes esi konkrētajam lietotājam
              sūtījis un cik reizes esi saņēmis no viņa rēķinu
            </li>
            <li>
              Credibility system, based issued invoices, reports if scammer,
              paid invoices, invoices paid on time and after deadline etc
            </li>
            <li>if sending to invoiceria email, then auto put in users acc</li>
            <li>
              if sending to email registered in invoiceria - suggest to issue ?
            </li>
            <li>
              Statistika - cik šonedēļ jāapmaksā, cik šonedēļ samaksājuši, cik
              plānojas saņemt maksājumus utt
            </li>
            <li>
              Sakārtot iespēju pievienot rekvizītus, kas nav reģistrēts
              lietotājs
            </li>
            <li>
              Ierobežot - ja rēķinu izraksti no sava konta, bet cita drauga
              vārdā, tad to vari izrakstīt vien sev
            </li>
            <li>
              Šobrīd rēķinu var izrakstīt jebkura drauga vārdā. Kaut kur
              jāparāda, ka par spīti tam, ka rēķinā ir pieminēts ka rēķins ir no
              lietotāja X, rēķinu varētu būt izrakstījis lietotājs Y.
            </li>
            <li>
              Pievienot iespeju izvēlēties rēķina rakstīšanu pēdējiem
              lietotājiem
            </li>
            <li>Configure currency</li>
            <li>Configure default values (pvn on/off)</li>
            <li>
              in settings set - Allow to find by email, allow to find by reg.nr,
              allow to find by email
            </li>
            <li>
              Iespjēja definēt, vai esi publiski atrodams vai vien pēc uuid
            </li>
            <li>
              Recurr - Automātiska atkārtotu rēķinu izrakstīšana (reizi X
              izrakstīt rēķinu par summu Y, lietotājam Z līdz datumam W, dublēt
              - sūtīt uz epastu, ja ir reģ)
            </li>
            <li>
              Iespēja pielabot atkārtotos rēķinus (gan visiem turpmākajaiem
              maksājumiem, gan nākamajam individuāli)
            </li>
            <li>
              Customising home screen - give the users a chance to select also
              what metrics to show, like invoices to be paid this
              week/month/day, notifications, sum required for invoices, invoices
              expected to be paid, invoices that will be sent out and then have
              a screen that has all of those as well
            </li>
            <li>
              When login - default start screen, tad iespēja definēt start
              screen shortcuts directly uz invoice template utt
            </li>
            <li>
              API for startups, automātiska rēķinu izsūtīšana (ieekonomē
              izmansas storage un paatrina development laiku)
            </li>
            <li>Microservice for pdf/file to json after ai is done</li>
            <li>Citi rēķinu tipi - čeki, pavadzīmes utt</li>
            <li>
              Ja rēķins tiek sūtīts nereģistrētam lietotājam, tad ..? Sūtīt PDF
              e-pastā, ar piedāvājumu reģistrēties, I guess.
            </li>
            <li>
              Iespēja nosūtīt pavēsti, ka rēķinu esi apmaksājis + pievienot
              maksājuma pierādījumu + visi statusi, kas varētu tam sekot
            </li>

            <li>Upgrade add friend screen</li>
            <li>
              Rēķinu valodas, iespējas nosūtīt rēķinu konkrētā valodā, iespēja
              izvēlēties rēķinu konkrētā valodā/translations in invoices
            </li>

            <li>Drag & drop service order</li>
            <li>Wizzard for creating invoice?</li>
            <li>
              <strong>MORE ERROR HANDLING!</strong>
              <strong>Iespēja nomainīt paroli</strong>
              <strong>E-mail verification!</strong>

              <strong>
                Rēķinu veidošanas brīdī parādīt, ja rekvizitiem esi izvēlējies
                draugu/lietotāju
              </strong>
            </li>
            <li>Iespēja customizēt rēķinus / izvēlēties rēķina izskatu!</li>
            <li>Design your own email</li>
            <li>
              Pievienot rēķinu darbību vēsturi iekš rēķina pārskata (kad
              izveidots, kad apmaksāts, kam un kad izsūtīts, kad mainīts kāds
              status) / Darbību atskaites - reģistrēt, kad e-pasts ir nosūtīts,
              kam, utt.
            </li>
            <li>
              Invoice flows - kas notiek ar rēķiniem, kad tie tiek saņēmti utt
            </li>

            <li>Iespēja importēt vienu vai vairākus rēķinus</li>
            <li>
              Iespēja definēt automātisku rēķinu nosūtīšanu uz konkrētu adresi,
              konkrētā laikā (piem, grāmatvedei)
            </li>

            <li>
              Pielabot atrādīšanu/summu gadījumā, ja rēķins ir daļēji apmaksāts
            </li>
            <li>
              Izdomāt līdz galam loģiku ar rēķinu labojumiem. Izveidot rēķina
              kopiju, ja piem, pagarinu apmaksas termiņu, vai izlabot oriģinālu?
            </li>
            <li>Noslīpēt rēķinu izrakstīšanu sev</li>
            <li>E-mail logic</li>
            <li>
              Pabeigt iekšējo rēķinu loģiku (notification, ka lietotājs a ir
              izveidojis rēķinu tev un attiecīgi apstiprināt, lai arī viņam
              rādās rekvizīti)
            </li>
            <li>
              Iespēja izvēlēties reģistrēto lietotāju, bet pēc tam pielabot
              rekvizītus
            </li>
            <li>
              Atļaut piekļūt rēķinam bez reģistrācijas, bet aizsargāt rēķinu ar
              PIN
            </li>
          </ul>
        </div>
      </Card>
    </div>
  );
};
