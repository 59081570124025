import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Text,
  Textarea,
  useMantineTheme,
  Grid,
  LoadingOverlay,
  Alert,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import {
  createInvoice,
  getInvoiceLastLogo,
  getInvoiceLastName,
  getSingleInvoice,
} from "../../functions/api/invoiceApi";
import {
  EditInvoiceNrModal,
  replacePlaceholders,
} from "../../Components/Modals/EditInvoiceNrModal";
import { EditInvoiceSubjectModal } from "../../Components/Modals/EditInvoiceSubjectModal";
import { ServiceList } from "../../Components/Lists";
import {
  getFriendCredentialsById,
  searchFriendCredentials,
} from "../../functions/api/userApi";
import { useParams } from "react-router-dom";
import {
  getBusinessID,
  getBusinessUserID,
  getUserId,
} from "../../functions/tokens";
import {
  RenderInvoiceSubjectFields,
  dataMapping,
  useAmountDue,
  useIndividualSubTotalNoVat,
  useSubtotal,
} from "./fn";
import { ItemSwitch } from "../../Components/ItemSwitch";
import {
  DateSelectionFields,
  DiscountInput,
  ImportDataAIModal,
  InvoiceNameContainer,
  InvoiceTagInput,
  LogoContainer,
  TaxInput,
  TotalsHolder,
} from "./CreateComponents";
import { AlertCircle, Stars } from "tabler-icons-react";

function CreateInvoiceScreen() {
  const intl = useIntl();
  const theme = useMantineTheme();

  const [dueDate, setDueDate] = useState(new Date());
  const [services, setServices] = useState([]);
  const [notes, setNotes] = useState("");
  const [tags, setTags] = useState([]);
  const [taxRate, setTaxRate] = useState(21);
  const [discount, setDiscount] = useState(null);
  const [publicNotes, setPublicNotes] = useState("");
  const [modalData, setModalData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [issuedFor, setIssuedFor] = useState({});
  const [issuedBy, setIssuedBy] = useState({});
  const [individualVat, setIndividualVat] = useState(false);
  const [invoiceName, setInvoiceName] = useState();
  const [invoiceModal, setInvoiceModal] = useState();
  const [issueDate, setIssueDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  const [noCreds, setNoCreds] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  const param = useParams();

  const retrieveInvoice = async (invoice_id, block, returnData = false) => {
    const data = await getSingleInvoice({ invoice_code: invoice_id }).then(
      (data) => {
        if (block) {
          data.status != "draft" && setIsBlock(true);
        }
        setServices(JSON.parse(data.services_json));
        if (data.by_user_id) {
          getFriendCredentialsById({ id: data.by_user_id }).then((res) => {
            const myData = JSON.parse(res[0].credentials);
            const mappedData = dataMapping({ data: myData });
            setIssuedBy({ ...mappedData, friend: res[0] });
          });
        } else {
          setIssuedBy(JSON.parse(data.issuedby_json));
        }

        if (data.for_user_id) {
          getFriendCredentialsById({ id: data.for_user_id }).then((res) => {
            const myData = JSON.parse(res[0].credentials);
            const mappedData = dataMapping({ data: myData });
            setIssuedFor({ ...mappedData, friend: res[0] });
          });
        } else {
          setIssuedFor(JSON.parse(data.issuedfor_json));
        }

        setTaxRate(data.vat_perc);
        setPublicNotes(data.public_note);
        setNotes(data.note);
        setDiscount(data.discount);
        setIndividualVat(data.vat_individual);
        setLogo(data.logo_url ? data.logo_url : null);
        // invoice_index_id <- to do, look for type
        return data;
      }
    );

    if (returnData) {
      return data;
    }

    setLoading(false);
  };

  useEffect(() => {
    if (param.edit) {
      async function invoiceRetrievalForEdit() {
        const data = await retrieveInvoice(param.edit, true, true);

        if (data.tags.length > 0) {
          setTags(data.tags);
        }

        setInvoiceName({
          value: data.invoice_name,
          label: data.invoice_index_id,
        });

        if (data.logo_url) {
          setLogo(data.logo_url);
        }
        setInvoiceId(data.invoice_id);
        setLoading(false);
      }
      invoiceRetrievalForEdit();
    } else {
      if (param.id != "new") {
        retrieveInvoice(param.id);
      } else {
        getFriendCredentialsById(
          getBusinessID() ? { id: getBusinessUserID() } : { id: getUserId() }
        ).then((res) => {
          if (res[0]?.credentials) {
            const myData = JSON.parse(res[0].credentials);
            if (myData != null) {
              const mappedData = dataMapping({ data: myData });
              setIssuedBy({ ...mappedData, friend: res[0] });
            }
          } else {
            setNoCreds(true);
          }

          getInvoiceLastName().then((x) => {
            if (x.length > 0) {
              setInvoiceName({
                value: replacePlaceholders(
                  x[0].invoice_name,
                  x[0].invoice_index
                ),
                id: x[0].indexid,
              });
            }

            if (param.user) {
              searchFriendCredentials({ search: param.user }).then((res) => {
                const crds = res.find((item) => item.uuid === param.user);
                const myData = JSON.parse(crds.credentials);
                const mappedData = dataMapping({ data: myData });

                setIssuedFor({ ...mappedData, friend: crds });
                setLoading(false);
              });
            }
            if (!param.user) {
              setLoading(false);
            }
          });

          getInvoiceLastLogo().then((l) => {
            setLogo(l);
          });
        });
      }
    }
  }, []);

  const importDataFromInvoice = (data) => {
    setDueDate(new Date(data.dueDate ? data.dueDate : null));
    setServices(data.services);
    setTaxRate(data.taxRate);
    setPublicNotes(data.publicNotes);
    setInvoiceName({ value: data.invoiceName, id: 0 });
    setDiscount(data.discount);
    setIndividualVat(data.vat_individual);

    setIssuedFor(data.issuedFor);
    setIssuedBy(data.issuedBy);
  };

  const createInvoiceProxy = (returnTo, template = false) => {
    if (invoiceName.value == "" || invoiceName.value == null) {
      return window.alert(intl.formatMessage({ id: "invoice_name_missing" }));
    }

    try {
      createInvoice({
        dueDate,
        services,
        notes,
        taxRate,
        discount,
        publicNotes,
        issuedBy,
        issuedFor,
        invoiceName: invoiceName.value,
        by_credential_id: issuedBy?.friend?.cid ? issuedBy?.friend?.cid : null,
        for_credential_id: issuedFor?.friend?.cid
          ? issuedFor?.friend?.cid
          : null,
        by_user_id: issuedBy?.friend?.userid ? issuedBy?.friend?.userid : null,
        for_user_id: issuedFor?.friend?.userid
          ? issuedFor?.friend?.userid
          : null,
        invoice_index_id: invoiceName.id > 0 ? invoiceName.id : null,
        vat_individual: individualVat,
        template: template,
        logo_url: logo,
        tags,
        invoice_id: invoiceId,
      }).then((data) => {
        window.location.href = `/${returnTo}/${data.eId}`;
      });
    } catch (e) {
      return window.alert(
        intl.formatMessage({ id: "something_went_wrong_recheck" })
      );
    }
  };

  const submitCreateInvoice = async () => {
    if (issuedFor.length == 0 || services.length == 0 || issuedBy.length == 0) {
      return window.alert(intl.formatMessage({ id: "fill_more_fields" }));
    }
    if (!invoiceName) {
      return window.alert(intl.formatMessage({ id: "invoice_name_missing" }));
    }

    createInvoiceProxy(`invoice`);
  };

  const submitCreateTemplate = async () => {
    createInvoiceProxy(`template-invoices`, true);
  };

  const setUpModal = (data) => {
    setModalOpen(true);
    const mappedData = dataMapping(data);
    setModalData({ type: data.type, data: mappedData });
  };

  const handleDataSave = () => {
    const updatedData = modalData?.data;
    if (modalData?.type === "for") {
      setIssuedFor(updatedData);
    } else {
      setIssuedBy(updatedData);
    }
    setModalData(null);
    setModalOpen(false);
  };

  const handleDueDateChange = (value) => {
    setDueDate(value);
  };

  const handleIssueDateChange = (value) => {
    setIssueDate(value);
  };

  const individualSubTotalNoVat = useIndividualSubTotalNoVat(
    services,
    individualVat
  );
  const subtotal = useSubtotal(services, { vat_individual: individualVat });
  const amountDue = useAmountDue(subtotal, {
    vat_individual: individualVat,
    vat_perc: taxRate,
  });

  if (isBlock) {
    return (
      <div>
        <h2>{intl.formatMessage({ id: "forbidden" })}</h2>
        <div style={{ marginBottom: 10 }}>
          {intl.formatMessage({ id: "allowed_to_edit_only_draft" })}
        </div>
        <Button onClick={() => (window.location.href = "/all-invoices")}>
          {intl.formatMessage({ id: "return_to_invoice" })}
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: 20,
        position: "relative",
        height: loading ? 900 : "auto",
      }}
    >
      {/* <ServiceBuilderModal /> */}
      <div style={{ padding: 5 }}>
        <LoadingOverlay
          visible={loading}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "pink", type: "bars" }}
        />
        <ImportDataAIModal
          open={importModal}
          setOpen={setImportModal}
          action={importDataFromInvoice}
        />
        <EditInvoiceSubjectModal
          modalData={modalData}
          modalOpen={modalOpen}
          handleDataSave={handleDataSave}
          setModalData={setModalData}
          setModalOpen={setModalOpen}
        />
        <EditInvoiceNrModal
          open={invoiceModal}
          setOpen={setInvoiceModal}
          setInvoiceNr={setInvoiceName}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Text size="xl" fw={700}>
            {param.edit
              ? intl.formatMessage({ id: "edit_invoice" })
              : intl.formatMessage({ id: "add_new_invoice" })}
          </Text>
          <Button size="xs" onClick={() => setImportModal(true)}>
            {intl.formatMessage({ id: "import_data" })}{" "}
            <Stars
              style={{ rotate: "50deg", marginBottom: 3, marginLeft: 3 }}
              fill="yellow"
              color="orange"
              size={14}
              strokeWidth={1}
            />
          </Button>
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing.sm,
            marginTop: theme.spacing.md,
          }}
        >
          {noCreds && (
            <Alert
              icon={<AlertCircle color="green" />}
              style={{ display: "flex", alignItems: "center" }}
            >
              {intl.formatMessage({ id: "no_credentials_found" })}{" "}
              <Button
                size="xs"
                style={{ marginLeft: 20 }}
                onClick={() => (window.location.href = "/settings")}
              >
                {intl.formatMessage({ id: "set_credentials" })}
              </Button>
            </Alert>
          )}
          <Grid>
            <RenderInvoiceSubjectFields
              type="by"
              obj={issuedBy}
              setUpModal={setUpModal}
            />
            <RenderInvoiceSubjectFields
              type="for"
              obj={issuedFor}
              setUpModal={setUpModal}
            />
            <Grid.Col span={{ base: 12, md: 6 }}>
              <InvoiceNameContainer
                setInvoiceModal={setInvoiceModal}
                invoiceName={invoiceName}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <LogoContainer logo={logo} setLogo={setLogo} />
            </Grid.Col>
            {/* <Grid.Col span={{ base: 12, md: 4 }}>
              <InvoiceLanguageContainer logo={logo} setLogo={setLogo} />
            </Grid.Col> */}
          </Grid>
          <InvoiceTagInput tags={tags} setTags={setTags} />
          <DateSelectionFields
            issueDate={issueDate}
            handleDueDateChange={handleDueDateChange}
            dueDate={dueDate}
            handleIssueDateChange={handleIssueDateChange}
          />
        </div>
        <ServiceList
          services={services}
          setServices={setServices}
          individualVat={individualVat}
        />
        <div style={{ marginTop: theme.spacing.md }}>
          <ItemSwitch
            checked={individualVat}
            setChecked={(e) => setIndividualVat(e)}
            labelKey="individual_tax_for_each"
          />
          <ItemSwitch
            checked={taxRate > 0}
            setChecked={(e) => setTaxRate(e == true ? 21 : null)}
            labelKey="tax_rate_enabled"
          />
          {!individualVat && taxRate != null && (
            <TaxInput taxRate={taxRate} setTaxRate={setTaxRate} />
          )}
          <DiscountInput discount={discount} setDiscount={setDiscount} />
          <TotalsHolder
            individualVat={individualVat}
            individualSubTotalNoVat={individualSubTotalNoVat}
            subtotal={subtotal}
            taxRate={taxRate}
            discount={discount}
            amountDue={amountDue}
          />
          <Textarea
            label={intl.formatMessage({ id: "public_notes" })}
            placeholder={intl.formatMessage({ id: "public_notes" })}
            value={publicNotes}
            onChange={(event) => setPublicNotes(event.target.value)}
            style={{ marginBottom: 5 }}
          />
          <Textarea
            label={intl.formatMessage({ id: "notes" })}
            placeholder={intl.formatMessage({ id: "notes" })}
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
            style={{ marginBottom: 5 }}
          />
          {param.edit ? (
            <Grid>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Button
                  variant="filled"
                  onClick={submitCreateInvoice}
                  style={{ backgroundColor: "blue", marginTop: 15 }}
                  fullWidth
                >
                  <FormattedMessage id="save_invoice" />
                </Button>
              </Grid.Col>
            </Grid>
          ) : (
            <Grid>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Button
                  variant="filled"
                  onClick={submitCreateTemplate}
                  style={{ backgroundColor: "orange", marginTop: 15 }}
                  fullWidth
                >
                  <FormattedMessage id="create_template" />
                </Button>
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6 }}>
                <Button
                  variant="filled"
                  onClick={submitCreateInvoice}
                  style={{ backgroundColor: "blue", marginTop: 15 }}
                  fullWidth
                >
                  <FormattedMessage id="create_invoice" />
                </Button>
              </Grid.Col>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateInvoiceScreen;
