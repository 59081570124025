import {
  Paper,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
} from "@mantine/core";
import "./LoginScreen.css";
import { useEffect, useRef, useState } from "react";
import { setData, setGroup, setToken, setUserId } from "../../functions/tokens";
import { handleLogin } from "../../functions/api/authApi";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../Components/Logo/Logo";

export function LoginScreen() {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const formRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const frmNd = formRef.current;

    const handleAnimationEnd = () => frmNd.classList.remove("shake");

    frmNd && frmNd.addEventListener("animationend", handleAnimationEnd);

    return () =>
      frmNd && frmNd.removeEventListener("animationend", handleAnimationEnd);
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await handleLogin(email, password, rememberMe);
      if (!response.ok) {
        throw new Error(intl.formatMessage({ id: "incorrect_login" }));
      }

      const data = await response.json();
      setToken(data.token);
      setGroup(data.group);
      setUserId(data.id);
      setData(data);
      navigate("/main");
    } catch (err) {
      setError(err.message);
      formRef.current.classList.add("shake");
    }
  };

  return (
    <div className="wrapper">
      <Paper className="form" radius={0} p={30} ref={formRef}>
        <Title order={2} className={`title`} ta="center" mt="md" mb={50}>
        {intl.formatMessage({id: "welcome_back"})} <Logo />
        </Title>
        <form>
          <TextInput
            label={intl.formatMessage({id: "email"})}
            placeholder="hello@gmail.com"
            size="md"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordInput
            label={intl.formatMessage({id: "password"})}
            placeholder={intl.formatMessage({id: "password"})}
            mt="md"
            name="password"
            size="md"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <Checkbox label="Keep me logged in" mt="xl" size="md" /> */}
          {error && (
            <div
              style={{
                textAlign: "center",
                color: "red",
                marginTop: 10,
                marginBottom: -20,
              }}
            >
              {error}
            </div>
          )}
          <Text ta="center" mt="xl">
            {intl.formatMessage({ id: "dont_have_an_account" })}{" "}
            <Anchor
              href="#"
              fw={700}
              onClick={(event) => (window.location.href = "/register")}
            >
              {intl.formatMessage({ id: "do_register" })}
            </Anchor>
          </Text>
          <Button
            fullWidth
            mt="xl"
            size="md"
            type="submit"
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: "login" })}
          </Button>
        </form>
        <Text ta="center" mt="lg">
          <Anchor
            href="#"
            fw={700}
            onClick={(event) => (window.location.href = "/forgot-password")}
          >
            {intl.formatMessage({ id: "forgot_password" })}
          </Anchor>
        </Text>
      </Paper>
    </div>
  );
}
