import { Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import React, { useState } from "react";

const DownloadPDFButton = ({ encryptedCode, invoiceName, ai_ref }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    try {
      if (ai_ref) {
        // Direct download from S3 with a manual filename
        const a = document.createElement("a");
        a.href = ai_ref;
        a.download = `${invoiceName}.pdf`; // Enforcing the filename
        document.body.appendChild(a);
        a.click();
        a.remove();
        setLoading(false);
      } else {
        // Fallback to the old method
        const response = await fetch(
          `https://generator-dta.invoiceria.com/?code=${encryptedCode}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
            },
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${invoiceName}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url); // Clean up the object URL
        } else {
          console.error("Failed to download PDF");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <Button
      onClick={handleDownload}
      fullWidth
      loading={loading}
      rightSection={<IconDownload size={14} />}
    >
      Download PDF
    </Button>
  );
};


export default DownloadPDFButton;
