import { Container, Title, Text, Button } from "@mantine/core";
import classes from "./HeroCard.module.css";
import { useIntl } from "react-intl";

export function HeroCard({ data }) {
  const intl = useIntl();
  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%),
        url(${data.img})`,
      }}
    >
      <Container size="lg">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>{data.title}</Title>
            {data.description && (
              <Text className={classes.description} mt={30}>
                {intl.formatMessage({id: data.description})}
              </Text>
            )}

            {data.cta && (
              <Button
                variant="gradient"
                gradient={{ from: "pink", to: "yellow" }}
                size="xl"
                className={classes.control}
                mt={40}
                onClick={data.cta_action}
              >
                {intl.formatMessage({id: data.cta_title})}
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
