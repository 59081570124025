import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { getInvoiceList } from "../../functions/api/invoiceApi";
import { Text, Button, Progress } from "@mantine/core";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const DownloadScreen = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const intl = useIntl();
  const param = useParams();

  useEffect(() => {
    if (param.id) {
      getInvoiceList({ uuid: param.id }).then((response) => {
        setInvoices(response.invoices);
      });
    }
  }, [param.id]);

  const generateInvoicePDF = async (invoice) => {
    // if(invoice.original_ref){

    // }else{
    try {
      const response = await fetch(
        `https://generator-dta.invoiceria.com/?code=${invoice.encrypted}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const pdfBlob = await response.blob();
      return pdfBlob;
    } catch (error) {
      console.error("Error generating PDF:", error);
      throw error;
    }
    // }
  };

  const handleDownloadAll = async () => {
    const zip = new JSZip();
    setLoading(true);
    setProgress(0);

    let cumulativeProgress = 0;

    await Promise.all(
      invoices.map(async (invoice, index) => {
        try {
          const pdfBlob = await generateInvoicePDF(invoice);
          zip.file(
            `${invoice.invoice_name}_${invoice.invoice_id}.pdf`,
            pdfBlob,
            { binary: true }
          );

          const progressIncrement =
            ((index + 1) / invoices.length) * 100 - cumulativeProgress;
          cumulativeProgress += progressIncrement;
          setProgress(cumulativeProgress);
        } catch (error) {
          console.error("Error generating PDF for invoice:", invoice, error);
        }
      })
    );

    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, `invoiceria_${new Date().toDateString()}.zip`);
        setLoading(false);
        setProgress(0); // Reset progress after completion
      })
      .catch((error) => {
        console.error("Error generating ZIP file:", error);
        setLoading(false);
        setProgress(0); // Reset progress in case of error
      });
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Text
          style={{ fontWeight: 900, fontSize: 30 }}
          variant="gradient"
          gradient={{ from: "red", to: "yellow" }}
        >
          {intl.formatMessage({ id: "download_invoices_screen" })}
        </Text>
      </div>

      {loading && (
        <div style={{ marginTop: 20 }}>
          <Progress
            striped
            size="sm"
            animated
            color="yellow"
            value={progress}
            label={`${Math.round(progress)}%`}
          />
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Button onClick={handleDownloadAll} variant="outline" loading={loading}>
          {intl.formatMessage({ id: "download_all_as_zip" })}
        </Button>
      </div>
    </div>
  );
};

export default DownloadScreen;
