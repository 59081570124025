import { apiFetch, handleResponse, url } from ".";
import { getBusinessID, getToken } from "../tokens";
const i = "invoices/";
export const createInvoice = (data) => apiFetch(`${i}create-invoice`, data);
export const getInvoices = (data) => apiFetch(`${i}get-all`, data);
export const getInvoiceLogos = () => apiFetch(`${i}get-logos`);
export const issueInvoice = (data) => apiFetch(`${i}issue-invoice`, data);
export const getSingleInvoice = (data) => apiFetch(`${i}get`, data);
export const voidInvoice = (data) => apiFetch(`${i}set-void`, data);
export const archivenvoice = (data) => apiFetch(`${i}set-archive`, data);
export const extendInvoice = (data) => apiFetch(`${i}set-extend`, data);
export const setInvoicePaid = (data) => apiFetch(`${i}set-paid`, data);
export const partialInvoice = (data) => apiFetch(`${i}set-partial`, data);
export const saveInvoiceName = (data) => apiFetch(`${i}save-name`, data);
export const modifyTags = (data) => apiFetch(`${i}modify-tags`, data);
export const getInvoiceNames = () => apiFetch(`${i}get-names`);
export const setInvoiceSeen = (data) => apiFetch(`${i}set-seen`, data);
export const getInvoiceTags = () => apiFetch(`${i}get-tags`);
export const getInvoiceLastName = () =>
  apiFetch(`${i}get-last-used-invoice-name`);
export const saveInvoiceList = (data) =>
  apiFetch(`${i}save-download-data`, data);
export const getInvoiceList = (data) =>
  apiFetch(`${i}get-invoice-download-data`, data);

export const uploadLogoAPI = (file) => {
  const token = getToken();
  const formData = new FormData();
  const business_id = getBusinessID();
  formData.append("file", file);

  return fetch(url + "upgo-route/upload-logo", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
      Business: `${business_id}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};

export const getInvoiceLastLogo = () => apiFetch(`${i}get-last-used-logo`);
