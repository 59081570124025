import classes from "./SupportScreen.module.css";
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Container,
  Accordion,
  ThemeIcon,
  rem,
} from "@mantine/core";
import { useState } from "react";
import { useIntl } from "react-intl";
import { submitSupportTicket } from "../../functions/api/systemApi";

export const SupportScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const intl = useIntl();
  const [win, setWin] = useState(false);

  const submitForm = () => {
    submitSupportTicket({ name, email, subject, msg }).then(() => {
      setWin(true);
    });
  };
  return (
    <>
      <div>
        <Container size="sm" className={classes.wrapper}>
          <Title ta="center" className={classes.title}>
            Biežāk <span style={{ color: "red" }}>Uzdotie</span> Jautājumi
          </Title>

          <Accordion variant="separated">
            <Accordion.Item className={classes.item} value="language">
              <Accordion.Control>Can I change the language?</Accordion.Control>
              <Accordion.Panel>
                The app is currently in only one language due to location, but
                we are working on implementing more languages to make it more
                accessible!
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="template">
              <Accordion.Control>
                Vai es varu nomainīt rēķina izskatu?
              </Accordion.Control>
              <Accordion.Panel>
                Mēs šobrīd cītīgi strādājam pie tā, lai varētu piedāvāt iespēju
                lietotājiem gan izveidot savu rēķinu dizainu, gan izvēlēties no
                jau esošām sagatavēm
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          {!win ? (
            <div style={{ marginTop: 50 }}>
              <Title
                order={2}
                size="h1"
                style={{
                  fontFamily: "Greycliff CF, var(--mantine-font-family)",
                }}
                fw={900}
                ta="center"
              >
                {intl.formatMessage({ id: "report_problem" })}
              </Title>

              <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
                <TextInput
                  label={intl.formatMessage({ id: "name" })}
                  placeholder={intl.formatMessage({ id: "name" })}
                  name="name"
                  variant="filled"
                  value={name}
                  onChange={(x) => setName(x.target.value)}
                />
                <TextInput
                  label={intl.formatMessage({ id: "email" })}
                  placeholder={intl.formatMessage({ id: "email" })}
                  name="email"
                  variant="filled"
                  value={email}
                  onChange={(x) => setEmail(x.target.value)}
                />
              </SimpleGrid>

              <TextInput
                label={intl.formatMessage({ id: "subject" })}
                placeholder={intl.formatMessage({ id: "subject" })}
                mt="md"
                name="subject"
                variant="filled"
                value={subject}
                onChange={(x) => setSubject(x.target.value)}
              />
              <Textarea
                mt="md"
                label={intl.formatMessage({ id: "message" })}
                placeholder={intl.formatMessage({ id: "your_problem" })}
                maxRows={10}
                minRows={5}
                autosize
                name="message"
                variant="filled"
                value={msg}
                onChange={(x) => setMsg(x.target.value)}
              />

              <Group justify="center" mt="xl">
                <Button type="submit" size="md" onClick={() => submitForm()}>
                  {intl.formatMessage({ id: "send" })}
                </Button>
              </Group>
            </div>
          ) : (
            <div>
              <Title order={2} ta="center" style={{marginTop: 70}}>
                {intl.formatMessage({ id: "support_sent" })}
              </Title>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};
