import { apiFetch } from ".";
const mb = "mailbox/";

export const getUserEmailApi = () => apiFetch(`${mb}get-user-email`);
export const getUserInboxApi = () => apiFetch(`${mb}get-inbox`);
export const checkIfEmailAvailable = (data) =>
  apiFetch(`${mb}check-if-available`, data);
export const saveUsersEmailApi = (data) =>
  apiFetch(`${mb}save-email-addr`, data);
export const setEmailAsSeen = (data) => apiFetch(`${mb}set-seen`, data);
