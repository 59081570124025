import { Button, Card, Grid, Text, TextInput, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getPersonalCredentials } from "../../functions/api/credentialsApi";
import { getSelfUserData } from "../../functions/api/userApi";
import { setBusinessID, setSelectedBusiness } from "../../functions/tokens";
import { EditBusinessNameModal } from "../../Components/Modals/Business/EditBusinessNameModal";
import { useClipboard } from "@mantine/hooks";
import { Clipboard, ClipboardCheck } from "tabler-icons-react";
import {
  DefaultValueCard,
  LanguageSwitchMng,
  SettingsCredentialManagement,
  SettingsUploadPictureFrame,
} from "./fn";
import { getAllUserBusinesses } from "../../functions/api/businessApi";

export const SettingsScreen = () => {
  const [credentials, setCredentials] = useState([]);
  const [self, setSelf] = useState([]);
  const [change, setChange] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [bModal, setBModal] = useState(false);
  const clipboard = useClipboard({ timeout: 1500 });
  const [business, setBusiness] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    getSelfUserData().then((x) => {
      setSelf(x);
      setImgLoading(false);
    });
    getPersonalCredentials().then((x) => {
      setCredentials(x);
    });
    getAllUserBusinesses().then((x) => {
      setBusiness(x.results);
    });
  }, []);

  const onClickChangeProfile = (item) => {
    setBusinessID(item.business_id);
    setSelectedBusiness(item);
    window.location.reload();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChange(true);
    setSelf((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <EditBusinessNameModal open={bModal} setOpen={setBModal} />
      <Title order={4} style={{ marginBottom: 10 }}>
        {intl.formatMessage({ id: "settings" })}
      </Title>
      <small style={{ marginTop: -5, marginBottom: 5 }}>
        <TextInput
          style={{ maxWidth: 330 }}
          rightSectionPointerEvents="cursor"
          size="xs"
          description={intl.formatMessage({ id: "uuid_to_be_found_by" })}
          rightSectionWidth={"auto"}
          rightSection={
            <Button
              size="xs"
              color={clipboard.copied ? "teal" : "blue"}
              onClick={() => clipboard.copy(self.uuid)}
              title={intl.formatMessage({ id: "copy_to_clipboard" })}
            >
              {clipboard.copied ? (
                <ClipboardCheck size={20} />
              ) : (
                <Clipboard size={20} />
              )}
            </Button>
          }
          value={self.uuid}
        />
      </small>
      {credentials && credentials.length == 0 && (
        <span style={{ color: "red", marginBottom: 10 }}>
          {intl.formatMessage({ id: "set_your_credentials_to_start" })}
        </span>
      )}

      <SettingsUploadPictureFrame
        self={self}
        setSelf={setSelf}
        setImgLoading={setImgLoading}
        imgLoading={imgLoading}
      />

      <LanguageSwitchMng />

      <SettingsCredentialManagement />

      <DefaultValueCard />

      {business.length > 0 && (
        <Card withBorder style={{ marginBottom: 10, marginTop: 10 }}>
          <Title order={4}>
            {intl.formatMessage({ id: "switch_to_business_account" })}
          </Title>
          <Text style={{ marginTop: 5, marginBottom: 5 }}>
            {intl.formatMessage({ id: "switch_to_biz_account_info" })}
          </Text>
          {business.map((biz) => (
            <div style={{ marginTop: 5, marginBottom: 5 }}>
              <Button fullWidth onClick={() => onClickChangeProfile(biz)}>
                {biz.business_name}
              </Button>
            </div>
          ))}
        </Card>
      )}
      <Card withBorder style={{ marginBottom: 10, marginTop: 10 }}>
        <Title order={4}>
          {intl.formatMessage({ id: "create_business_account" })}
        </Title>
        <Text style={{ marginTop: 5, marginBottom: 5 }}>
          {intl.formatMessage({ id: "create_business_account_info" })}
        </Text>
        <Button onClick={() => setBModal(true)}>
          {intl.formatMessage({ id: "create_business_account_cta" })}
        </Button>
      </Card>

      <div style={{ marginTop: 10 }}>
        <Card withBorder>
          <Title order={5}>{intl.formatMessage({ id: "subscription" })}</Title>
          <small>{intl.formatMessage({ id: "coming_eventually" })}</small>
        </Card>
      </div>
      <div style={{ marginTop: 10 }}>
        <Card withBorder>
          <Title order={5}>{intl.formatMessage({ id: "my_data" })}</Title>
          <Grid>
            <Grid.Col span={{ xs: 6 }}>
              <TextInput
                name="name"
                onChange={handleChange}
                value={self?.name || ""}
                label={intl.formatMessage({ id: "name" })}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 6 }}>
              <TextInput
                name="surname"
                onChange={handleChange}
                value={self?.surname || ""}
                label={intl.formatMessage({ id: "surname" })}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 6 }}>
              <TextInput
                disabled
                name="email"
                onChange={handleChange}
                value={self?.email || ""}
                label={intl.formatMessage({ id: "email" })}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 6 }}>
              <TextInput
                name="phone"
                onChange={handleChange}
                value={self?.phone || ""}
                label={intl.formatMessage({ id: "phone" })}
              />
            </Grid.Col>
            <Grid.Col>{/* password change */}</Grid.Col>
            {change && (
              <Grid.Col span={{ xs: 12 }}>
                <Grid.Col span={{ xs: 6 }}>
                  <Button fullWidth>
                    {intl.formatMessage({ id: "save" })}
                  </Button>
                </Grid.Col>
              </Grid.Col>
            )}
          </Grid>
        </Card>
      </div>
      <div style={{ marginTop: 20 }}>
        <Card withBorder>
          <Title order={5}>
            {intl.formatMessage({ id: "delete_account" })}
          </Title>
          <small>{intl.formatMessage({ id: "action_irreversible" })}</small>
          <Grid>
            <Grid.Col span={{ xs: 12 }}>
              <Grid.Col span={{ xs: 6 }}>
                <Button
                  fullWidth
                  color="red"
                  onClick={() =>
                    window.alert(
                      intl.formatMessage({
                        id: "action_currently_not_possible",
                      })
                    )
                  }
                >
                  {intl.formatMessage({ id: "delete_account" })}
                </Button>
              </Grid.Col>
            </Grid.Col>
          </Grid>
        </Card>
      </div>
    </>
  );
};
