import { Grid, LoadingOverlay, Modal, Table, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { getSingleInvoice } from "../../functions/api/invoiceApi";
import { useIntl } from "react-intl";
import UserCard from "../UserCard";
import { Link } from "tabler-icons-react";

export const InvoiceDetailsModal = ({ open, setOpen, modalData }) => {
  const [invoice, setInvoice] = useState([]);
  const [services, setServices] = useState([]);
  const [issuedBy, setIssuedBy] = useState([]);
  const [issuedFor, setIssuedFor] = useState([]);
  const [forUser, setForUser] = useState([]);
  const [byUser, setByUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const intl = useIntl();

  useEffect(() => {
    setLoading(true);
    if (open) {
      const retrieveInvoice = () => {
        getSingleInvoice({ invoice_code: modalData?.encrypted }).then(
          (data) => {
            setInvoice(data);
            setServices(JSON.parse(data.services_json));
            setIssuedBy(JSON.parse(data.issuedby_json));
            setIssuedFor(JSON.parse(data.issuedfor_json));

            if (data.for_user && data.for_user[0]) {
              setForUser(data.for_user[0]);
            }
            if (data.by_user && data.by_user[0]) {
              setByUser(data.by_user[0]);
            }
          }
        );
        setLoading(false);
      };
      retrieveInvoice();
    } else {
      setInvoice([]);
      setIssuedBy([]);
      setIssuedFor([]);
      setForUser([]);
      setByUser([]);
    }
  }, [open]);

  return (
    <Modal
      size="xl"
      title={`${intl.formatMessage({ id: "invoice_nr" })}: ${
        invoice?.invoice_name
      }`}
      opened={open}
      onClose={setOpen}
      style={{ position: "relative" }}
    >
      <LoadingOverlay
        visible={loading}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{ color: "pink", type: "bars" }}
      />
      <div
        style={{
          marginTop: -7,
          marginBottom: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <small>
            {invoice.paid_date ? (
              <strong>{intl.formatMessage({ id: "paid_on" })}</strong>
            ) : (
              intl.formatMessage({ id: "due_date" })
            )}
            :{" "}
            {invoice.paid_date
              ? new Date(invoice.paid_date).toLocaleDateString()
              : invoice.extended_due
              ? new Date(invoice.extended_due).toLocaleDateString()
              : new Date(invoice.due_date).toLocaleDateString()}
          </small>
        </div>
        <a
          href={`invoice/${modalData?.encrypted}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Link size={15} style={{ marginBottom: -2 }} />{" "}
          {intl.formatMessage({ id: "more_info" })}
        </a>
      </div>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={4} title={intl.formatMessage({ id: "created_on" })}>
            {intl.formatMessage({ id: "issued_by" })}{" "}
            <small
              style={{
                color: "gray",
                fontSize: 12,
                marginLeft: 5,
              }}
            >
              ({new Date(invoice.created_on).toLocaleDateString()})
            </small>
          </Title>
          {byUser && byUser.userid && (
            <UserCard
              data={byUser}
              config={{
                padding: 8,
                showApprovalStatus: false,
                showCredentials: false,
                showButtons: false,
                showPointer: false,
              }}
            />
          )}
          <Table withTableBorder verticalSpacing={1}>
            <Table.Tbody>
              {Object.entries(issuedBy).map(([key, value]) => (
                <Table.Tr>
                  <Table.Td>
                    <strong>{intl.formatMessage({ id: key })}</strong>
                  </Table.Td>
                  <Table.Td>{value}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={4}>{intl.formatMessage({ id: "issued_for" })}</Title>
          {forUser && forUser.userid && (
            <UserCard
              data={forUser}
              config={{
                padding: 8,
                showApprovalStatus: false,
                showCredentials: false,
                showButtons: false,
                showPointer: false,
              }}
            />
          )}
          <Table withTableBorder verticalSpacing={1}>
            <Table.Tbody>
              {Object.entries(issuedFor).map(
                ([key, value]) =>
                  key != "priv" && (
                    <Table.Tr>
                      <Table.Td>
                        <strong>{intl.formatMessage({ id: key })}</strong>
                      </Table.Td>
                      <Table.Td>{value}</Table.Td>
                    </Table.Tr>
                  )
              )}
            </Table.Tbody>
          </Table>
        </Grid.Col>
      </Grid>
      <div style={{ marginTop: 10 }}>
        <Table withRowBorders={false} verticalSpacing={1}>
          <Table.Tbody>
            {invoice.total_novat && invoice.total_novat > 0 && (
              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>{intl.formatMessage({ id: "subtotal" })}</strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {invoice.total_novat}€
                </Table.Td>
              </Table.Tr>
            )}
            {invoice.vat_perc && invoice.vat_perc > 0 && (
              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>{intl.formatMessage({ id: "tax_rate" })}</strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {" "}
                  {invoice.vat_perc}%
                </Table.Td>
              </Table.Tr>
            )}

            {invoice.vat_value && invoice.vat_value > 0 && (
              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>{intl.formatMessage({ id: "tax" })}</strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {" "}
                  {invoice.vat_value}
                </Table.Td>
              </Table.Tr>
            )}

            {invoice.discount && (
              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>
                    {intl.formatMessage({ id: "subtotal_no_disc" })}
                  </strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {invoice.total_vat}€
                </Table.Td>
              </Table.Tr>
            )}
            {invoice.discount && (
              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>{intl.formatMessage({ id: "discount" })}</strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {invoice.discount}%
                </Table.Td>
              </Table.Tr>
            )}

            {invoice.paid_amount && (
              <Table.Tr>
                <Table.Td style={{ textAlign: "right", width: 130 }}>
                  <strong>
                    {intl.formatMessage({ id: "already_paid_amount" })}
                  </strong>
                </Table.Td>
                <Table.Td style={{ textAlign: "left" }}>
                  {invoice.paid_amount}€
                </Table.Td>
              </Table.Tr>
            )}

            <Table.Tr>
              <Table.Td style={{ textAlign: "right", width: 130 }}>
                <strong>{intl.formatMessage({ id: "total_due" })}</strong>
              </Table.Td>
              <Table.Td style={{ textAlign: "left" }}>
                {(
                  invoice.total_vat *
                    (invoice.discount ? 1 - invoice.discount / 100 : 1) -
                  (invoice.paid_amount ? invoice.paid_amount : 0)
                ).toFixed(2)}
                €
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </div>
    </Modal>
  );
};
