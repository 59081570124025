import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getLanguage, getToken } from "./functions/tokens";
import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/tiptap/styles.css";
import MainContainer from "./Components/MainContainer";
import { Logout } from "./Screens/LogoutScreen";
import { getBrowserLocale } from "./functions/helpers";
import { routesLoggedIn, routesLoggedOut } from "./routes";

// https://images.unsplash.com/photo-1717501219621-7b860d789a2e?q=15&w=560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
// https://images.unsplash.com/photo-1667842474031-8a0ba604df48?q=15&w=577&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D

function App() {
  const [isLoggedIn, setLoggedin] = useState(getToken());
  const navigation = useNavigate();

  const [locale, setLocale] = useState(
    getLanguage() || getBrowserLocale() || "lv-LV"
  );

  const RedirectWrapper = () => (
    <Navigate to={isLoggedIn ? "/main" : "/login"} />
  );

  useEffect(() => {
    setLoggedin(getToken());
  }, [navigation]);

  const LoggedInRoutes = () => (
    <MainContainer>
      <Routes>
        {routesLoggedIn.map((route, index) => {
          return (
            <Route
              key={`l-${index}`}
              exct={route.exct}
              path={route.path}
              element={route.element}
            >
              {route.children &&
                route.children.map((child, childIndex) => (
                  <Route
                    key={childIndex}
                    path={child.path}
                    element={child.element}
                  />
                ))}
            </Route>
          );
        })}
        <Route
          exct
          path="/logout"
          element={<Logout setLoggedin={setLoggedin} />}
        />

        <Route path="*" element={<RedirectWrapper />} />
      </Routes>
    </MainContainer>
  );

  const UnauthorisedRoutes = () => (
    <Routes>
      {routesLoggedOut.map((route, index) => (
        <Route
          key={index}
          exct={route.exct}
          path={route.path}
          element={route.element}
        />
      ))}
      <Route path="*" element={<RedirectWrapper />} />
    </Routes>
  );

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={"en-GB"}
    >
      {isLoggedIn ? <LoggedInRoutes /> : <UnauthorisedRoutes />}
    </IntlProvider>
  );
}

export default App;
