import {
  Button,
  Card,
  Checkbox,
  Grid,
  Loader,
  LoadingOverlay,
  Select,
  Table,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  getTemplateByUUID,
  saveTemplateApi,
} from "../../functions/api/templateApi";
import UserCard from "../../Components/UserCard";
import { getFriendCredentialsById } from "../../functions/api/userApi";
import DataTable from "../../Components/DataTable";
import { TotalsHolder } from "../CreateInvoiceScreen/CreateComponents";
import TextEditor from "../../Components/TextEditor";
import { replacePlaceholders } from "../../Components/Modals/EditInvoiceNrModal";
import { getColumnsConfig, getDueDateOptions } from "./fn";
import {
  useAmountDue,
  useIndividualSubTotalNoVat,
  useSubtotal,
} from "../CreateInvoiceScreen/fn";

export const TemplateScreen = () => {
  const intl = useIntl();

  const [template, setTemplate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [issuedBy, setIssuedBy] = useState([]);
  const [byUser, setByUser] = useState([]);
  const [services, setServices] = useState([]);

  const [emailContent, setEmailContent] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemplate((prevState) => ({
      ...prevState,
      ...prevState.data,
      [name]: value,
    }));
  };

  const theme = useMantineTheme();

  const param = useParams();

  const individualSubTotalNoVat = useIndividualSubTotalNoVat(services);
  const subtotal = useSubtotal(services, template);
  const amountDue = useAmountDue(subtotal, template);

  const fetchInvoice = () => {
    setLoading(true);
    getTemplateByUUID({ tuuid: param.id }).then((x) => {
      if (x.length > 0) {
        setTemplate(x[0]);
        setIssuedBy(JSON.parse(x[0].issuedby_json));
        setServices(JSON.parse(x[0].services_json));
        if (x[0].by_user_id && x[0].by_user_id > 0) {
          getFriendCredentialsById({ id: x[0].by_user_id }).then((y) => {
            setByUser(y[0]);
          });
        }

        setLoading(false);
      } else {
        window.location.href = "/template-invoices";
      }
    });
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  useEffect(() => {
    if (template.mail_text) {
      setEmailContent(template.mail_text);
    } else {
      setEmailContent(
        `<p>${intl.formatMessage({
          id: "invoice_attached",
        })}</p></br><p><a href="https://invoiceria.com">${intl.formatMessage({
          id: "sent_from_eazybill",
        })}</a></p>`
      );
    }
  }, [template]);

  const saveTemplate = () => {
    saveTemplateApi({ template, emailContent }).then((x) => fetchInvoice());
  };

  return (
    <div
      style={{
        marginTop: 20,
        position: "relative",
        height: loading ? 900 : "auto",
      }}
    >
      <LoadingOverlay
        visible={loading}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{ color: "pink", type: "bars" }}
      />
      <Text size="xl" fw={700}>
        {intl.formatMessage({ id: "edit_template_invoice" })}
      </Text>
      <hr />

      <Card withBorder style={{ marginBottom: 10 }}>
        <TextInput
          name="template_name"
          label={intl.formatMessage({ id: "template_name" })}
          value={template.template_name}
          onChange={handleChange}
        />
        <TextInput
          name="template_description"
          label={intl.formatMessage({ id: "template_description" })}
          value={template.template_description}
          onChange={handleChange}
        />
        <Select
          value={template.template_deadline}
          name="template_deadline"
          onChange={(x) =>
            handleChange({ target: { name: "template_deadline", value: x } })
          }
          label={intl.formatMessage({ id: "due_date_settings" })}
          data={getDueDateOptions(intl)}
        />
        <TextInput
          name="template_mail_subject"
          label={intl.formatMessage({ id: "email_subject" })}
          value={template.template_mail_subject}
          onChange={handleChange}
        />
        <div style={{display: 'flex', marginTop: 10, gap: 10, alignItems: 'center'}}>
        <Checkbox
          checked={template.home_screen}
          name="home_screen"
          onChange={(e) =>
            handleChange({
              target: {
                name: "home_screen",
                value: template.home_screen ? false : true,
              },
            })
          }
        />
        {intl.formatMessage({id: 'pin_to_start'})}
        </div>
        <span
          style={{
            fontWeight: 500,
            marginTop: 7,
            marginBottom: 7,
            fontSize: 14,
          }}
        >
          {intl.formatMessage({ id: "email_content" })}
        </span>
        {emailContent ? (
          <TextEditor
            value={emailContent}
            onChange={(x) => setEmailContent(x)}
          />
        ) : (
          <Loader />
        )}
        <Button style={{ marginTop: 20 }} onClick={() => saveTemplate()}>
          {intl.formatMessage({ id: "save" })}
        </Button>
      </Card>

      <Card withBorder>
        <span style={{ position: "absolute", right: -35, top: 10 }}>
          {/* <Button size="xs" onClick={() =>}>
            {intl.formatMessage({ id: "edit_template_base" })}
          </Button> */}
        </span>
        <div style={{ marginTop: 20 }}></div>
        <div style={{ display: "flex", gap: 5 }}>
          <strong>{intl.formatMessage({ id: "example_invoice_name" })}:</strong>{" "}
          {template.or_invoice_name}
        </div>
        <div style={{ display: "flex", gap: 5 }}>
          <strong>{intl.formatMessage({ id: "invoice_name_type" })}:</strong>{" "}
          {template.invoice_type}
        </div>
        <div style={{ display: "flex", gap: 5 }}>
          <strong>{intl.formatMessage({ id: "current_invoice_name" })}:</strong>{" "}
          {replacePlaceholders(template.invoice_type, template.invoice_index)}
        </div>
        <Grid>
          <Grid.Col span={{ base: 12 }}>
            <Title order={4}>{intl.formatMessage({ id: "issued_by" })}</Title>
            {byUser && byUser.userid && (
              <UserCard
                data={byUser}
                config={{
                  padding: 8,
                  showApprovalStatus: false,
                  showCredentials: false,
                  showButtons: false,
                  showPointer: false,
                  showIssueButton: true,
                }}
              />
            )}
            <Table withTableBorder verticalSpacing={1}>
              <Table.Tbody>
                {Object.entries(issuedBy).map(([key, value]) => (
                  <Table.Tr>
                    <Table.Td>
                      <strong>{intl.formatMessage({ id: key })}</strong>
                    </Table.Td>
                    <Table.Td>{value}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Grid.Col>
        </Grid>
        <Text fw={700} size="lg" style={{ marginBottom: -15, marginTop: 15 }}>
          {intl.formatMessage({ id: "services" })}
        </Text>
        <DataTable data={services} columns={getColumnsConfig(template)} />
        <div style={{ margin: 10 }}>
          <TotalsHolder
            individualVat={template.vat_individual}
            individualSubTotalNoVat={individualSubTotalNoVat}
            subtotal={subtotal}
            taxRate={template.vat_perc}
            discount={Number(template.discount)}
            amountDue={amountDue}
          />
        </div>
        {template.note && (
          <Card withBorder>
            <strong>{intl.formatMessage({ id: "public_notes" })}:</strong>
            {template.note}
          </Card>
        )}
      </Card>
    </div>
  );
};
