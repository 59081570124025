import { Button, Modal, TextInput, Title } from '@mantine/core';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { createBusinessAccount } from '../../../functions/api/businessApi';

export const EditBusinessNameModal = ({ open, setOpen }) => {
  const [name, setName] = useState('');
  const intl = useIntl();

  const setUpBusiness = async () => {
    const resp = await createBusinessAccount({ business_name: name });
    if (resp) {
      window.alert(intl.formatMessage({id: "biz_account_created"}));
      window.location.reload();
    }
  };

  return (
    <Modal
      opened={open}
      onClose={() => setOpen(false)}
      title={intl.formatMessage({ id: 'set_business_name' })}
    >
      <div  style={{padding: 10, paddingTop: 0}}>
      <small>{intl.formatMessage({ id: 'create_biz_acc_info' })}</small>
      </div>
      <TextInput
        onChange={(x) => setName(x.target.value)}
        value={name}
        style={{ marginBottom: 20 }}
      />
      <Button onClick={setUpBusiness}>
        {intl.formatMessage({ id: 'create_business_account_cta' })}
      </Button>
    </Modal>
  );
};
