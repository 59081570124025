import { FormattedMessage } from 'react-intl';
import {
  removeBusinessID,
  removeCallToken,
  removeData,
  removeGroup,
  removeSelectedBusiness,
  removeToken,
  removeUserId,
} from '../functions/tokens';
import { Text } from '@mantine/core';
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isValidEmail(email) {
  return emailRegex.test(email);
}

export function getBrowserLocale() {
  switch (navigator.language) {
    case 'zh':
    case 'zh-Hant':
    case 'zh-Hans':
    case 'zh-TW':
    case 'zh-HK':
    case 'zh-CN':
      return 'lv-LV';
    default:
      return 'lv-LV';
  }
}

export const clearData = () => {
  removeToken();
  removeGroup();
  removeData();
  removeUserId();
  removeCallToken();
  removeBusinessID();
  removeSelectedBusiness();
};

export const featureData = {
  image:
    'https://images.unsplash.com/photo-1553484771-371a605b060b?q=40&w=1340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  title: 'main_page_feature_title',
  status: 'main_page_feature_status',
  description: 'main_page_feature_description',
};

export const heroData = {
  img: 'https://images.unsplash.com/photo-1519501025264-65ba15a82390?q=30&w=1464&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  title: (
    <FormattedMessage
      id="main_page_hero_title"
      values={{
        highlight: (chunks) => (
          <Text
            component="span"
            inherit
            variant="gradient"
            gradient={{ from: 'pink', to: 'yellow' }}
          >
            {chunks}
          </Text>
        ),
        endHighlight: '', // Optional, if you want to close the highlight span.
      }}
    />
  ),
  description: 'main_page_hero_description',
  cta: true,
  cta_title: 'main_page_hero_cta',
  cta_action: () => {
    window.location.href = '/settings';
  },
};
