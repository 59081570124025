import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import HeroCard from "../../Components/HeroCard";
import {
  Button,
  Card,
  LoadingOverlay,
  Modal,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { IconAt, IconCheck, IconCopy } from "@tabler/icons-react";
import {
  checkIfEmailAvailable,
  getUserEmailApi,
  getUserInboxApi,
  saveUsersEmailApi,
  setEmailAsSeen,
} from "../../functions/api/mailboxApi";
import { useClipboard } from "@mantine/hooks";
import { FileDescription } from "tabler-icons-react";

function getTextAfterFirstUnderscore(str) {
  const parts = str.split("_");
  if (parts.length > 1) {
    return parts.slice(1).join("_");
  } else {
    return ""; // Or return str if you want to return the original string if no underscore is found
  }
}

export const EmailInvoiceScreen = () => {
  const intl = useIntl();

  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const clipboard = useClipboard();
  const [openSelect, setOpenSelect] = useState(false);
  const [selected, setSelected] = useState([]);

  const openEmailModal = (item) => {
    if (item && item.seen == false) {
      setEmailAsSeen({ emailid: item.ueiid });
    }

    setOpenSelect(true);
    setSelected(item);
  };

  useEffect(() => {
    setLoading(true);
    getUserEmailApi().then((x) => {
      if (x.email && x.email != "") {
        setEmail(x.email);
        getUserInboxApi().then((y) => {
          setEmails(y);
        });
      } else {
        setEmail("");
      }
      setLoading(false);
    });
  }, []);

  const saveEmailAddr = () => {
    if (error == null) {
      checkIfEmailAvailable({
        email: `${first}-${second}@invoiceria.com`,
      }).then((x) => {
        if (x.email != 0) {
          setError(intl.formatMessage({ id: "email_already_taken" }));
        } else {
          saveUsersEmailApi({
            email: `${first}-${second}@invoiceria.com`,
          }).then((y) => {
            setEmail(y[0].email_add);
            setModal(false);
          });
        }
      });
    }
  };

  useEffect(() => {
    const cleanedFirst = first.replace(/[^a-z0-9]/gi, "").toLowerCase();
    const cleanedSecond = second.replace(/[^a-z0-9]/gi, "").toLowerCase();
    setFirst(cleanedFirst);
    setSecond(cleanedSecond);
    setError(null);
    if (cleanedFirst.length < 3) {
      setError(intl.formatMessage({ id: "first_part_too_short" }));
    }
    if (cleanedSecond.length < 3) {
      setError(intl.formatMessage({ id: "second_part_too_short" }));
    }
    if (cleanedFirst === "" || cleanedSecond === "") {
      setError(intl.formatMessage({ id: "email_parts_cant_be_empty" }));
    }
    if (error == null) {
      checkIfEmailAvailable({
        email: `${cleanedFirst}-${cleanedSecond}@invoiceria.com`,
      }).then((x) => {
        if (x.email !== 0) {
          setError(intl.formatMessage({ id: "email_already_taken" }));
        }
      });
    }
  }, [first, second]);

  const heroData = {
    img: "https://images.unsplash.com/photo-1713946598635-0c2a65e4ee08?q=30&w=1232&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: (
      <FormattedMessage
        id="email_page_hero_title"
        values={{
          highlight: (chunks) => (
            <Text
              component="span"
              inherit
              variant="gradient"
              gradient={{ from: "pink", to: "yellow" }}
            >
              {chunks}
            </Text>
          ),
          endHighlight: "",
        }}
      />
    ),
    description: "email_page_hero_description",
    cta: true,
    cta_title: "email_page_hero_cta",
    cta_action: () => {
      setModal(true);
    },
  };

  const HeadContainer = ({ item }) => {
    return (
      <div
        style={{
          fontSize: 11,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {intl.formatMessage({ id: "sent_from" })}: {item.email_from}
        </div>
        <div>
          {item.received ? new Date(item.received).toLocaleString() : ""}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        position: "relative",
        height: loading ? 900 : "auto",
      }}
    >
      <Modal
        opened={openSelect}
        onClose={() => setOpenSelect(false)}
        title={selected && <strong>{selected.subject}</strong>}
      >
        {selected && (
          <div>
            <div>
              <HeadContainer item={selected} />
              <div dangerouslySetInnerHTML={{ __html: selected.body }}></div>
            </div>
            {selected.invoices_ref && (
              <Card withBorder style={{ marginTop: 20, padding: 10 }}>
                {intl.formatMessage({ id: "invoiceria_invoices" })}
                <div style={{ display: "flex" }}>
                  {JSON.parse(selected.invoices_ref).map((lnk) => {
                    return (
                      <a target="_blank" href={`/invoice/${lnk}`} style={{ marginRight: 10 }}>
                        {lnk}
                      </a>
                    );
                  })}
                </div>
              </Card>
            )}
            {selected.attachment_url && (
              <Card withBorder style={{ marginTop: 20, padding: 10 }}>
                {intl.formatMessage({ id: "original_attachments" })}
                <div style={{ display: "flex" }}>
                  {JSON.parse(selected.attachment_url).map((lnk) => {
                    return (
                      <a target="_blank" href={lnk} style={{ marginRight: 10 }}>
                        {getTextAfterFirstUnderscore(lnk)}
                      </a>
                    );
                  })}
                </div>
              </Card>
            )}
          </div>
        )}
      </Modal>
      <Modal
        title={intl.formatMessage({ id: "set_up_system_email" })}
        opened={modal}
        onClose={() => setModal(false)}
      >
        <div style={{ marginBottom: 20, fontSize: 10 }}>
          {intl.formatMessage({ id: "email_invoice_system_disclaimer" })}
        </div>
        <div>
          {error && (
            <div style={{ color: "red", fontSize: 10, marginTop: -15 }}>
              {error}
            </div>
          )}
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextInput
              value={first}
              onChange={(e) => setFirst(e.target.value)}
              rightSectionPointerEvents="none"
              placeholder={intl.formatMessage({ id: "email_pl_name" })}
            />
            <span style={{ marginLeft: 5, marginRight: 5 }}> - </span>
            <TextInput
              value={second}
              onChange={(e) => setSecond(e.target.value)}
              rightSectionPointerEvents="none"
              placeholder={intl.formatMessage({ id: "email_pl_surname" })}
            />
            <span style={{ marginLeft: 5 }}>@invoiceria.com</span>
          </span>
        </div>
        {first && second && (
          <small style={{ color: error ? "red" : "green" }}>
            {first}-{second}@invoiceria.com
          </small>
        )}
        <Button
          fullWidth
          style={{ marginTop: 20 }}
          onClick={() => saveEmailAddr()}
        >
          {intl.formatMessage({ id: "create_invoice_email_mailbox" })}
        </Button>
      </Modal>
      <LoadingOverlay
        visible={loading}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{ color: "pink", type: "bars" }}
      />
      {email == "" && <HeroCard data={heroData} />}
      {email != "" && (
        <div>
          <Title order={3} style={{ marginBottom: 10 }}>
            {intl.formatMessage({ id: "invoice_emails_page" })}
          </Title>
          <Tooltip
            label={intl.formatMessage({ id: "email_copied" })}
            offset={5}
            position="bottom"
            radius="xl"
            transitionProps={{ duration: 100, transition: "slide-down" }}
            opened={clipboard.copied}
          >
            <Button
              variant="light"
              color="green"
              rightSection={
                clipboard.copied ? (
                  <IconCheck
                    style={{ width: rem(20), height: rem(20) }}
                    stroke={1.5}
                  />
                ) : (
                  <IconCopy
                    style={{ width: rem(20), height: rem(20) }}
                    stroke={1.5}
                  />
                )
              }
              radius="null"
              size="md"
              styles={{
                root: { paddingRight: rem(14), height: rem(48) },
                section: { marginLeft: rem(22) },
              }}
              onClick={() => clipboard.copy(email)}
            >
              {email}
            </Button>
          </Tooltip>
          <div style={{ marginTop: 20 }}>
            {emails &&
              emails.map((item) => {
                const attch = item.attachment_url
                  ? JSON.parse(item.attachment_url)
                  : null;
                return (
                  <Card
                    style={{
                      backgroundColor: item.seen ? "" : "#cccccc50",
                      cursor: "pointer",
                    }}
                    withBorder
                    shadow="xs"
                    onClick={() => {
                      openEmailModal(item);
                    }}
                  >
                    <HeadContainer item={item} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <strong>{item.subject}</strong>
                      <div>
                        {attch && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FileDescription
                              size={16}
                              style={{ marginRight: 5 }}
                            />
                            {attch.length}
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                );
              })}
            {email != "" && emails.length == 0 && (
              <Card withBorder shadow="xs">
                {intl.formatMessage({ id: "emails_share_get_started" })}
              </Card>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
