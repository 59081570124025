import { useEffect, useState } from "react";
import {
  changeBusinessUserStatus,
  getBusinessesData,
  getBusinessesUsers,
  sendBusinessInviteAPI,
} from "../../functions/api/businessApi";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  FileButton,
  Grid,
  Group,
  Loader,
  Modal,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { useIntl } from "react-intl";
import {
  bizPlaceholderImg,
  placeholderImg,
} from "../../Components/UserButton/AccountSelector";
import { setSelectedBusiness } from "../../functions/tokens";
import { EditInvoiceSubjectModal } from "../../Components/Modals/EditInvoiceSubjectModal";
import {
  getPersonalCredentials,
  publishPersonalCredentials,
} from "../../functions/api/credentialsApi";
import UserCard from "../../Components/UserCard";
import { isValidEmail } from "../../functions/helpers";
import { sendUserAnInviteAPI } from "../../functions/api/emailApi";
import { IconX } from "@tabler/icons-react";
import {
  searchUsersForFriends,
  uploadProfilePicAPI,
} from "../../functions/api/userApi";
import { useClipboard } from "@mantine/hooks";
import { Clipboard, ClipboardCheck } from "tabler-icons-react";
import {
  DefaultValueCard,
  SettingsUploadPictureFrame,
  handleCredentialSave,
} from "./fn";

export const BusinessSettingsScreen = () => {
  const [business, setBusiness] = useState([]);
  const [bizUsers, setBizUsers] = useState([]);
  const [selection, setSelection] = useState([]);
  const [imgLoading, setImgLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [userModal, setUserModal] = useState(false);
  const [modifyModal, setModifyModal] = useState([]);
  const clipboard = useClipboard({ timeout: 1500 });

  const intl = useIntl();

  useEffect(() => {
    getBusinessesData().then((x) => setBusiness(x));
    getBusinessesUsers().then((x) => setBizUsers(x));
    getPersonalCredentials().then((x) => setCredentials(x));
    setImgLoading(false);
  }, []);

  useEffect(() => {
    getBusinessesUsers().then((x) => setBizUsers(x));
    getPersonalCredentials().then((x) => setCredentials(x));
  }, [modalOpen, modifyModal]);

  const handleOpen = ({ data }) => {
    const preset = data ? JSON.parse(data) : null;
    const modalInitSettings = {
      type: "issuer",
      searchOff: true,
      hideEmail: true,
    };
    const toSet = {
      ...modalInitSettings,
      ...{
        data: { ...preset, priv: true, bussiness_name: business.business_name },
      },
    };
    setModalData(toSet);
    setModalOpen(true);
  };

  const toggleRow = (userid) =>
    setSelection((current) =>
      current.includes(userid)
        ? current.filter((item) => item !== userid)
        : [...current, userid]
    );
  const toggleAll = () =>
    setSelection((current) =>
      current.length === bizUsers.length
        ? []
        : bizUsers.map((item) => item.userid)
    );

  const publishCredentials = (cid) => {
    if (
      window.confirm(
        intl.formatMessage({ id: "confirm_publish_credentials" }) +
          intl.formatMessage({ id: "credentials_not_published_info" })
      )
    ) {
      publishPersonalCredentials({ cid }).then((x) => window.location.reload());
    }
  };

  const rows = bizUsers.map((item) => {
    const selected = selection.includes(item.userid);

    return (
      <Table.Tr
        key={item.userid}
        style={{
          textDecoration: item.b_status == "blocked" ? "line-through" : "",
          backgroundColor: selected ? "dodgerblue" : "",
        }}
      >
        <Table.Td>
          <Checkbox
            checked={selection.includes(item.userid)}
            onChange={() => toggleRow(item.userid)}
          />
        </Table.Td>
        <Table.Td>
          <Group gap="sm">
            <Avatar
              size={26}
              src={item.user_img ? item.user_img : placeholderImg}
              radius={26}
            />
            <Text size="sm" fw={500}>
              {item.name} {item.surname}
              {item.b_status == "blocked" && (
                <>
                  <br />
                  <Badge size="xs" color="red">
                    {intl.formatMessage({ id: "blocked" })}
                  </Badge>
                </>
              )}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>{item.email}</Table.Td>
        <Table.Td>
          {item.main ? (
            <Button disabled>
              {intl.formatMessage({ id: "business_main_acc" })}
            </Button>
          ) : (
            <Button onClick={() => setModifyModal(item)}>
              {intl.formatMessage({ id: "business_edit_user" })}
            </Button>
          )}
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <UserSearchModal modal={userModal} setModal={setUserModal} />
      <ModifyBusinessUser
        modalData={modifyModal}
        setModalData={setModifyModal}
      />
      <EditInvoiceSubjectModal
        modalData={modalData}
        modalOpen={modalOpen}
        handleDataSave={() => handleCredentialSave(credentials, modalData)}
        setModalData={setModalData}
        setModalOpen={setModalOpen}
      />

      <Title order={3}>{intl.formatMessage({ id: "business_settings" })}</Title>
      {/* <small style={{ color: 'gray' }}>{business.uuid}</small> */}
      <TextInput
        style={{ maxWidth: 330, marginBottom: 5 }}
        rightSectionPointerEvents="cursor"
        size="xs"
        rightSectionWidth={"auto"}
        rightSection={
          <Button
            size="xs"
            color={clipboard.copied ? "teal" : "blue"}
            onClick={() => clipboard.copy(business.uuid)}
            title={intl.formatMessage({ id: "copy_to_clipboard" })}
          >
            {clipboard.copied ? (
              <ClipboardCheck size={20} />
            ) : (
              <Clipboard size={20} />
            )}
          </Button>
        }
        value={business.uuid}
      />

      <SettingsUploadPictureFrame
        self={business}
        setSelf={setBusiness}
        setImgLoading={setImgLoading}
        imgLoading={imgLoading}
        isItBusiness={true}
      />
      <Title order={5} style={{ marginBottom: 5 }}>
        {intl.formatMessage({ id: "business_credentials" })}
      </Title>
      {credentials && credentials.length > 0 && (
        <div>
          <Grid>
            {credentials &&
              credentials.map((credential) => (
                <Grid.Col span={{ base: 12 }}>
                  <Card withBorder>
                    {credential.published == 0 && (
                      <div style={{ marginBottom: 10 }}>
                        <div style={{ color: "red" }}>
                          <Title order={5}>
                            {intl.formatMessage({
                              id: "credentials_not_published",
                            })}
                          </Title>
                          <small>
                            {intl.formatMessage({
                              id: "credentials_not_published_info",
                            })}
                          </small>
                        </div>
                      </div>
                    )}
                    {Object.entries(JSON.parse(credential.data)).map(
                      (x) =>
                        x[0] !== "priv" && (
                          <Grid key={x[0]}>
                            <Grid.Col span={{ base: 12 }} pl="5">
                              <strong>
                                {intl.formatMessage({ id: x[0] })}
                              </strong>
                              :{" "}
                            </Grid.Col>
                            <Grid.Col
                              span={{ base: 12 }}
                              pt="0"
                              style={{ marginTop: -10, marginBottom: 10 }}
                            >
                              <span>{x[1]}</span>
                            </Grid.Col>
                          </Grid>
                        )
                    )}
                    <div>
                      {credential.published == 0 && (
                        <Button
                          style={{ marginRight: 15, marginTop: 15 }}
                          onClick={() => handleOpen(credential)}
                        >
                          {intl.formatMessage({ id: "edit_credentials" })}
                        </Button>
                      )}
                      {credential.published == 0 ? (
                        <Button
                          style={{ marginTop: 15 }}
                          onClick={() => publishCredentials(credential.cid)}
                        >
                          {intl.formatMessage({ id: "publish_credentials" })}
                        </Button>
                      ) : (
                        <>
                          {/* <Button
                          style={{ marginTop: 15 }}
                          onClick={() => unpublishCredentials(credential.cid)}
                        >
                          {intl.formatMessage({
                            id: 'un_publish_credentials',
                          })}
                        </Button> */}
                        </>
                      )}
                    </div>
                  </Card>
                </Grid.Col>
              ))}
          </Grid>
        </div>
      )}
      <div style={{ marginTop: 20 }}>
        {credentials.length == 0 && (
          <Title order={6}>
            {intl.formatMessage({ id: "credentials_not_set" })}
          </Title>
        )}

        {(credentials.length == 0 || credentials[0]?.published == 1) && (
          <Button onClick={() => handleOpen({})}>
            {intl.formatMessage({ id: "set_credentials" })}
          </Button>
        )}
      </div>

      <DefaultValueCard />

      <div style={{ marginTop: 30 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title order={4}>
            {" "}
            {intl.formatMessage({ id: "business_participants" })}
          </Title>
          <Button onClick={() => setUserModal(true)} size="xs">
            {intl.formatMessage({ id: "add_user_to_biz" })}
          </Button>
        </div>
        <ScrollArea>
          <Table miw={800} verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: rem(40) }}>
                  <Checkbox
                    onChange={toggleAll}
                    checked={selection.length === business.length}
                    indeterminate={
                      selection.length > 0 &&
                      selection.length !== business.length
                    }
                  />
                </Table.Th>
                <Table.Th>{intl.formatMessage({ id: "name" })}</Table.Th>
                <Table.Th>{intl.formatMessage({ id: "email" })}</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </ScrollArea>
      </div>
    </>
  );
};

export const ModifyBusinessUser = ({ modalData, setModalData }) => {
  const intl = useIntl();

  const registerChanges = (action) => {
    changeBusinessUserStatus({ userid: modalData.userid, type: action });
    setModalData([]);
  };
  return (
    <Modal
      opened={modalData.userid != undefined}
      onClose={() => setModalData([])}
      title={intl.formatMessage({ id: "modify_biz_user" })}
    >
      <UserCard
        data={modalData}
        config={{
          padding: 8,
          showApprovalStatus: true,
          showCredentials: false,
          showButtons: false,
          showPointer: false,
          showIssueButton: false,
        }}
      />
      <div
        style={{
          display: "flex",
          marginTop: 20,
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{ backgroundColor: "red" }}
          onClick={() => registerChanges("remove")}
        >
          {intl.formatMessage({ id: "remove_user" })}
        </Button>
        <Button
          style={{
            backgroundColor: modalData.b_status == "blocked" ? "green" : "red",
          }}
          onClick={() =>
            registerChanges(
              modalData.b_status == "blocked" ? "unblock" : "block"
            )
          }
        >
          {modalData.b_status == "blocked"
            ? intl.formatMessage({ id: "unblock_user" })
            : intl.formatMessage({ id: "block_user" })}
        </Button>
      </div>
    </Modal>
  );
};

export const UserSearchModal = ({ modal, setModal }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const intl = useIntl();

  const sendUserAnInvite = () => {
    if (isValidEmail(searchValue)) {
      sendUserAnInviteAPI(searchValue).then(() => {
        window.alert(intl.formatMessage({ id: "invite_sent" }));
        setLoading(false);
        setSearchResults([]);
        setSearchValue("");
      });
    }
  };

  useEffect(() => {
    if (!searchValue.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    searchUsersForFriends({ search: searchValue })
      .then((data) => {
        setSearchResults(data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue]);

  const sendInvite = () => {
    sendBusinessInviteAPI(selected).then((x) => {
      setModal(false);
      setSelected([]);
    });
  };

  const renderUserSearch = (option) => {
    const showPointer =
      !option?.approved ||
      (option?.approved == 0 && option?.friend_data.friend_id != option.userid);
    return (
      <UserCard
        data={option}
        config={{
          padding: 8,
          showApprovalStatus: true,
          showCredentials: false,
          showButtons: false,
          showPointer: showPointer,
          showIssueButton: false,
          onClick: () => {
            setSelected(option);
          },
        }}
      />
    );
  };

  return (
    <Modal
      title={intl.formatMessage({ id: "add_user_to_biz" })}
      opened={modal}
      onClose={() => setModal(false)}
    >
      {selected.length == 0 ? (
        <div style={{}}>
          <TextInput
            label={intl.formatMessage({ id: "search_for_contact" })}
            placeholder={intl.formatMessage({ id: "search_for_contact" })}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            rightSection={
              loading ? (
                <Loader size="xs" />
              ) : (
                searchValue && (
                  <IconX
                    style={{ cursor: "pointer" }}
                    onClick={() => setSearchValue("")}
                  />
                )
              )
            }
          />
          <div style={{ zIndex: 2000 }}>
            {searchResults.length > 0 && (
              <Card p="0" shadow="md">
                {searchResults.map((user, index) => renderUserSearch(user))}
              </Card>
            )}
            {searchResults.length <= 4 && searchValue != "" && (
              <Card p="0" shadow="md">
                {isValidEmail(searchValue) ? (
                  <Alert
                    style={{
                      cursor: "pointer",
                      border: "0.5px solid lightgrey",
                    }}
                    color="green"
                    onClick={sendUserAnInvite}
                    title={intl.formatMessage({ id: "add_contact_didnt_find" })}
                  >
                    {intl.formatMessage(
                      { id: "add_contact_didnt_find_invite" },
                      { email: searchValue }
                    )}
                  </Alert>
                ) : (
                  <Alert
                    style={{ border: "0.5px solid lightgrey" }}
                    color="yellow"
                    title={intl.formatMessage({ id: "add_contact_didnt_find" })}
                  >
                    {intl.formatMessage({ id: "add_contact_valid_email" })}
                  </Alert>
                )}
              </Card>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Badge
            size="lg"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
            onClick={() => setSelected([])}
          >
            {selected.name} {selected.surname}{" "}
            <IconX size={18} style={{ marginBottom: -5 }} />
          </Badge>
          <UserCard
            data={selected}
            config={{
              padding: 8,
              showApprovalStatus: true,
              showCredentials: false,
              showButtons: false,
              showPointer: false,
              showIssueButton: false,
            }}
          />
          <Button onClick={sendInvite} style={{ marginTop: 20 }}>
            {intl.formatMessage({ id: "send_invite" })}
          </Button>
        </div>
      )}
    </Modal>
  );
};
