import { useState } from "react";
import { UnstyledButton, Menu, Group, Avatar, Text, rem } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import classes from "./UserButton.module.css";
import {
  getData,
  getSelectedBusiness,
  removeBusinessID,
  removeBusinessUserID,
  removeSelectedBusiness,
  setBusinessID,
  setBusinessUserID,
  setSelectedBusiness,
} from "../../functions/tokens";

export const bizPlaceholderImg =
  "https://images.unsplash.com/photo-1544502062-f82887f03d1c?q=40&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
export const placeholderImg =
  "https://images.unsplash.com/photo-1640960543409-dbe56ccc30e2?q=20&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

export function CombinedUserButton({ data }) {
  const selectedBizString = getSelectedBusiness();
  const selectedBiz = selectedBizString ? JSON.parse(selectedBizString) : null;

  const [user, setUser] = useState(getData().data);
  const [selected, setSelected] = useState(selectedBiz ? selectedBiz : user);
  const [opened, setOpened] = useState(false);

  const combinedData = [
    {
      ...user,
      business_name: `${user.name} ${user.surname}`,
      user_img: user.user_img ? user.user_img : placeholderImg,
      uuid: user.email,
      type: "user",
    },
    ...data,
  ];

  const handleProfileChange = (item) => {
    setSelected(item);
    if (item.userid) {
      removeSelectedBusiness();
      removeBusinessID();
      removeBusinessUserID();
    } else {
      setBusinessID(item.business_id);
      setSelectedBusiness(item);
      setBusinessUserID(item.ref_id);
    }
    window.location.href = "/";
  };

  const items = combinedData.map((item) => (
    <Menu.Item
      key={item.business_name}
      style={{
        border: "0.5px solid lightgray",
        padding: 0,
        borderRadius: 0,
        marginTop: -1,
      }}
      onClick={() => handleProfileChange(item)}
    >
      <UnstyledButton
        className={item.userid && classes.user}
        style={
          !item.userid
            ? { padding: "0.5rem", paddingLeft: "1rem", height: 70 }
            : {}
        }
      >
        <Group>
          <div style={{ position: "relative" }}>
            <Avatar
              src={item?.user_img ? item.user_img : bizPlaceholderImg}
              radius="xl"
            />
          </div>
          <div style={{ flex: 1 }}>
            <Text size="sm" fw={500}>
              {item.business_name}
            </Text>
            <Text c="dimmed" style={{ fontSize: !item.userid ? 10 : 12 }}>
              {item.uuid}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.user}
          onClick={() =>
            data.length == 0 && (window.location.href = "/settings")
          }
        >
          <Group style={{ position: "relative" }}>
            <Avatar
              src={
                selected.user_img
                  ? selected.user_img
                  : selected.biz_id
                  ? bizPlaceholderImg
                  : placeholderImg
              }
              radius="xl"
            />
            {!selected.userid && (
              <Avatar
                size="sm"
                style={{ position: "absolute", top: 20, left: 20 }}
                radius="xl"
                src={user.user_img ? user.user_img : placeholderImg}
              />
            )}
            <div style={{ flex: 1 }}>
              <Text size="sm" fw={500}>
                {selected.business_name
                  ? selected.business_name
                  : `${selected.name} ${selected.surname}`}
              </Text>
              <Text c="dimmed" style={{ fontSize: !selected.userid ? 9 : 12 }}>
                {selected.biz_id ? selected.uuid : selected.email}
              </Text>
            </div>
            <IconChevronRight
              style={{ width: rem(14), height: rem(14) }}
              stroke={1.5}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      {data.length > 0 && (
        <Menu.Dropdown style={{ padding: 0, marginTop: -15 }}>
          {items}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}
